import { CSSProperties } from 'react';
import styled from 'styled-components';

import BreadcrumbsStyled from '../Breadcrumbs';
import PageActions from '../PageActions';

interface Breadcrumb {
  label: string;
  url?: string;
}

interface Props {
  className?: string;
  title?: string;
  controlBar?: boolean;
  breadcrumbs?: Array<Breadcrumb>;
  style?: CSSProperties;
}

const ContentHeader = ({ className, title, controlBar, breadcrumbs, style }: Props) => (
  <div style={style} className={className}>
    <div>
      <BreadcrumbsStyled breadcrumbs={breadcrumbs} />
      <h1>{title}</h1>
    </div>
    {controlBar && <PageActions />}
  </div>
);

ContentHeader.defaultProps = {
  className: '',
  title: '',
  controlBar: false,
  breadcrumbs: [],
  style: {},
};

const ContentHeaderStyled = styled(ContentHeader)`
  border: 0.0625rem solid var(--2685a133, #2685a133);
  border-radius: 0.5rem;
  background: url('/images/content-header-bg.jpg') center center / cover no-repeat;
  padding: 16px 12px 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  gap: 1rem;

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.xl};
    font-weight: 600;
    line-height: ${({ theme }) => theme.lineHeights.xl};
    letter-spacing: -0.03em;
    color: ${({ theme }) => theme.colors.primary.text};
    margin: 0;
  }
`;

export default ContentHeaderStyled;
