import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ContentHeaderStyled from '../../../components/ContentHeader';
import PaginationStyled from '../../../components/Pagination';

import NewsItem from './components/NewsItem';

interface Props {
  className?: string;
}

const News = ({ className }: Props) => {
  const navigate = useNavigate();

  return (
    <div className={className}>
      <ContentHeaderStyled
        title="What’s new"
        breadcrumbs={[{ label: 'Get Started', url: '/' }, { label: 'Latest Updates' }]}
      />

      <NewsItem
        imgSrc="/images/news-image@3x.jpg"
        onReadMore={() => navigate('/get-started/news/design-evolved')}
        time="Aug 9, 2024"
        title="Design Evolved: Explore Our New Design System!"
        description="Introducing our brand new design system, meticulously crafted to elevate user experience to unprecedented heights. Drawing from the latest in design innovation and user feedback. Dive in and explore the next frontier of digital design."
      />

      <PaginationStyled
        nextLink="/branding-styleguide"
        next="Branding Styleguide"
        className="footer-pagination"
      />
    </div>
  );
};

News.defaultProps = {
  className: '',
};

const StyledNews = styled(News)`
  width: 100%;
  display: flex;
  flex-direction: column;
  .footer-pagination {
    display: none;
    margin-top: auto;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
    }
  }
`;

export default StyledNews;
