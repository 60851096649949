import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowRight } from '../../images/icons/arrow-right.svg';
import Button from '../Button';

interface Props {
  className?: string;
  title?: string;
  subtitle?: React.ReactNode;
  buttonName?: string;
  buttonLink?: string;
  category?: boolean;
}

const Hero = ({ className, title, subtitle, buttonName, buttonLink, category }: Props) => (
  <div className={`${className} ${category && 'hero-category'} `}>
    <div className="hero-desc">
      <h1>{title}</h1>
      {!category && buttonLink && buttonName && (
        <Button
          iconAfter={<ArrowRight />}
          variant="primary"
          size="M"
          onClick={() => (window.location.href = buttonLink)}
        >
          {buttonName}
        </Button>
      )}
      {category && subtitle}
    </div>
  </div>
);

Hero.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
  buttonName: '',
  buttonLink: '',
  category: false,
};

const HeroStyled = styled(Hero)`
  border: 0.0625rem solid var(--dadce0, #dadce0);
  border-radius: 0.75rem;
  background: url('images/hero-image@3x.jpg') center center / cover no-repeat;
  padding: 2rem;
  min-height: 23.0625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2.5rem;

  &.hero-category {
    background: url('images/hero-category@3x.jpg') center center / cover no-repeat;

    .hero-desc {
      max-width: 43.75rem;

      h1 {
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.25;
        letter-spacing: -0.03em;
        color: var(--030404, #030404);
        margin-top: 0;
        margin-bottom: 1rem;
      }

      p {
        font-size: 0.875rem;
        line-height: 1.428571428571429;
        color: var(--5c636e, #5c636e);
        margin-top: 0;
        margin-bottom: 1.25rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &:not(.hero-category) {
    .hero-desc {
      max-width: 50%;

      h1 {
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.083333333333333;
        color: var(--030404, #030404);
        margin-top: 0;
        margin-bottom: 1rem;
      }

      p {
        color: var(--5c636e, #5c636e);
        margin-top: 0;
        margin-bottom: 1.5rem;
      }
    }
  }
`;

export default HeroStyled;
