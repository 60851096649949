import React from 'react';
import styled, { css } from 'styled-components';

type ButtonVariant =
  | 'default'
  | 'primary'
  | 'subtle'
  | 'plain'
  | 'destructive'
  | 'destructive-filled';

type ButtonSize = 'XS' | 'S' | 'M';

interface BaseProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
}

// onlyIcon is not used with iconBefore and iconAfter
interface PropsWithBothIcons extends BaseProps {
  iconBefore?: React.ReactNode; // Icon to appear before the text
  iconAfter?: React.ReactNode; // Icon to appear after the text
  onlyIcon?: never;
}

// onlyIcon prop is used without iconBefore and iconAfter
interface PropsWithOnlyIcon extends BaseProps {
  iconBefore?: never;
  iconAfter?: never;
  onlyIcon?: boolean;
}

type Props = PropsWithOnlyIcon | PropsWithBothIcons;

// Define button styles based on props (variation)
const buttonStyles = {
  default: css`
    background-color: ${(props) => props.theme.colors.primary.background};
    color: ${(props) => props.theme.colors.primary.text};

    .icon-wrapper {
      > svg {
        path {
          fill: ${(props) => props.theme.colors.primary.text};
        }
      }

      &::after {
        background-color: ${(props) => props.theme.colors.primary.border};
        opacity: 20%;
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.primary['background-medium']};
      color: ${(props) => props.theme.colors.primary['text-hover']};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.primary['text-hover']};
          }
        }
      }
    }

    &:active {
      background-color: ${(props) => props.theme.colors.primary['background-active']};
      color: ${(props) => props.theme.colors.primary['text-hover']};
    }

    &:disabled {
      background-color: ${(props) => props.theme.colors.neutral['background-medium']};
      color: ${(props) => props.theme.colors.neutral['text-disabled']};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.neutral['text-disabled']};
          }
        }
        &::after {
          background-color: ${(props) => props.theme.colors.neutral['text-disabled']};
        }
      }
    }
  `,
  primary: css`
    background-color: ${(props) => props.theme.colors.primary.action};
    color: ${(props) => props.theme.colors.white};

    .icon-wrapper {
      > svg {
        path {
          fill: ${(props) => props.theme.colors.white};
        }
      }

      &::after {
        background-color: ${(props) => props.theme.colors.neutral['text-disabled']};
        opacity: 40%;
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.primary['action-hover']};
      color: ${(props) => props.theme.colors.primary['text-strong-hover']};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.primary['text-strong-hover']};
          }
        }
      }
    }

    &:active {
      background-color: ${(props) => props.theme.colors.primary['action-hover']};
      color: ${(props) => props.theme.colors.primary['text-strong-active']};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.primary['text-strong-active']};
          }
        }
      }
    }

    &:disabled {
      background-color: ${(props) => props.theme.colors.neutral.background};
      color: ${(props) => props.theme.colors.neutral['text-disabled']};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.neutral['text-disabled']};
          }
        }
        &::after {
          background-color: ${(props) => props.theme.colors.neutral['text-disabled']};
        }
      }
    }
  `,
  subtle: css`
    background-color: ${(props) => props.theme.colors.neutral.background};
    color: ${(props) => props.theme.colors.neutral['text-weak']};

    .icon-wrapper {
      > svg {
        path {
          fill: ${(props) => props.theme.colors.neutral['text-weak']};
        }
      }

      &::after {
        background-color: ${(props) => props.theme.colors.neutral['text-disabled']};
        opacity: 40%;
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.neutral['background-hover']};
      color: ${(props) => props.theme.colors.neutral.text};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.neutral.text};
          }
        }
      }
    }

    &:active {
      background-color: ${(props) => props.theme.colors.neutral['background-active']};
      color: ${(props) => props.theme.colors.neutral.text};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.neutral.text};
          }
        }
      }
    }

    &:disabled {
      background-color: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.neutral['text-disabled']};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.neutral['text-disabled']};
          }
        }
        &::after {
          background-color: ${(props) => props.theme.colors.neutral['text-disabled']};
        }
      }
    }
  `,
  destructive: css`
    background-color: ${(props) => props.theme.colors.alert.background};
    color: ${(props) => props.theme.colors.alert.text};

    .icon-wrapper {
      > svg {
        path {
          fill: ${(props) => props.theme.colors.alert.text};
        }
      }

      &::after {
        background-color: ${(props) => props.theme.colors.alert.border};
        opacity: 20%;
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.alert['background-medium']};
      color: ${(props) => props.theme.colors.alert['text-hover']};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.alert['text-hover']};
          }
        }
      }
    }

    &:active {
      background-color: ${(props) => props.theme.colors.alert['background-active']};
      color: ${(props) => props.theme.colors.alert['text-hover']};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.alert['text-hover']};
          }
        }
      }
    }

    &:disabled {
      background-color: ${(props) => props.theme.colors.neutral['background-medium']};
      color: ${(props) => props.theme.colors.neutral['text-disabled']};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.neutral['text-disabled']};
          }
        }
        &::after {
          background-color: ${(props) => props.theme.colors.neutral['text-disabled']};
        }
      }
    }
  `,
  'destructive-filled': css`
    background-color: ${(props) => props.theme.colors.alert['background-strong']};
    color: ${(props) => props.theme.colors.white};

    .icon-wrapper {
      > svg {
        path {
          fill: ${(props) => props.theme.colors.white};
        }
      }

      &::after {
        background-color: ${(props) => props.theme.colors.neutral.border};
        opacity: 40%;
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.alert['background-strong-hover']};
      color: ${(props) => props.theme.colors.white};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.white};
          }
        }
      }
    }

    &:active {
      background-color: ${(props) => props.theme.colors.alert['background-strong-hover']};
      color: ${(props) => props.theme.colors.white};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.white};
          }
        }
      }
    }

    &:disabled {
      background-color: ${(props) => props.theme.colors.neutral.background};
      color: ${(props) => props.theme.colors.neutral['text-disabled']};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.neutral['text-disabled']};
          }
        }
        &::after {
          background-color: ${(props) => props.theme.colors.neutral['text-disabled']};
        }
      }
    }
  `,
  plain: css`
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.neutral['text-weak']};

    .icon-wrapper {
      > svg {
        path {
          fill: ${(props) => props.theme.colors.neutral['text-weak']};
        }
      }

      &::after {
        background-color: ${(props) => props.theme.colors.neutral['text-disabled']};
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.neutral['background-hover']};
      color: ${(props) => props.theme.colors.neutral.text};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.neutral.text};
          }
        }
      }
    }

    &:active {
      background-color: ${(props) => props.theme.colors.neutral['background-active']};
      color: ${(props) => props.theme.colors.neutral.text};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.neutral.text};
          }
        }
      }
    }

    &:disabled {
      background-color: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.neutral['text-disabled']};

      .icon-wrapper {
        > svg {
          path {
            fill: ${(props) => props.theme.colors.neutral['text-disabled']};
          }
        }
        &::after {
          background-color: ${(props) => props.theme.colors.neutral['text-disabled']};
        }
      }
    }
  `,
};

const sizeButtonWithTextStyles = {
  XS: css`
    font-size: ${(props) => props.theme.fontSizes.xs};
    line-height: ${(props) => props.theme.lineHeights.xs};
    padding: 4px 8px;
  `,
  S: css`
    font-size: ${(props) => props.theme.fontSizes.sm};
    line-height: ${(props) => props.theme.lineHeights.sm};
    padding: 8px 10px;
  `,
  M: css`
    font-size: ${(props) => props.theme.fontSizes.sm};
    line-height: ${(props) => props.theme.lineHeights.sm};
    padding: 12px;
  `,
};

const sizeButtonOnlyIconStyles = {
  XS: css`
    min-width: 24px;
    height: 24px;
  `,
  S: css`
    min-width: 32px;
    height: 32px;
  `,
  M: css`
    min-width: 40px;
    height: 40px;
  `,
};

const IconWrapper = styled.span.withConfig({
  shouldForwardProp: (prop) => !['position', 'onlyIcon'].includes(prop),
})<{
  position?: 'before' | 'after';
  onlyIcon?: boolean;
}>`
  display: inline-flex;
  position: relative;

  ${({ onlyIcon, position }) =>
    !onlyIcon &&
    css`
      margin: ${position === 'before' ? '0 17px 0 0' : '0 0 0 17px'};
    `}

  > svg {
    width: 16px;
    height: 16px;

    > path {
      transition: fill 0.3s ease-in-out;
    }
  }

  ${({ onlyIcon, position }) =>
    !onlyIcon &&
    css`
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        ${position === 'before' ? 'right: -8px;' : 'left: -8px;'};
        transition:
          height 0.3s ease-in-out,
          top 0.3s ease-in-out;
      }
    `}
`;

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['size', 'variant', 'onlyIcon'].includes(prop),
})<{
  size: ButtonSize;
  variant: ButtonVariant;
  onlyIcon?: boolean;
}>`
  border-radius: 6px;
  transition:
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  overflow: clip;
  white-space: nowrap;
  font-weight: 500;
  justify-content: center; // center the text

  /* Apply size and variant styles based on the props */
  ${({ size, onlyIcon }) =>
    !onlyIcon ? sizeButtonWithTextStyles[size] : sizeButtonOnlyIconStyles[size]}
  ${({ variant }) => buttonStyles[variant || 'default']}

  
  // Hover effect for icons pseudo-elements
  &:hover {
    ${({ onlyIcon }) =>
      !onlyIcon &&
      css`
        > .icon-wrapper {
          &::after,
          &::before {
            height: 240%;
            top: -11px;
          }
        }
      `}
  }
`;

const Button = ({
  children,
  variant = 'default',
  size = 'M',
  iconBefore,
  iconAfter,
  onlyIcon,
  ...props
}: Props) => (
  <StyledButton onlyIcon={onlyIcon} variant={variant} size={size} {...props}>
    {iconBefore && (
      <IconWrapper position="before" className="icon-wrapper">
        {iconBefore}
      </IconWrapper>
    )}
    {onlyIcon ? (
      <IconWrapper className="icon-wrapper" onlyIcon>
        {children}
      </IconWrapper>
    ) : (
      children
    )}
    {iconAfter && (
      <IconWrapper position="after" className="icon-wrapper">
        {iconAfter}
      </IconWrapper>
    )}
  </StyledButton>
);

Button.defaultProps = {
  variant: 'default',
  size: 'M',
  iconBefore: undefined,
  iconAfter: undefined,
  onlyIcon: false,
};

export default Button;
