import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// This hook is used to scroll to the top of the page when the route changes.
// It is used in the LayoutWithSidebar component that has .content element.

const useScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    const contentElement = document.querySelector('.content');
    if (contentElement) {
      contentElement.scrollTo({ top: 0, behavior: 'auto' });
    }
  }, [pathname]);
};

export default useScrollToTop;
