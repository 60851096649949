import styled from 'styled-components';

interface Props {
  className?: string;
  data: { label: string; color: string }[];
}

const CategoricalLegend = ({ className, data }: Props) => (
  <div className={className}>
    {data.map((item) => (
      <div className="default-legend-item" key={item.label}>
        <div className="default-legend-color" style={{ backgroundColor: item.color }} />
        <span className="default-legend-label">{item.label}</span>
      </div>
    ))}
  </div>
);

CategoricalLegend.defaultProps = {
  className: '',
};

const StyledCategoricalLegend = styled(CategoricalLegend)`
  display: flex;
  align-items: center;
  gap: 12px;

  .default-legend-item {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    line-height: ${({ theme }) => theme.lineHeights.xs};
    color: ${({ theme }) => theme.colors.neutral['text-weak']};
    font-weight: 400;

    > .default-legend-color {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
`;

export default StyledCategoricalLegend;
