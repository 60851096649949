import styled from 'styled-components';

import Categorical from './components/Categorical';
import Choropleth from './components/Choropleth';

interface CategoricalLegend {
  data: { label: string; color: string }[];
}

interface ChoroplethLegend {
  colors: [string, string, string, string, string];
  labels: [string, string, string];
}

interface BaseProps {
  className?: string;
}

interface CategoricalProps extends BaseProps, CategoricalLegend {
  type: 'categorical';
}

interface ChoroplethProps extends BaseProps, ChoroplethLegend {
  type: 'choropleth';
}

type Props = CategoricalProps | ChoroplethProps;

const Legend = ({ className, type, ...rest }: Props) => {
  if (type === 'categorical') {
    const { data } = rest as CategoricalProps;
    return <Categorical className={className} data={data} />;
  }
  const { colors, labels } = rest as ChoroplethProps;
  return <Choropleth className={className} colors={colors} labels={labels} />;
};

Legend.defaultProps = {
  className: '',
};

const StyledLegend = styled(Legend)``;

export default StyledLegend;
