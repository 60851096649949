import styled from 'styled-components';

import ContentHeaderStyled from '../../../components/ContentHeader';
import HoverableCard from '../../../components/HoverableCard';
import PaginationStyled from '../../../components/Pagination';
import { ReactComponent as ChartIcon } from '../../../images/icons/chart-bar.svg';

interface Props {
  className?: string;
}

const OthersPage = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <ContentHeaderStyled
        className="breadcrumbs"
        title="Others"
        breadcrumbs={[
          { label: 'Data Vizualization', url: '/data-visualization' },
          { label: 'Others Chart' },
        ]}
      />

      <div className="cards">
        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Bubbles Chart"
          url="bubble"
          image="/images/data-viz-others/bubbles.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />
        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Box Plot - V"
          url="box-plot-v"
          image="/images/data-viz-others/box-plot-v.png"
          icon={<ChartIcon />}
          tags={['distribution', 'comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Box Plot - H"
          url="box-plot-h"
          image="/images/data-viz-others/box-plot-h.png"
          icon={<ChartIcon />}
          tags={['distribution', 'comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Violin Plot - V"
          url="violin-plot-v"
          image="/images/data-viz-others/violin-plot-v.png"
          icon={<ChartIcon />}
          tags={['distribution', 'comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Violin Plot - H"
          url="violin-plot-h"
          image="/images/data-viz-others/violin-plot-h.png"
          icon={<ChartIcon />}
          tags={['distribution', 'comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Line & Grouped Bar Chart"
          url="line-and-stacked-bar"
          image="/images/data-viz-others/line-grouped-bar.png"
          icon={<ChartIcon />}
          tags={['correlation', 'comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Scatter Plot"
          url="scatter-plot"
          image="/images/data-viz-others/scatter-plot.png"
          icon={<ChartIcon />}
          tags={['correlation']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Ribbon Chart"
          url="ribbon-chart"
          image="/images/data-viz-others/ribbon.png"
          icon={<ChartIcon />}
          tags={['proportion', 'trend', 'comparison']}
        />
      </div>

      <PaginationStyled
        previousLink="/data-visualization/diagrams"
        previous="Diagrams"
        nextLink="/data-visualization/lines"
        next="Line"
        className="footer-pagination"
      />
    </div>
  </div>
);

OthersPage.defaultProps = {
  className: '',
};

const StyledOthersPage = styled(OthersPage)`
  > .sidebar {
    overflow-x: hidden;
    height: calc(100vh - 4rem);
  }

  > .content {
    overflow-x: hidden;
    margin: 0 auto;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    > .breadcrumbs {
      margin-bottom: 24px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-bottom: 40px;
      }
    }

    > .cards {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 80px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    > .footer-pagination {
      display: none;
      margin-top: auto;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: block;
      }
    }
  }
`;

export default StyledOthersPage;
