import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import PaginationStyled from '../../../components/Pagination';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government usability testing resources',
    cards: [
      {
        title: 'IBM Web Accessibility Checklist',
        subtitle: 'plainlanguage.gov',
        linkUrl: '#',
      },
      {
        title: 'IBM Web Accessibility Checklist',
        subtitle: 'plainlanguage.gov',
        linkUrl: '#',
      },
      {
        title: 'Government usability testing resources',
        subtitle: '[digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Testing for plain-language usability',
        subtitle: '[18f.gov]',
        linkUrl: '#',
      },
      {
        title: 'Testing for plain-language usability',
        subtitle: '[18f.gov]',
        linkUrl: '#',
      },
    ],
  },
  {
    id: 2,
    title: 'Government accessibility resources',
    cards: [
      {
        title: 'The Section 508 accessibility program',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility for Teams guide',
        subtitle: '[accessibility.digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Find your Section 508 Coordinator',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Section 508 ICT Testing Baseline',
        subtitle: '[section508coordinators.github.com]',
        linkUrl: '#',
      },
      {
        title: 'Learn about writing in plain language',
        subtitle: '[plainlanguage.gov]',
        linkUrl: '#',
      },
      {
        title: 'Plain language resources',
        subtitle: '[plainlanguage.gov]',
        linkUrl: '#',
      },
      {
        title: '18F’s accessibility guide',
        subtitle: '[accessibility.18f.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility Requirements Tool (ART) for contracting',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Understanding universal design',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
    ],
  },
  {
    id: 3,
    title: 'Non-government accessibility resources',
    cards: [
      {
        title: 'Web Accessibility Perspectives Videos',
        subtitle: '[w3.org]',
        linkUrl: '#',
      },
      {
        title: 'Inclusive design principles',
        subtitle: '[inclusivedesignprinciples.org]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility fundamentals with Rob Dodson',
        subtitle: '[youtube.com]',
        linkUrl: '#',
      },
      {
        title: 'Microsoft’s inclusive design manual',
        subtitle: '[microsoft.com]',
        linkUrl: '#',
      },
      {
        title: 'Inclusive components by Heydon Pickering',
        subtitle: '[inclusive-components.design]',
        linkUrl: '#',
      },
      {
        title: 'Humane by design',
        subtitle: '[humanebydesign.com]',
        linkUrl: '#',
      },
    ],
  },
];

const AccessibilityStructure = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <ContentHeaderStyled
        title="Accessibility"
        breadcrumbs={[{ label: 'Get Started', url: '/' }, { label: 'Accessibility' }]}
      />
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-with-navigation-wrapper">
            <div className="page-content-with-navigation">
              <div className="page-content-item">
                <h1 className="h1" id="overview">
                  Overview
                </h1>
                <h3 className="h3" id="definition">
                  Definition
                </h3>
                <p className="mb-40">
                  The Accessibility page within our design system serves as a comprehensive resource
                  outlining guidelines and best practices to ensure that our digital products are
                  usable by individuals with diverse abilities.
                </p>
                <h3 className="h3" id="rationale">
                  Rationale
                </h3>
                <p className="mb-40">
                  Accessibility is crucial for ensuring that all users, regardless of their
                  abilities, can access and interact with our digital products effectively. Failing
                  to prioritize accessibility can lead to exclusionary experiences, hindering user
                  engagement and potentially resulting in legal compliance issues.
                </p>
              </div>
              <h1 className="divider h1" id="guidelines">
                Guidelines
              </h1>
              <div className="page-content-item">
                <h3 className="h3" id="best-practices">
                  Best Practices
                </h3>
                <ul className="ordered-list">
                  <li>
                    <strong>Ensuring Readable Text:</strong> Adhere to the W3C&apos;s
                    recommendations for text contrast ratios to ensure readability.
                  </li>
                  <li>
                    <strong>Consistent and Accessible Color Use:</strong> Maintain consistent
                    contrast ratios across all color swatches.
                  </li>
                  <li>
                    <strong>Keyboard Accessibility:</strong> Design components to support full
                    functionality via keyboard input.
                  </li>
                </ul>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-accessibility/screen-voice-search@3x.jpg"
                    alt="Voice search"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>
                      The label voice search&apos; describes the user task (search) paired with the
                      input method (voice)
                    </p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-accessibility/screen-microphone@3x.jpg"
                    alt="Microphone"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>
                      The label voice search&apos; describes the user task (search) paired with the
                      input method (voice)
                    </p>
                  </div>
                </div>
              </div>
              <div className="page-content-item">
                <h3 className="h3" id="principles">
                  Principles
                </h3>
                <ul className="ordered-list">
                  <li>
                    <strong>High Contrast and Legible Text:</strong> Ensure high contrast, legible
                    text, and clear icons for better readability.
                  </li>
                  <li>
                    <strong>Keyboard Navigation:</strong> Support keyboard navigation for seamless
                    interaction.
                  </li>
                  <li>
                    <strong>Content Organization:</strong> Organize content hierarchy for clarity
                    and avoid overcrowding.
                  </li>
                  <li>
                    <strong>Responsive Design:</strong> Design responsively for easy readability and
                    navigation across devices.
                  </li>
                  <li>
                    <strong>Inclusive Information Conveyance:</strong> Use multiple cues, not just
                    color, for conveying information inclusively.
                  </li>
                </ul>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-accessibility/screen-bg-color-standard@3x.jpg"
                    alt="Standard background color"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>
                      Use a background color that has enough contrast to meet the AA standard ratio.
                    </p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-accessibility/screen-bg-color-spectrum-text@3x.jpg"
                    alt="Text color"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>
                      Use a background color that is close in the spectrum to the text color used.
                    </p>
                  </div>
                </div>
              </div>
              <div className="page-content-item">
                <h3 className="visually-hidden" id="considerations">
                  Considerations
                </h3>
                <h3 className="h3">Considerations for Diverse Abilities:</h3>
                <ul className="ordered-list">
                  <li>
                    <strong>Physical Disabilities:</strong> Designing for keyboard interaction
                    improves efficiency for users with physical disabilities.
                  </li>
                  <li>
                    <strong>Visual Impairments:</strong> Prioritize screen reader compatibility and
                    clear translation of visual information into text.
                  </li>
                  <li>
                    <strong>Low Vision:</strong> Maximize readability and provide clear visual
                    contrast for users with low vision.
                  </li>
                  <li>
                    <strong>Color Vision Deficiencies:</strong> Adhere to WCAG 2.1 AA guidelines for
                    color contrast and provide non-color information.
                  </li>
                </ul>
              </div>
              <img
                className="mb-40 img-responsive"
                src="/images/get-started-accessibility/example-color-vision@3x.jpg"
                alt="Color Vision Deficiencies"
              />
              <div className="page-content-item">
                <h3 className="visually-hidden" id="accessibility-standards">
                  Accessibility Standards
                </h3>
                <h3 className="h3">Global Accessibility Standards: </h3>
                <p>
                  We adhere to the Web Content Accessibility Guidelines (WCAG) to ensure our digital
                  products meet global accessibility standards.
                </p>
              </div>
              <div className="examples-wrapper">
                <div className="examples-wrapper-item">
                  <img
                    src="/images/get-started-accessibility/this-text-is-accessible-01@3x.jpg"
                    alt=""
                  />
                  <p>Text: Grey 20, Background: Blue 60, Gap: 40</p>
                </div>
                <div className="examples-wrapper-item">
                  <img
                    src="/images/get-started-accessibility/this-text-is-accessible-02@3x.jpg"
                    alt=""
                  />
                  <p>Text: Blue 70 , Background: Ultramarine 30, Gap: 40</p>
                </div>
                <div className="examples-wrapper-item">
                  <img
                    src="/images/get-started-accessibility/this-text-is-accessible-03@3x.jpg"
                    alt=""
                  />
                  <p>Text: Grey 40, Background: Blue 60, Gap: 20</p>
                </div>
                <div className="examples-wrapper-item">
                  <img
                    src="/images/get-started-accessibility/this-text-is-accessible-04@3x.jpg"
                    alt=""
                  />
                  <p>Text: Gray 10 , Background: Ultramarine 30, Gap: 20</p>
                </div>
              </div>
              <figure>
                <img
                  src="/images/get-started-accessibility/example-of-headings-marked-up-in-code@3x.jpg"
                  alt="Example of headings marked up in code"
                />
                <figcaption>Example of headings marked up in code</figcaption>
              </figure>
              <div className="page-content-item">
                <h3 className="h3">Define headings</h3>
                <p className="mb-40">
                  For web only: Landmarks and headings help assistive-technology users orient
                  themselves to a web page and allow for easy navigation and traversal across large
                  sections of a document or page. By classifying and labeling sections of a page,
                  structural information that is conveyed visually through layout design can also be
                  represented in code.
                </p>
              </div>
              <figure>
                <img
                  src="/images/get-started-accessibility/example-accessibility-labels-marked-up-in-code@3x.jpg"
                  alt="Example: The accessibility labels marked up in code for these icons describe their purpose"
                />
                <figcaption>
                  Example: The accessibility labels marked up in code for these icons describe their
                  purpose
                </figcaption>
              </figure>
              <div className="page-content-item">
                <h3 className="h3">Conclusion:</h3>
                <p className="mb-40">
                  By adhering to accessibility principles and considering the diverse needs of our
                  users, we ensure that our digital products are inclusive and accessible to all,
                  providing a better user experience for everyone.
                </p>
              </div>
              <h1 className="divider h1" id="references">
                References
              </h1>
              {dataExternalLinkGroups.map((externalLinkGroup) => (
                <ExternalLinkGroupStyled
                  key={externalLinkGroup.id}
                  title={externalLinkGroup.title}
                  cards={externalLinkGroup.cards}
                />
              ))}
            </div>

            <div>
              <div className="page-navigation">
                <h4>Contents</h4>
                <Scrollspy
                  ids={[
                    'overview',
                    'definition',
                    'rationale',
                    'guidelines',
                    'best-practices',
                    'principles',
                    'considerations',
                    'accessibility-standards',
                    'references',
                  ]}
                  offset={0}
                  activeItemClassName="active"
                  itemContainerClassName="multilevel-link-list"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaginationStyled
        previousLink="/"
        previous="Get Started"
        nextLink="/get-started/content"
        next="Content"
      />
    </div>
  </div>
);

AccessibilityStructure.defaultProps = {
  className: '',
};

const Accessibility = styled(AccessibilityStructure)`
    ${commonPageContentStyles}
    ${navigationStyles([2, 3, 5, 6, 7, 8])}
    overflow: unset;

    height: 100%;

   
        > .content {
            width: 100%;
            padding: 0;
        }
}
`;

export default Accessibility;
