import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  menuTitle: string;
  children: ReactNode;
  as?: string;
  className?: string;
  id?: string;
}

const Title = styled.h3`
  position: absolute;
  top: 0;
  opacity: 0;
  width: 0;
  height: 0;
`;

const Content = styled.div`
  position: relative;
`;

// Use this component when you want to create a sticky navigation menu with a custom title.

const StickyNavMenuTitle = ({ menuTitle, children, as, className, id }: Props) => (
  <Content as={as} className={className}>
    <Title id={id}>{menuTitle}</Title>
    {children}
  </Content>
);

StickyNavMenuTitle.defaultProps = {
  as: 'h3',
  className: '',
  id: '',
};

export default StickyNavMenuTitle;
