import styled from 'styled-components';

import ContentHeaderStyled from '../../../../components/ContentHeader';
import ExternalLink from '../../../../components/ExternalLink';
import PaginationStyled from '../../../../components/Pagination';
import StickyNav from '../../../../components/StickyNav';
import StickyNavMenuTitle from '../../../../components/StickyNav/components/StickyNavMenuTitle';
import NewsItem from '../components/NewsItem';

import heroImg from './images/hero-image.png';

interface Props {
  className?: string;
}

const DesignEvolved = ({ className }: Props) => (
  <div className={className}>
    <ContentHeaderStyled
      title="Design Evolved: Explore Our New Design System!"
      breadcrumbs={[
        { label: 'Get Started', url: '/' },
        {
          label: 'Latest Updates',
          url: '/get-started/news',
        },
        { label: 'Design Evolved: Explore Our New Design System!' },
      ]}
    />
    <div className="page-wrapper">
      <section className="content">
        <div className="time">9 Feb, 2024</div>
        <p className="mb-40 text-week description">
          <div style={{ opacity: 0, width: '0px', height: '0px' }} id="overview">
            Overview
          </div>
          Introducing our brand new design system, meticulously crafted to elevate user experience
          to unprecedented heights. Drawing from the latest in design innovation and user feedback.
          Dive in and explore the next frontier of digital design.
        </p>

        <img className="image mb-40" src={heroImg} alt="img" />

        <StickyNavMenuTitle id="guidelines" className="subtitle" menuTitle="Guidelines" as="h3">
          DHCS Pro Design Language
        </StickyNavMenuTitle>

        <ul className="text-week mb-40">
          <li>
            <b>Assign meaning:</b> Ensure that the visual language is clear and intuitive for
            healthcare professionals and patients alike.
          </li>
          <li>
            <b> Increase density:</b> Optimize space while maintaining high usability for various
            medical dashboards and forms.
          </li>
          <li>
            <b>Craft real-world interactions:</b> Design interfaces that feel responsive and
            meaningful, ensuring a sense of reliability in complex healthcare workflows.
          </li>
          <li>
            <b>Make it predictable:</b> Similar design elements should behave consistently to reduce
            cognitive load and improve user trust.{' '}
          </li>
        </ul>

        <h3 className="subtitle">Updated Token Resources</h3>

        <ol className="text-week mb-40">
          <li>
            <b>Assign meaning:</b> Ensure that the visual language is clear and intuitive for
            healthcare professionals and patients alike.
          </li>
          <li>
            <b>Increase density:</b> Optimize space while maintaining high usability for various
            medical dashboards and forms.
          </li>
          <li>
            <b>Craft real-world interactions:</b> Design interfaces that feel responsive and
            meaningful, ensuring a sense of reliability in complex healthcare workflows.
          </li>
          <li>
            <b>Make it predictable:</b> Similar design elements should behave consistently to reduce
            cognitive load and improve user trust.
          </li>
          <li>
            <b>Color Token List:</b> Colors adapted for medical use (e.g., neutral, alert, positive
            states).
          </li>
          <li>
            <b>Font Token List:</b> Fonts chosen for readability in medical environments.
          </li>
          <li>
            <b>Shadow Token List:</b> Shadows used to highlight key actions or inputs.
          </li>
          <li>
            <b>Space Token List:</b> Spacing optimized for dense information display.
          </li>
        </ol>

        <h3 className="subtitle">Component API Simplification</h3>

        <p className="text-week mb-40 description">
          Simplify and Consolidate: Streamline APIs to make them consistent across the system. Use
          Inline and Block for directional layout. Rename properties for better alignment with CSS
          standards (e.g., radius to border-radius).
        </p>

        <h3 className="subtitle">Figma UI Kit </h3>

        <p className="text-week description">
          Figma Variables: Incorporate variables for color, space, and size to make it easy for
          designers to work within the system using Figma’s updated features.{' '}
        </p>
        <p className="text-week mb-40 description">
          This approach would ensure that the DHCS Design System remains functional, clear, and
          predictable while catering to the complex nature of healthcare-related tasks.
        </p>

        <hr className="mb-40" />

        <h2 className="title" id="references">
          References
        </h2>
        <h3 className="subtitle">Government usability testing resources</h3>

        <div className="cards mb-40">
          <ExternalLink
            linkUrl=""
            title="IBM Web Accessibility Checklist"
            subtitle="plainlanguage.gov"
          />
          <ExternalLink
            linkUrl=""
            title="IBM Web Accessibility Checklist"
            subtitle="plainlanguage.gov"
          />
          <ExternalLink
            linkUrl=""
            title="Government usability testing resources"
            subtitle="[digital.gov]"
          />
          <ExternalLink
            linkUrl=""
            title="Testing for plain-language usability"
            subtitle="[18f.gov]"
          />
          <ExternalLink
            linkUrl=""
            title="Testing for plain-language usability"
            subtitle="[18f.gov]"
          />
        </div>

        <hr className="mb-40" />

        <h2 className="title">Other Updates</h2>

        <NewsItem
          className="mb-40"
          imgSrc="/images/body-styles.png"
          onReadMore={() => {}}
          time="Feb 9, 2024"
          title="Version 10 Typography"
          description="Introducing our brand new design system, meticulously crafted to elevate user experience to unprecedented heights. Drawing from the latest in design innovation and user feedback. Dive in and explore the next frontier of digital design."
        />
        <hr className="mb-40" />
        <NewsItem
          className="mb-40"
          imgSrc="/images/layout-components.png"
          onReadMore={() => {}}
          time="Apr 9, 2024"
          title="New DHCS layout components."
          description="Introducing our brand new design system, meticulously crafted to elevate user experience to unprecedented heights. Drawing from the latest in design innovation and user feedback. Dive in and explore the next frontier of digital design."
        />
      </section>
      <div>
        <StickyNav
          style={{ width: '160px', marginTop: '30px' }}
          ids={['overview', 'guidelines', 'references']}
          title="Contents"
        />
      </div>
    </div>
    <PaginationStyled
      nextLink="/branding-styleguide"
      next="Branding Styleguide"
      className="footer-pagination"
    />
  </div>
);

DesignEvolved.defaultProps = {
  className: '',
};

const StyledDesignEvolved = styled(DesignEvolved)`
  width: 100%;

  .mb-40 {
    margin-bottom: 40px;
  }

  .text-week {
    color: ${({ theme }) => theme.colors.neutral['text-weak']};
  }

  .page-wrapper {
    display: flex;
    gap: 40px;
    > .content {
      min-width: 1000px;

      .time {
        width: fit-content;
        border-radius: 16px;
        font-size: ${({ theme }) => theme.fontSizes.xs};
        line-height: ${({ theme }) => theme.lineHeights.xs};
        padding: 8px 12px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.neutral.text};
        margin-bottom: 24px;
        border: 1px solid ${({ theme }) => theme.colors.neutral.border};
      }

      .title:not(span) {
        font-weight: 300;
        color: ${({ theme }) => theme.colors.neutral.text};
        font-size: ${({ theme }) => theme.fontSizes.xl};
        line-height: ${({ theme }) => theme.lineHeights.xl};
        margin-bottom: 24px;
        max-width: 700px;
      }

      .subtitle {
        font-size: 20px;
        line-height: 24px;
        color: ${({ theme }) => theme.colors.neutral.text};
        margin-bottom: 16px;
        font-weight: 600;
        max-width: 700px;
      }

      .description {
        font-size: ${({ theme }) => theme.fontSizes.base};
        line-height: ${({ theme }) => theme.lineHeights.base};
        font-weight: 400;
        max-width: 700px;
      }

      ol,
      ul {
        margin-left: 18px;
        max-width: 700px;
        color: ${({ theme }) => theme.colors.neutral['text-weak']};

        > li::marker {
          font-weight: bold;
        }
      }

      hr {
        border: 1px dashed ${({ theme }) => theme.colors.neutral.border};
      }

      .image {
        max-height: 400px;
        width: 100%;
      }

      .cards {
        margin-top: 24px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;

        > a {
          flex-basis: 30%;
        }
      }
    }
  }

  .footer-pagination {
    display: none;
    margin-top: auto;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
    }
  }
`;

export default StyledDesignEvolved;
