import styled from 'styled-components';

import { ReactComponent as ArrowCounterclockwise } from '../../images/icons/arrow-counterclockwise.svg';
import { ReactComponent as BuildChart } from '../../images/icons/build-chart.svg';
import { ReactComponent as FullScreenExitIcon } from '../../images/icons/fullscreen-exit.svg';
import { ReactComponent as Fullscreen } from '../../images/icons/fullscreen.svg';
import { ReactComponent as ZoomIn } from '../../images/icons/zoom-in.svg';
import { ReactComponent as ZoomOut } from '../../images/icons/zoom-out.svg';
import Button from '../Button';

import { usePageActions } from './context';

interface Props {
  className?: string;
}

const PageActions = ({ className }: Props) => {
  const { zoomIn, zoomOut, resetSettings, fullScreen, resetScreen, isFullScreen } =
    usePageActions();

  return (
    <div className={className}>
      <div className="button-list">
        <button type="button" aria-label="icon" onClick={zoomIn}>
          <ZoomIn />
        </button>
        <button type="button" aria-label="icon" onClick={zoomOut}>
          <ZoomOut />
        </button>
        <button type="button" aria-label="icon" onClick={resetSettings}>
          <ArrowCounterclockwise />
        </button>
        {!isFullScreen && (
          <button
            type="button"
            aria-label="icon"
            onClick={() => {
              fullScreen();
            }}
          >
            <Fullscreen />
          </button>
        )}
        {isFullScreen && (
          <button
            type="button"
            aria-label="icon"
            onClick={() => {
              resetScreen();
            }}
          >
            <FullScreenExitIcon />
          </button>
        )}
      </div>
      <Button size="M" iconAfter={<BuildChart />}>
        Use in Builder
      </Button>
    </div>
  );
};

PageActions.defaultProps = {
  className: '',
};

const StyledPageActions = styled(PageActions)`
  display: flex;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  border-radius: 6px;

  > .button-list {
    margin-right: 16px;
    display: flex;
    gap: 8px;
    > button {
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 4px 8px;
      cursor: pointer;

      > svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

export default StyledPageActions;
