import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import PaginationStyled from '../../../components/Pagination';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government accessibility resources',
    cards: [
      {
        title: 'The Section 508 accessibility program',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility for Teams guide',
        subtitle: '[accessibility.digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Find your Section 508 Coordinator',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
    ],
  },
];

const TableStructure = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <ContentHeaderStyled
        title="Table"
        breadcrumbs={[
          { label: 'General Components', url: '/general-components' },
          { label: 'Table', url: '/' },
        ]}
      />
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-with-navigation-wrapper">
            <div className="page-content-with-navigation">
              <div className="page-content-item">
                <h1 className="h1" id="overview">
                  Overview
                </h1>
                <p className="mb-40">
                  The data table&rsquo;s features are ideal for organizing and displaying data in a
                  UI. The column headers can sort data in ascending or descending order, rows can be
                  expanded to progressively disclose can so data in ascence ordescence order.rows
                  can be expandec to progressivel a sciose information, and single or batch actions
                  can be taken on rows. The data table toolbar gives a location for primary buttons,
                  search, filtering, table display settings,and other utilities.
                </p>
                <h1 className="divider h1" id="specifications">
                  Specifications
                </h1>
                <h3 className="h3" id="anatomy">
                  Anatomy
                </h3>
                <ol className="inside-list">
                  <li>
                    Title and description: contains the data table title and an optional
                    description.
                  </li>
                  <li>
                    Column header: title for the row header with the optional sorting behavior.
                  </li>
                  <li>
                    Table row: can be configured to show different types of data. Rows can be
                    selectable, expandable, and modified to show alternating zebra stripe background
                    colors.
                  </li>
                </ol>
              </div>
              <img
                src="/images/general-components-table/table-1@3x.jpg"
                alt="Anatomy"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <h3 className="h3" id="hover">
                  Hover
                </h3>
                <p className="mb-40 font-sm">
                  The data table&rsquo;s row hover state should always be enabled as it can help the
                  user visually scan the columns of data in a row even if the row is not
                  interactive.
                </p>
              </div>
              <img
                src="/images/general-components-table/table-2@3x.jpg"
                alt="Hover"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <h3 className="h3" id="sorting">
                  Sorting
                </h3>
                <p className="font-sm">
                  Columns can be sorted in ascending or descending order. Sorting controls are
                  located in the column headers and indicated with an arrow icon on hover and when a
                  column has been sorted.
                </p>
                <p className="mb-40 font-sm">
                  A sorted data table has three states: unsorted ( arrows ), sorted-up ( arrow--up)
                  or sorted-down (arrow--down ). The icon indicates the current sorted state and is
                  only shown if sorting is activated. Only the column being sorted should display an
                  icon, and unsorted icons are only visible on hover. You can see a demo of table
                  sorting in Carbon&rsquo;s React Storybook.
                </p>
              </div>
              <img
                src="/images/general-components-table/table-3@3x.jpg"
                alt="Sorting"
                className="mb-40 img-responsive"
              />
              <h1 className="h1 divider" id="guidelines">
                Guidelines
              </h1>
              <div className="page-content-item">
                <h3 className="h3" id="sizing">
                  Sizing
                </h3>
                <p className="mb-40 font-sm">
                  The column header row should always match the row size of the table. Extra large
                  row heights are only recommended if your data is expected to have 2 lines of
                  content in a single row.
                </p>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/general-components-table/screen-do-use-sizing@3x.jpg"
                    alt="Do use sizing"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>Do use the same row height for the table and header rows.</p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    src="/images/general-components-table/screen-do-not-use-sizing@3x.jpg"
                    alt="Do not use sizing"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>Don&apos;t mix row heights for the table and header rows.</p>
                  </div>
                </div>
              </div>
              <div className="page-content-item">
                <h3 className="h3" id="usage">
                  Usage
                </h3>
                <p className="mb-40 font-sm">
                  Data tables are used to organize and display data efficiently. The data table
                  component allows for customization with additional functionality, as needed by
                  your product’s users.
                </p>
                <h4 className="h4" id="when-to-use">
                  When to use
                </h4>
                <ol className="disc-list mb-40">
                  <li>To organize and display data.</li>
                  <li>
                    bIf your user must navigate to a specific piece of data to complete a task.
                  </li>
                  <li>Displaying all of a user&rsquo;s resources.</li>
                </ol>
                <h4 className="h4" id="when-not-to-use">
                  When not to use
                </h4>
                <ol className="disc-list font-sm mb-40">
                  <li>When a more complex display o the data or interactions are required.</li>
                  <li>As a replacement for a spreadsheet application.</li>
                </ol>
              </div>
              <h1 className="h1 divider" id="references">
                References
              </h1>
              {dataExternalLinkGroups.map((externalLinkGroup) => (
                <ExternalLinkGroupStyled
                  key={externalLinkGroup.id}
                  title={externalLinkGroup.title}
                  cards={externalLinkGroup.cards}
                />
              ))}
            </div>
            <div>
              <div className="page-navigation">
                <h4>Contents</h4>
                <Scrollspy
                  ids={[
                    'overview',
                    'specifications',
                    'anatomy',
                    'hover',
                    'sorting',
                    'guidelines',
                    'sizing',
                    'usage',
                    'references',
                  ]}
                  offset={0}
                  activeItemClassName="active"
                  itemContainerClassName="multilevel-link-list"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaginationStyled
        previousLink="/general-components/containment"
        previous="Containment"
        nextLink="/data-visualization"
        next="Data Vizualization"
      />
    </div>
  </div>
);

TableStructure.defaultProps = {
  className: '',
};

const Table = styled(TableStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([3, 4, 5, 7, 8])}

    overflow: unset;
  height: 100%;

  > .content {
    width: 100%;
    padding: 0;
  }
`;

export default Table;
