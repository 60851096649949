import styled from 'styled-components';

interface Props {
  className?: string;
  colors: [string, string, string, string, string];
  labels: [string, string, string];
}

const ChoroplethLegend = ({ className, colors, labels }: Props) => (
  <div className={className}>
    <div className="colors">
      {colors.map((color) => (
        <div style={{ backgroundColor: color }} className="color" key={color} />
      ))}
    </div>
    <div className="labels">
      {labels.map((label) => (
        <div key={label}>{label}</div>
      ))}
    </div>
  </div>
);

ChoroplethLegend.defaultProps = {
  className: '',
};

const StyledChoroplethLegend = styled(ChoroplethLegend)`
  width: min-content;

  .colors {
    display: flex;
    align-items: center;
    gap: 1px;
    > .color {
      width: 37px;
      height: 8px;

      &:first-child {
        border-radius: 3px 0 0 3px;
      }

      &:last-child {
        border-radius: 0 3px 3px 0;
      }
    }
  }

  .labels {
    margin-top: 3px;
    display: flex;
    justify-content: space-between;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    line-height: ${({ theme }) => theme.lineHeights.xs};
    color: ${({ theme }) => theme.colors.neutral['text-weak']};
    font-weight: 400;
  }
`;

export default StyledChoroplethLegend;
