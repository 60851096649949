import styled from 'styled-components';

import Alert from '../../components/Alert';
import ArticleCard from '../../components/ArticleCard';
import Button from '../../components/Button';
import Link from '../../components/Link';
import abcImage from '../../images/abc-image.png';
import chartDownImg from '../../images/chart-down.png';
import dottedBg from '../../images/dotted-bg.jpg';
import { ReactComponent as ArrowIcon } from '../../images/icons/arrow-right.svg';
import { ReactComponent as ChevronRightIcon } from '../../images/icons/chevron-right.svg';
import overviewHeroImage from '../../images/overview-hero.png';
import searchChartImg from '../../images/search-chart.png';

interface Props {
  className?: string;
}

const Overview = ({ className }: Props) => (
  <div className={className}>
    <section className="hero">
      <Alert
        className="alert"
        iconName="gear"
        mainText="Design system alert:"
        numberOfAlerts={5}
        additionalText="chart types will be added to the Design System in July 2024."
        actions={<Link href="/">Learn More</Link>}
      />
      <div className="hero__inner">
        <div className="hero__left">
          <h1 data-text="Unleash Your Data's Potential">Unleash Your Data&apos;s Potential</h1>
          <p>
            Unleash your data&apos;s potential with the help of the DHCS Data Visualization Builder.
            Effortlessly create interactive visualizations and narrate compelling stories with your
            data in minutes. No technical expertise required – simply select your data, customize
            your visuals, and share your insights.
            <br /> Transform your data into impactful visuals with ease.
          </p>
          <div className="hero__footer">
            <Button variant="subtle" iconAfter={<ArrowIcon />} size="M">
              Learn More
            </Button>
            <Button
              onClick={() => (window.location.href = 'https://dhcs.fuselabcreative.com/sign-in')}
              variant="primary"
              size="M"
              iconAfter={<ChevronRightIcon />}
            >
              Try the Builder
            </Button>
          </div>
        </div>
        <div className="hero__right" />
      </div>
    </section>
    <section className="cards">
      <ArticleCard
        title="Select the Perfect Visualization"
        content="With so many visualization option,
                   it can be hard to know where to start, and
                   which chart to use.  Our powerful and intuitive
                    ChartMatch can help you decide."
        imagePath={chartDownImg}
        linkPath="/design-system/chart-match"
      />
      <ArticleCard
        title="Crisp, Clean Design Every Time"
        content="DHCS's custom design system ensures consistency, efficiency, and scalability across projects while empowering designers to prioritize creativity and innovation."
        imagePath={searchChartImg}
        linkPath="/"
      />

      <ArticleCard
        className="last-card"
        imagePath={abcImage}
        title="Build Custom Components"
        content="Whether you're working with PowerBI, Excel, ESRI, or Figma, if you need to build it, we've got you covered. For more information, explore our comprehensive Dev Resources documentation."
        linkPath="/dev-resources"
      />
    </section>
  </div>
);

Overview.defaultProps = {
  className: '',
};

const OverviewPage = styled(Overview)`
  margin: 0 auto;
   @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
       display: flex;
       flex-direction: column;
       flex: 1;
   }
  > .hero {
    background-image: url(${dottedBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 24px;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          padding-top: 16px;
          display: flex;
          flex-direction: column;
          flex:1;
      }

    > .hero__inner {
        flex: 1;
        display: flex;
        justify-content:  center;
        align-items: center;
      gap: 0 48px;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      padding-bottom: 72px;
      padding-top: 48px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-direction: row;
        flex-wrap: nowrap;
          padding-top: 14px;
          padding-bottom: 48px;
      }
      > .hero__left {
        flex-basis: 50%;
        padding-left: 16px;
        padding-right: 16px;
        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          padding-left: 48px;
          padding-right: 0;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
          flex-basis: 35%;
          flex-shrink: 0;
        }
          > h1 {
              font-weight: 600;
              letter-spacing: -1.12px;
              font-size: ${(props) => props.theme.fontSizes.xl};
              line-height: ${(props) => props.theme.lineHeights.xl};
              position: relative;
              background: linear-gradient(to left, #155676, #2685a1);
              color: transparent;
              background-clip: text;
              margin-bottom: 16px;
              
              @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
                  font-size: ${(props) => props.theme.fontSizes['2xl']};
                  line-height: ${(props) => props.theme.lineHeights['2xl']};
              }

              &::after {
                  content: attr(data-text);
                  font-size: ${(props) => props.theme.fontSizes['2xl']};
                  line-height: ${(props) => props.theme.lineHeights['2xl']};
                  width: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
                  color: transparent;
                  -webkit-text-stroke: 1px #383d43;
                  -webkit-text-fill-color: transparent;
                  z-index: -1;
              }
          }

        > p {
          font-size: ${(props) => props.theme.fontSizes.base};
          line-height: ${(props) => props.theme.lineHeights.base};
          font-weight: 400;
          color: ${(props) => props.theme.colors.neutral['text-weak']};
          margin-bottom: 24px;
        }

        > .hero__footer {
          display: flex;
          gap: 24px;
        }
      }
      > .hero__right {
        background-image: url(${overviewHeroImage});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 200px;
        margin-bottom: 32px;

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          flex-basis: 65%;
          background-position: center right;
          height: 440px;
          padding-right: 48px;
          margin-bottom: 0;
        }

          @media (min-width: ${({ theme }) => theme.breakpoints['2xl']}) {
              height: 100%;
          }
      }
    }
  }

  > .cards {
      background: linear-gradient(to top, white, rgba(255, 255, 255, 0)), ${(props) => props.theme.colors.grey['100']};
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: flex;
      flex-direction: row;
        margin-top: auto;
    }
      
      .last-card {
          padding-bottom: 64px;
          border-right: 0;
          @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
              padding-bottom: 24px;
          }
  }
`;

export default OverviewPage;
