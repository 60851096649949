import styled from 'styled-components';

import Button from '../../../../components/Button';
import { ReactComponent as ArrowRight } from '../../../../images/icons/arrow-right.svg';

interface Props {
  className?: string;
  imgSrc: string;
  time: string;
  title: string;
  description: string;
  onReadMore: () => void;
}

const NewsItem = ({ className, imgSrc, time, title, description, onReadMore }: Props) => (
  <div className={className}>
    <img src={imgSrc} alt="img" />

    <div className="news-meta">
      <div className="news-meta__time">{time}</div>
      <h4 className="news-meta__title">{title}</h4>
      <p className="news-meta__description">{description}</p>

      <Button onClick={onReadMore} size="S" variant="subtle" iconAfter={<ArrowRight />}>
        Read More
      </Button>
    </div>
  </div>
);

NewsItem.defaultProps = {
  className: '',
};

const StyledNewsItem = styled(NewsItem)`
  display: flex;
  gap: 24px;
  img {
    max-width: 330px;
    min-height: 100%;
    height: 197px;
    border-radius: 12px;
  }

  .news-meta {
    > .news-meta__time {
      width: fit-content;
      border-radius: 16px;
      font-size: ${({ theme }) => theme.fontSizes.xs};
      line-height: ${({ theme }) => theme.lineHeights.xs};
      padding: 7px 12px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.neutral.text};
      margin-bottom: 12px;
      border: 1px solid ${({ theme }) => theme.colors.neutral.border};
    }

    > .news-meta__title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.neutral.text};
      margin-bottom: 11px;
    }

    > .news-meta__description {
      font-size: ${({ theme }) => theme.fontSizes.base};
      line-height: 24px;
      color: ${({ theme }) => theme.colors.neutral['text-weak']};
      margin-bottom: 24px;
      font-weight: 400;
    }
  }
`;

export default StyledNewsItem;
