import styled from 'styled-components';

const Text = styled.p.withConfig({
  shouldForwardProp: (prop) => !['fontSize', 'lineHeight', 'color', 'textAlign'].includes(prop),
})<{
  fontSize?: string;
  lineHeight?: string;
  color?: string;
  textAlign?: 'left' | 'right' | 'center' | 'justify';
}>`
  font-size: ${({ fontSize }) => fontSize || '16px'};
  line-height: ${({ lineHeight }) => lineHeight || '20px'};
  color: ${({ color }) => color || 'unset'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
`;

export default Text;
