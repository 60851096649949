import styled from 'styled-components';

import ColorSquare from '../../../../../components/ColorSquare';
import ColorVisionStyled from '../../../../../components/ColorVision';
import Table from '../../../../../components/Table';
import { ReactComponent as Checkmark } from '../../../../../images/icons/checkmark.svg';

const dataColorVision = [
  { id: 1, title: 'Normal', color: '#2d6e8d' },
  { id: 2, title: 'Protanopia', color: '#526dbc' },
  { id: 3, title: 'Deuteranopia', color: '#4770b7' },
  { id: 4, title: 'Tritanopia', color: '#517881' },
];

interface Props {
  className?: string;
}

const readabilityTableColumns = [
  { header: 'Usage', key: 'usage' },
  { header: 'Text Size', key: 'textSize' },
  { header: 'Text Style', key: 'textStyle' },
  { header: 'Text Color', key: 'textColor' },
  { header: 'Text Color', key: 'textColor2' },
  { header: 'Contrast Ratio', key: 'contrastRatio' },
];
const readabilityTableData = [
  {
    id: 1,
    usage: 'Axis Label',
    textSize: '12 pt',
    textStyle: 'Regular',
    textColor: <ColorSquare color="#5C636E" />,
    textColor2: <ColorSquare color="#FFFFFF" />,
    contrastRatio: (
      <div className="contrast-ratio-cell">
        6.06:1
        <Checkmark />
      </div>
    ),
  },
];

const contrastTableColumns = [
  { header: 'Usage', key: 'usage' },
  { header: 'Element Color', key: 'elementColor' },
  { header: 'Opacity', key: 'opacity' },
  { header: 'Background Color', key: 'backgroundColor' },
  { header: 'Contrast Ratio', key: 'contrastRatio' },
];

const contrastTableData = [
  {
    id: 1,
    usage: 'Axis Label',
    elementColor: <ColorSquare color="#5C6BC0" />,
    opacity: '100 %',
    backgroundColor: <ColorSquare color="#FFFFFF" />,
    contrastRatio: (
      <div className="contrast-ratio-cell">
        4.86:1
        <Checkmark />
      </div>
    ),
  },
];

const UsabilityTab = ({ className }: Props) => (
  <div className={className}>
    <div className="readability">
      <h2 className="title" id="definition">
        Readability
      </h2>
      <p>
        The color of any text should have a contrast ratio of at least 4.5:1 against the background
        color for standard text and 3:1 for large text. You can measure the color contrast with a
        tool such as the <strong>WebAIM Contrast Checker</strong>. For text legibility, aim for a
        minimum font size of 12pt for most text. Larger text (e.g., 18pt or larger) may be needed
        for data labels or annotations.
      </p>
      <Table
        className="readability-table"
        columns={readabilityTableColumns}
        data={readabilityTableData}
      />
    </div>

    <div className="contrast">
      <h2 className="title">Contrast</h2>
      <p>
        When designing a plot, it&apos;s crucial to ensure strong contrast both against the
        background and between individual elements. Maintain a contrast ratio of{' '}
        <strong>at least 3:1</strong>. For instance, if showcasing two bars in a bar graph with
        colors like red and blue, their contrast should meet or exceed this 3:1 threshold.
      </p>

      <h4 className="contrast__guidelines">Color Contrast Guidelines</h4>
      <Table className="contrast-table" columns={contrastTableColumns} data={contrastTableData} />
      <h4 className="contrast__vision-testing">Color Vision Deficiency Testing</h4>
      <ColorVisionStyled data={dataColorVision} />
    </div>
  </div>
);

UsabilityTab.defaultProps = {
  className: '',
};

const StyledUsabilityTab = styled(UsabilityTab)`
  margin-top: 40px;
  > .readability {
    margin-bottom: 48px;

    > .title {
      margin-bottom: 8px;
    }

    > .readability-table {
      margin-top: 20px;
    }

    strong {
      color: ${({ theme }) => theme.colors.primary.text};
    }
  }

  > .contrast {
    > .title {
      margin-bottom: 16px;
    }

    > h4 {
      color: ${({ theme }) => theme.colors.neutral.text};
      font-size: ${({ theme }) => theme.fontSizes.base};
      line-height: ${({ theme }) => theme.lineHeights.base};
      font-weight: 600;
    }

    > .contrast__guidelines {
      margin-top: 32px;
      margin-bottom: 24px;
    }

    > .contrast__vision-testing {
      margin-bottom: 16px;
    }

    > .contrast-table {
      margin-bottom: 32px;
    }
  }

  .contrast-ratio-cell {
    display: flex;
    align-items: center;
    gap: 8px;

    > svg {
      > path {
        fill: ${({ theme }) => theme.colors.success['background-strong']};
      }
    }
  }

  .title {
    color: ${({ theme }) => theme.colors.neutral.text};
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSizes.lg};
    line-height: ${({ theme }) => theme.lineHeights.lg};
  }

  p {
    color: ${({ theme }) => theme.colors.neutral['text-weak']};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.sm};
    font-weight: 400;
  }
`;

export default StyledUsabilityTab;
