import styled from 'styled-components';

import ExternalLink from '../../../components/ExternalLink';
import PaginationStyled from '../../../components/Pagination';
import StickyNav from '../../../components/StickyNav';
import StickyNavMenuTitle from '../../../components/StickyNav/components/StickyNavMenuTitle';
import { ReactComponent as DownloadIcon } from '../../../images/icons/download.svg';

import aboutImg from './images/about.png';

interface Props {
  className?: string;
}

const About = ({ className }: Props) => (
  <div className={className}>
    <div className="page-wrapper">
      <section className="content">
        <h1 id="about" className="title">
          About
        </h1>
        <div className="description">
          <p>
            The California Department of Health Care Services (DHCS) is the backbone of California’s
            health care safety net, helping millions of Californians every day. DHCS’ purpose is to
            provide equitable access to quality health care, leading to a healthy California for
            all.
          </p>
        </div>
        <div className="description">
          <p>To fulfill this purpose, DHCS has established the following, high-level goals:</p>
          <br />
          <ol>
            <li>
              <b>Be person centered</b> – Put people first and design programs and services for
              whole person care in the community;
            </li>
            <li>
              <b>Increase meaningful access</b> – Ensure individuals get care when, where, and how
              they need it by strengthening health care coverage, benefits, and provider and service
              capacity;
            </li>
            <li>
              <b>Strengthen operations</b> – Enhance our organizational structures, processes, and
              systems to improve program administration;
            </li>
            <li>
              <b>Leverage data to improve outcomes</b> – Drive better decisions and results with
              meaningful information.{' '}
            </li>
          </ol>
        </div>

        <img className="image" src={aboutImg} alt="img" />

        <div className="description">
          <p>
            DHCS is the single state agency responsible for financing and administering the
            state&apos;s Medicaid program, Medi-Cal, which provides health care services to
            low-income persons and families who meet defined eligibility requirements. Medi-Cal is
            authorized and funded through a federal-state partnership. Medi-Cal programs cover
            physical health, mental health, substance use disorder, services, pharmacy, dental, and
            long-term services and supports.
          </p>
          <p>
            As of January 2024, Medi-Cal serves about 15 million Californians. About one-third of
            Californians receive health care services financed or organized by DHCS, making the
            Department the largest health care purchaser in California. Our success is made possible
            only through collaboration with the federal government and other state agencies,
            counties, and partners as we invest nearly $150 billion annually for the care of
            low-income families, children, pregnant women, seniors, and persons with disabilities.
          </p>
        </div>

        <StickyNavMenuTitle
          id="initiatives"
          className="subtitle"
          menuTitle="DHCS Initiatives"
          as="h3"
        >
          DHCS Major Program Initiatives - Go-Live Dates
        </StickyNavMenuTitle>
        <div className="description">
          <p>
            From California Advancing and Innovating Medi-Cal (CalAIM) to transforming the state’s
            behavioral health programs, the Department’s initiatives preserve and improve the
            overall health and well-being of all Californians. The links below reflect our major
            program initiatives and anticipated go-live dates, pending readiness and federal
            approvals. This information will be updated as necessary.
          </p>
        </div>
        <div className="references">
          <h2 id="references" className="title">
            References
          </h2>

          <div className="cards">
            <ExternalLink
              linkUrl=""
              title="DHCS Major Program Initiatives "
              subtitle="Go-Live Dates (PDF)"
              icon={<DownloadIcon />}
            />
            <ExternalLink
              linkUrl=""
              title="DHCS Major Program Initiatives by Category"
              subtitle="Go-Live Dates (PDF)"
              icon={<DownloadIcon />}
            />
            <ExternalLink
              linkUrl=""
              title="DHCS Major Program Initiatives"
              subtitle="Go-Live (Excel)"
              icon={<DownloadIcon />}
            />
          </div>
        </div>
      </section>
      <div>
        <StickyNav
          style={{ width: '180px', marginTop: '30px' }}
          ids={['about', 'initiatives', 'references']}
          title="Contents"
        />
      </div>
    </div>

    <PaginationStyled
      nextLink="/branding-styleguide"
      next="Branding Styleguide"
      className="footer-pagination"
    />
  </div>
);

About.defaultProps = {
  className: '',
};

const StyledAbout = styled(About)`
  .page-wrapper {
    display: flex;
    gap: 40px;
    > .content {
      min-width: 1000px;
      .title:not(span) {
        font-weight: 300;
        color: ${({ theme }) => theme.colors.neutral.text};
        font-size: ${({ theme }) => theme.fontSizes.xl};
        line-height: ${({ theme }) => theme.lineHeights.xl};
        margin-bottom: 24px;
      }

      .subtitle {
        font-size: 20px;
        line-height: 24px;
        color: ${({ theme }) => theme.colors.neutral.text};
        margin-bottom: 16px;
        font-weight: 600;
        max-width: 700px;
      }

      .description {
        font-size: ${({ theme }) => theme.fontSizes.base};
        line-height: ${({ theme }) => theme.lineHeights.base};
        color: ${({ theme }) => theme.colors.neutral['text-weak']};
        font-weight: 400;
        margin-bottom: 40px;
        max-width: 700px;
      }

      ol {
        margin-left: 18px;
        max-width: 700px;

        > li::marker {
          font-weight: bold;
        }
      }

      .image {
        max-height: 400px;
        width: 100%;
        margin-bottom: 40px;
      }

      .references {
        margin-bottom: 40px;
        padding-top: 24px;
        border-top: 1px dashed ${({ theme }) => theme.colors.neutral.border};
        > .title {
          margin-bottom: 40px;
        }

        > .cards {
          display: flex;
          gap: 16px;

          > a {
            width: 100%;
          }
        }
      }
    }
  }

  .footer-pagination {
    display: none;
    margin-top: auto;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
    }
  }
`;

export default StyledAbout;
