import styled from 'styled-components';

import Box from '../../components/Box';
import Button from '../../components/Button';
import StyledChip from '../../components/Chip';
import ContentHeaderStyled from '../../components/ContentHeader';
import Flex from '../../components/Flex';
import PaginationStyled from '../../components/Pagination';
import { ReactComponent as PortalIcon } from '../../images/icons/builder-overview.svg';
import { ReactComponent as DashboardIcon } from '../../images/icons/dashboard.svg';
import { ReactComponent as DownloadIcon } from '../../images/icons/download.svg';
import { ReactComponent as DublicateIcon } from '../../images/icons/dublicate.svg';
import { ReactComponent as EyeIcon } from '../../images/icons/eye.svg';
import { ReactComponent as PowerBiIcon } from '../../images/icons/power-bi.svg';
import { ReactComponent as StoryIcon } from '../../images/icons/storytelling.svg';

import waiverImage from './images/1915waiver.png';
import calAimTemplateImage from './images/calaim-template.png';
import covid19Image from './images/covid19.png';
import demographicsImage from './images/demographics.png';
import ltss1915Image from './images/ltss-1915.png';
import ltsRelationalImage from './images/ltss-relational.png';
import ltssReportImage from './images/ltss-report.png';
import membersByRaceImage from './images/members-by-race.png';
import templateTitleCalaimImage from './images/template-title-calaim.png';

interface Props {
  className?: string;
}

const DashboardExamples = ({ className }: Props) => (
  <div className={className}>
    <ContentHeaderStyled
      title="Dashboard Examples"
      breadcrumbs={[{ label: 'Design System', url: '/' }, { label: 'Dashboard Examples' }]}
      className="hero-section"
    />

    <Box as="section" className="example-section">
      <Flex justify="space-between">
        <Box>
          <h2>1915c waiver participants research</h2>
          <Flex gap="16px">
            <StyledChip size="S" icon={<PortalIcon />}>
              Portal
            </StyledChip>
            <StyledChip size="S" icon={<StoryIcon />}>
              Storytelling
            </StyledChip>
          </Flex>
        </Box>

        <Flex gap="16px">
          <Button iconAfter={<EyeIcon />}>Preview dashboard</Button>
          <Button iconAfter={<DublicateIcon />}>Dublicate</Button>
        </Flex>
      </Flex>
      <p>
        The health plan with the highest number of members is AAH (Alameda Alliance For Health) at
        1.9 mil recorded in 2022, and with 0.2 mil in 2017 Anthem Blue Cross that had the least
        amount of members.
      </p>

      <Flex className="two-img-container" style={{ marginTop: '24px' }} gap="16px">
        <img src={ltss1915Image} alt="img" />

        <img src={demographicsImage} alt="img" />
      </Flex>

      <hr />
    </Box>

    <Box as="section" style={{ marginTop: '40px' }} className="example-section">
      <Flex justify="space-between">
        <Box>
          <h2>LTSS Report</h2>
          <Flex gap="16px">
            <StyledChip size="S" icon={<PowerBiIcon />}>
              Power BI
            </StyledChip>
            <StyledChip size="S" icon={<DashboardIcon />}>
              Dashboard
            </StyledChip>
          </Flex>
        </Box>

        <Flex gap="16px">
          <Button iconAfter={<EyeIcon />}>Preview dashboard</Button>
          <Button iconAfter={<DownloadIcon />}>Download Template</Button>
        </Flex>
      </Flex>
      <p>
        LTSS Reporting Dashboard Template is a comprehensive and user-friendly dashboard template
        designed to manage and analyze Long-Term Services and Supports (LTSS) data. This dashboard
        provides a centralized and interactive platform for visualizing key metrics, tracking
        performance, and making data-driven decisions.
      </p>

      <Flex className="two-img-container" style={{ marginTop: '24px' }} gap="16px">
        <img src={ltssReportImage} alt="img" />

        <img src={membersByRaceImage} alt="img" />
      </Flex>

      <hr />
    </Box>
    <Box as="section" style={{ marginTop: '40px' }} className="example-section">
      <Flex justify="space-between">
        <Box>
          <h2>CalAIM Dashboard</h2>
          <Flex gap="16px">
            <StyledChip size="S" icon={<PowerBiIcon />}>
              Power BI
            </StyledChip>
            <StyledChip size="S" icon={<StoryIcon />}>
              Storytelling
            </StyledChip>
          </Flex>
        </Box>

        <Flex gap="16px">
          <Button iconAfter={<EyeIcon />}>Preview dashboard</Button>
          <Button iconAfter={<DownloadIcon />}>Download Template</Button>
        </Flex>
      </Flex>
      <p>
        The CalAIM Dashboard Storytelling Template provides a structured approach to presenting data
        insights effectively. Incorporating strong UI design principles, including background
        contrast, enhances readability and user experience. Below are detailed instructions with an
        emphasis on background contrast and other UI considerations:{' '}
      </p>

      <Flex className="two-img-container" style={{ marginTop: '24px' }} gap="16px">
        <img src={calAimTemplateImage} alt="img" />

        <img src={templateTitleCalaimImage} alt="img" />
      </Flex>

      <hr />
    </Box>

    <Box as="section" style={{ marginTop: '40px' }} className="example-section">
      <Flex justify="space-between">
        <Box>
          <h2>1915c waiver participants research</h2>
          <Flex gap="16px">
            <StyledChip size="S" icon={<PortalIcon />}>
              Portal
            </StyledChip>
            <StyledChip size="S" icon={<DashboardIcon />}>
              Dashboard
            </StyledChip>
          </Flex>
        </Box>

        <Flex gap="16px">
          <Button iconAfter={<EyeIcon />}>Preview dashboard</Button>
          <Button iconAfter={<DublicateIcon />}>Dublicate</Button>
        </Flex>
      </Flex>
      <p>
        The health plan with the highest number of members is AAH (Alameda Alliance For Health) at
        1.9 mil recorded in 2022, and with 0.2 mil in 2017 Anthem Blue Cross that had the least
        amount of members.
      </p>

      <Box className="single-img-container" style={{ marginTop: '24px' }}>
        <img src={waiverImage} alt="img" />
      </Box>

      <hr />
    </Box>

    <Box as="section" style={{ marginTop: '40px' }} className="example-section">
      <Flex justify="space-between">
        <Box>
          <h2>LTSS Relational</h2>
          <Flex gap="16px">
            <StyledChip size="S" icon={<PowerBiIcon />}>
              Power BI
            </StyledChip>
            <StyledChip size="S" icon={<DashboardIcon />}>
              Dashboard
            </StyledChip>
          </Flex>
        </Box>

        <Flex gap="16px">
          <Button iconAfter={<EyeIcon />}>Preview dashboard</Button>
          <Button iconAfter={<DownloadIcon />}>Download Template</Button>
        </Flex>
      </Flex>
      <p>
        The DHCS LTSS Relational Dashboard is a intuitive dashboard template developed specifically
        for the California Department of Health Care Services (DHCS). It is designed to facilitate
        the management and analysis of Long-Term Services and Supports (LTSS) data, providing a
        centralized and interactive platform to visualize key metrics, monitor performance, and
        support data-driven decision-making.{' '}
      </p>

      <Box className="single-img-container" style={{ marginTop: '24px' }}>
        <img src={ltsRelationalImage} alt="img" />
      </Box>

      <hr />
    </Box>

    <Box as="section" style={{ marginTop: '40px' }} className="example-section">
      <Flex justify="space-between">
        <Box>
          <h2>Covid 19 Vaccination - Linked Services</h2>
          <Flex gap="16px">
            <StyledChip size="S" icon={<PowerBiIcon />}>
              Power BI
            </StyledChip>
            <StyledChip size="S" icon={<DashboardIcon />}>
              Dashboard
            </StyledChip>
          </Flex>
        </Box>

        <Flex gap="16px">
          <Button iconAfter={<EyeIcon />}>Preview dashboard</Button>
          <Button iconAfter={<DownloadIcon />}>Download Template</Button>
        </Flex>
      </Flex>
      <p>
        The DHCS LTSS Relational Dashboard is a intuitive dashboard template developed specifically
        for the California Department of Health Care Services (DHCS). It is designed to facilitate
        the management and analysis of Long-Term Services and Supports (LTSS) data, providing a
        centralized and interactive platform to visualize key metrics, monitor performance, and
        support data-driven decision-making.
      </p>

      <Box className="single-img-container" style={{ marginTop: '24px' }}>
        <img src={covid19Image} alt="img" />
      </Box>
    </Box>

    <PaginationStyled
      previousLink="/design-system/chart-match"
      previous="Chart Match"
      nextLink="/dev-resources"
      next="Dev Resources"
    />
  </div>
);

DashboardExamples.defaultProps = {
  className: '',
};
const StyledDashboardExamples = styled(DashboardExamples)`
  width: 100%;

  .hero-section {
    margin-bottom: 54px;
  }

  .example-section {
    h2 {
      color: ${({ theme }) => theme.colors.neutral.text};
      font-size: ${({ theme }) => theme.fontSizes.xl};
      line-height: ${({ theme }) => theme.fontSizes.xl};
      font-weight: 300;
      margin-bottom: 16px;
    }

    p {
      color: ${({ theme }) => theme.colors.neutral['text-weak']};
      font-size: ${({ theme }) => theme.fontSizes.sm};
      line-height: 20px;
      font-weight: 400;
      margin-top: 24px;
      max-width: 700px;
    }

    .two-img-container,
    .single-img-container {
      img {
        box-shadow: 0 2px 4px 0 #141c2c0f;
        width: 100%;
      }
    }

    .two-img-container {
      img {
        max-height: 312px;
      }
    }

    .single-img-container {
      img {
        max-height: 556px;
      }
    }

    hr {
      border: 1px dashed ${({ theme }) => theme.colors.neutral.border};
      margin-top: 24px;
    }
  }
`;

export default StyledDashboardExamples;
