import styled from 'styled-components';

interface Props {
  className?: string;
  color: string;
}

const ColorSquare = ({ className, color }: Props) => (
  <div className={className}>
    <div className="square" style={{ backgroundColor: color }} />
    <span>
      <span className="hex">#</span>
      {color.replace('#', '')}
    </span>
  </div>
);

ColorSquare.defaultProps = {
  className: '',
};

const StyledColorSquare = styled(ColorSquare)`
  display: flex;
  gap: 8px;
  align-items: center;
  > .square {
    width: 16px;
    height: 16px;
    border: 1px solid ${({ theme }) => theme.colors.neutral.border};
    border-radius: 2px;
  }

  > span {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.sm};
    color: ${({ theme }) => theme.colors.neutral.text};

    > .hex {
      color: ${({ theme }) => theme.colors.neutral['text-weak']};
      margin-right: 2px;
    }
  }
`;

export default StyledColorSquare;
