import styled from 'styled-components';

import CardGroupStyled from '../../components/CardGroup';
import HeroStyled from '../../components/Hero';
import PaginationStyled from '../../components/Pagination';
import commonPageContentStyles from '../../styles/mixins/_commonPageContentStyles';

const dataCardGroups = [
  {
    id: 1,
    title: '1',
    content: '1',
    linkUrl: '1',
    cards: [
      {
        title: 'Buttons',
        content:
          'It outlines the primary and secondary color palettes along with their corresponding hex codes or Pantone values, ensuring consistency across all brand materials.',
        linkUrl: '/general-components/buttons',
        image: '/images/general-components/general-components-buttons@3x.jpg',
      },
      {
        title: 'Links',
        content:
          'This section provides details about the primary and secondary fonts, including font families, weights, and sizes for various use cases such as headings, body text, and captions.',
        linkUrl: '#',
        image: '/images/general-components/general-components-links@3x.jpg',
      },
      {
        title: 'Form Elements',
        content: `Here, you'll find guidelines on logo usage, including acceptable variations such as full-color, monochrome, and reverse versions.`,
        linkUrl: '#',
        image: '/images/general-components/general-components-form-elements@3x.jpg',
      },
    ],
  },
  {
    id: 2,
    title: '1',
    content: '1',
    linkUrl: '1',
    cards: [
      {
        title: 'Communication',
        content:
          'This section outlines the design principles for icons, including style, grid system, and preferred sizes. It may also specify guidelines for icon usage in different contexts.',
        linkUrl: '#',
        image: '/images/general-components/general-components-communication@3x.jpg',
      },
      {
        title: 'Navigation',
        content: `Provides instructions on the types of images that align with the brand's visual identity, such as photography style, subject matter, and color treatment.`,
        linkUrl: '#',
        image: '/images/general-components/general-components-navigation@3x.jpg',
      },
      {
        title: 'Selection',
        content: `Provides instructions on the types of images that align with the brand's visual identity, such as photography style, subject matter, and color treatment.`,
        linkUrl: '#',
        image: '/images/general-components/general-components-selection@3x.jpg',
      },
    ],
  },
  {
    id: 3,
    title: '1',
    content: '1',
    linkUrl: '1',
    cards: [
      {
        title: 'Containment',
        content: `Here, you'll find guidelines on logo usage, including acceptable variations such as full-color, monochrome, and reverse versions.`,
        linkUrl: '#',
        image: '/images/general-components/general-components-containment@3x.jpg',
      },
      {
        title: 'Table',
        content: `It outlines the primary and secondary color palettes along with their corresponding hex codes or Pantone values, ensuring consistency across all brand materials.`,
        linkUrl: '#',
        image: '/images/general-components/general-components-table@3x.jpg',
      },
    ],
  },
];
interface Props {
  className?: string;
}

const GeneralComponentsStructure = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <HeroStyled
        title="General Components"
        subtitle={
          <>
            <p>
              Outlines the visual and stylistic elements of a brand to ensure consistency across all
              communication channels. It typically includes guidelines for logo usage, color
              palette, typography, imagery, and overall design principles.
            </p>
            <p>
              The purpose of a branding style guide is to maintain brand consistency, ensure brand
              recognition, and foster a cohesive brand identity across all touchpoints and mediums.
            </p>
          </>
        }
        category
      />
      <div>
        {dataCardGroups.map((cardGroup) => (
          <CardGroupStyled
            key={cardGroup.id}
            title={cardGroup.title}
            content={cardGroup.content}
            linkUrl={cardGroup.linkUrl}
            cards={cardGroup.cards}
            category
          />
        ))}
      </div>
      <PaginationStyled
        previousLink="/branding-styleguide"
        previous="Branding Styleguide"
        previousDisabled="disabled"
        nextLink="/general-components/buttons"
        next="Buttons"
      />
    </div>
  </div>
);

GeneralComponentsStructure.defaultProps = {
  className: '',
};

const GeneralComponents = styled(GeneralComponentsStructure)`
  ${commonPageContentStyles}

  height: 100%;
  overflow: unset;

  .content {
    padding: 0;
  }
`;

export default GeneralComponents;
