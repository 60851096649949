import { CSSProperties } from 'react';
import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

interface Props {
  className?: string;
  title: string;
  ids?: string[];
  style?: CSSProperties;
}

const StickyNav = ({
  style,
  className,
  title,
  ids = ['definition', 'usage', 'limitations', 'colors', 'related-plots'],
}: Props) => (
  <div style={style} className={className}>
    <h4>{title}</h4>

    <Scrollspy
      ids={ids}
      offset={0}
      activeItemClassName="active"
      itemContainerClassName="multilevel-link-list"
    />
  </div>
);

StickyNav.defaultProps = {
  className: '',
  ids: ['definition', 'usage', 'limitations', 'colors', 'related-plots'],
  style: {},
};

const StyledStickyNav = styled(StickyNav)`
  position: sticky;
  margin-top: 40px;
  top: 40px;
  left: 100%;
  width: 11.25rem;

  > h4 {
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.neutral.text};
    font-size: ${({ theme }) => theme.fontSizes.base};
    line-height: ${({ theme }) => theme.lineHeights.base};
  }

  ul {
    list-style: none;

    > li {
      position: relative;
      padding: 8px 24px;
      color: ${({ theme }) => theme.colors.neutral['text-weak']};
      font-weight: 500;
      font-size: ${({ theme }) => theme.fontSizes.sm};
      line-height: ${({ theme }) => theme.lineHeights.sm};
      cursor: pointer;

      &:before {
        left: 0;
        content: '';
        position: absolute;
        top: 0;
        width: 2px;
        height: 100%;
        background-color: ${({ theme }) => theme.colors.neutral.border};
        transition:
          background-color 0.3s ease-in-out,
          border-color 0.3s ease-in-out;
      }

      &:hover:before {
        background-color: ${({ theme }) => theme.colors.neutral['border-medium']};
      }

      &.active {
        color: ${({ theme }) => theme.colors.primary.text};
        &:before {
          background-color: ${({ theme }) => theme.colors.primary.border};
        }
      }
    }
  }
`;

export default StyledStickyNav;
