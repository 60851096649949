import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

type ButtonSize = 'S' | 'M';

interface Props {
  size?: ButtonSize;
  icon?: ReactNode;
  isError?: boolean;
  className?: string;
  children: ReactNode;
  onClick?: () => void;
}

const buttonStyles = css`
  color: ${(props) => props.theme.colors.primary.text};

  > svg {
    > path {
      fill: ${(props) => props.theme.colors.primary.text};
    }
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary['text-hover']};

    > svg {
      > path {
        fill: ${(props) => props.theme.colors.primary['text-hover']};
      }
    }
  }

  &:active {
    color: ${(props) => props.theme.colors.primary['text-hover']};

    > svg {
      > path {
        fill: ${(props) => props.theme.colors.primary['text-hover']};
      }
    }
  }

  &:disabled {
    color: ${(props) => props.theme.colors.neutral['text-disabled']};

    > svg {
      > path {
        fill: ${(props) => props.theme.colors.neutral['text-disabled']};
      }
    }
  }

  &.btn-link-error {
    color: ${(props) => props.theme.colors.alert.text};

    > svg {
      > path {
        fill: ${(props) => props.theme.colors.alert.text};
      }
    }
  }
`;

const sizeButtonWithTextStyles = {
  S: css`
    font-size: ${(props) => props.theme.fontSizes.xs};
    line-height: ${(props) => props.theme.lineHeights.xs};
  `,
  M: css`
    font-size: ${(props) => props.theme.fontSizes.sm};
    line-height: ${(props) => props.theme.lineHeights.sm};
  `,
};

const StyledButton = styled.button<{
  size: ButtonSize;
}>`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  border: none;
  background: transparent;
  padding: 4px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;

  ${buttonStyles}

  > svg {
    width: 12px;
    height: 12px;
  }

  ${({ size }) => sizeButtonWithTextStyles[size]}
`;

const ButtonLink = ({ children, size = 'M', icon, className = '', isError, onClick }: Props) => (
  <StyledButton
    onClick={onClick}
    size={size}
    className={`${className} ${isError ? 'btn-link-error' : ''}`}
  >
    {children} {icon}
  </StyledButton>
);

ButtonLink.defaultProps = {
  size: 'M',
  icon: undefined,
  isError: false,
  className: '',
  onClick: undefined,
};

export default ButtonLink;
