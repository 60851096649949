import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  tabs: { id: string; content: ReactNode; title: ReactNode }[];
  onChange?: (tab: string) => void;
  defaultActiveTab?: string;
}

const Tabs = ({ className, tabs, onChange, defaultActiveTab = '' }: Props) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  return (
    <div className={className}>
      <div className="tabs-header">
        <h2>Visualization Builder</h2>
        <div className="tabs-header__buttons">
          {tabs.map((tab) => (
            <button
              type="button"
              key={tab.id}
              onClick={() => {
                onChange?.(tab.id);
                setActiveTab(tab.id);
              }}
              className={activeTab === tab.id ? 'tab-button tab-button--active' : 'tab-button'}
            >
              {tab.title}
            </button>
          ))}
        </div>
      </div>

      <hr />

      <div className="tabs-content">{tabs.find((tab) => tab.id === activeTab)?.content}</div>
    </div>
  );
};

Tabs.defaultProps = {
  onChange: () => {},
  defaultActiveTab: '',
  className: '',
};

const StyledTabs = styled(Tabs)`
  .tabs-header {
    display: flex;
    justify-content: space-between;
    align-items: self-end;
    flex-wrap: wrap;
    margin-top: 20px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      justify-content: space-between;
      align-items: self-end;
      flex-wrap: nowrap;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints['2xl']}) {
      margin-top: 40px;
    }

    .tabs-header__buttons {
      display: inline-flex;
      overflow-x: auto;
      white-space: nowrap;
      padding: 6px;
      background-color: ${({ theme }) => theme.colors.neutral.background};
      border-radius: 6px;
      margin-right: 0;
      margin-top: 30px;
      margin-bottom: 42px;

      // Hide scrollbar for Chrome, Safari and Opera
      &::-webkit-scrollbar {
        display: none;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-right: 48px;
        margin-top: 0;
        margin-bottom: 0;
      }

      > .tab-button {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        white-space: nowrap;
        background-color: transparent;
        color: ${({ theme }) => theme.colors.neutral['text-weak']};
        padding: 4px 8px;
        border: 0;
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontSizes.sm};
        line-height: ${({ theme }) => theme.lineHeights.sm};
        border-radius: 4px;
        outline: none;

        &--active {
          background-color: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.neutral.text};
          box-shadow: 0px 2px 4px 1px #141c2c0a;
        }
      }
    }
    > h2 {
      background: linear-gradient(to left, #155676, #2685a1);
      font-weight: 600;
      font-size: ${({ theme }) => theme.fontSizes.xl};
      line-height: ${({ theme }) => theme.lineHeights.xl};
      color: transparent;
      background-clip: text;
    }
  }

  > hr {
    display: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
      border-top: 1px solid #d5d7db;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  > .tabs-content {
    height: 100%;
    flex: 1;
    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      min-height: 382px;
    }
  }
`;

export default StyledTabs;
