import styled from 'styled-components';

import PaginationStyled from '../../../components/Pagination';
import StickyNav from '../../../components/StickyNav';
import StickyNavMenuTitle from '../../../components/StickyNav/components/StickyNavMenuTitle';

import userDesignImg from './images/user-design.png';

interface Props {
  className?: string;
}

const DesignPrinciples = ({ className }: Props) => (
  <div className={className}>
    <div className="page-wrapper">
      <section className="content">
        <h1 className="title">Design Principles</h1>
        <StickyNavMenuTitle id="overview" className="subtitle" menuTitle="Overview" as="h3">
          User-Centered Design
        </StickyNavMenuTitle>

        <div className="description">
          <p>
            DHCS design decisions should always begin with understanding real user needs. Research,
            analyze data, and engage with users throughout the design process to ensure that DHCS
            digital products are useful, usable, and accessible.
          </p>
        </div>
        <img className="image" src={userDesignImg} alt="img" />

        <StickyNavMenuTitle id="definition" className="subtitle" menuTitle="Definition" as="h3">
          Do Less, Do Better
        </StickyNavMenuTitle>

        <div className="description">
          <p>
            Prioritize simplicity and efficiency in design. Focus on delivering the essential
            functionality that meets user needs effectively. Avoid unnecessary complexity and strive
            to streamline processes to make interactions with DHCS digital products as
            straightforward as possible.
          </p>
        </div>

        <h3 className="subtitle">
          Design with Data
          <div style={{ opacity: 0, width: '0px', height: '0px' }} id="Design Principles">
            Design Principles
          </div>
        </h3>

        <div className="description">
          <p>
            Let data drive decision-making. Utilize analytics and user feedback to continuously
            evaluate and improve DHCS digital products. Measure the effectiveness of designs,
            iterate based on insights, and prioritize features and enhancements that have the
            greatest positive impact on users.
          </p>
        </div>
      </section>
      <div>
        <StickyNav
          style={{ width: '180px', marginTop: '30px' }}
          ids={['overview', 'definition']}
          title="Contents"
        />
      </div>
    </div>
    <PaginationStyled
      nextLink="/branding-styleguide"
      next="Branding Styleguide"
      className="footer-pagination"
    />
  </div>
);

DesignPrinciples.defaultProps = {
  className: '',
};

const StyledDesignPrinciples = styled(DesignPrinciples)`
  .page-wrapper {
    display: flex;
    gap: 40px;
    > .content {
      min-width: 1000px;

      .title {
        font-weight: 300;
        color: ${({ theme }) => theme.colors.neutral.text};
        font-size: ${({ theme }) => theme.fontSizes.xl};
        line-height: ${({ theme }) => theme.lineHeights.xl};
        margin-bottom: 24px;
        max-width: 700px;
      }

      .subtitle {
        font-size: 20px;
        line-height: 24px;
        color: ${({ theme }) => theme.colors.neutral.text};
        margin-bottom: 16px;
        font-weight: 600;
        max-width: 700px;
      }

      .description {
        font-size: ${({ theme }) => theme.fontSizes.base};
        line-height: ${({ theme }) => theme.lineHeights.base};
        color: ${({ theme }) => theme.colors.neutral['text-weak']};
        font-weight: 400;
        margin-bottom: 40px;
        max-width: 700px;
      }

      ol {
        max-width: 700px;

        margin-left: 18px;

        > li::marker {
          font-weight: bold;
        }
      }

      .image {
        max-height: 400px;
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }

  .footer-pagination {
    display: none;
    margin-top: auto;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
    }
  }
`;

export default StyledDesignPrinciples;
