import styled from 'styled-components';

import { GetStartedTabs } from '../../types';

interface Props {
  className?: string;
  onSelect: (tab: GetStartedTabs) => void;
  activeTab: GetStartedTabs;
}

const ContentNavigation = ({ className, onSelect, activeTab }: Props) => (
  <div className={className}>
    <ul>
      <li>
        <button
          onClick={() => onSelect('explore')}
          type="button"
          className={activeTab === 'explore' ? 'active' : ''}
        >
          Explore
        </button>
      </li>
      <li>
        <button
          onClick={() => onSelect('how-to-use')}
          className={activeTab === 'how-to-use' ? 'active' : ''}
          type="button"
        >
          How to Use
        </button>
      </li>
      <li>
        <button
          onClick={() => onSelect('design-principles')}
          className={activeTab === 'design-principles' ? 'active' : ''}
          type="button"
        >
          Design Principles
        </button>
      </li>
      <li>
        <button
          onClick={() => onSelect('about')}
          className={activeTab === 'about' ? 'active' : ''}
          type="button"
        >
          About
        </button>
      </li>
    </ul>
  </div>
);

ContentNavigation.defaultProps = {
  className: '',
};

const ContentNavigationStyled = styled(ContentNavigation)`
  border-bottom: 0.0625rem solid var(--dadce0, #dadce0);
  margin-bottom: 2.5rem;

  ul {
    display: flex;
    list-style: none;
    gap: 1.5rem;
    margin: 0;
    padding-left: 0;
  }

  button {
    padding: 1.5rem 0;
    font-size: 0.875rem;
    line-height: 1.1428571429;
    color: var(--5f6877, #5f6877);
    background: transparent;
    border: 0 none;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    //letter-spacing: 0.1px;

    &::after {
      content: '';
      position: absolute;
      bottom: -0.0625rem;
      height: 0.125rem;
      background-color: transparent;
      width: 100%;
      left: 0;
      transition: background 0.3s ease-in-out;
    }

    &:hover {
      color: var(--000005, #000005);

      &::after {
        background: var(--9fa4ad, #9fa4ad);
      }
    }

    &.active {
      color: var(--000005, #000005);
      text-shadow:
        0 0 0.5px #000005,
        0 0 0.5px #000005;

      &::after {
        background: var(--2d6e8d, #2d6e8d);
      }
    }

    &:disabled {
      pointer-events: none;
      color: var(--9fa4ad, #9fa4ad);
    }
  }
`;

export default ContentNavigationStyled;
