import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import PaginationStyled from '../../../components/Pagination';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government usability testing resources',
    cards: [
      {
        title: 'Learn about writing in plain language ',
        subtitle: '[plainlanguage.gov]',
        linkUrl: '#',
      },
      {
        title: 'Testing for plain-language usability',
        subtitle: '[18f.gov]',
        linkUrl: '#',
      },
      {
        title: 'IBM Web Accessibility Checklist',
        subtitle: '[plainlanguage.gov]',
        linkUrl: '#',
      },
      {
        title: 'Government usability testing resources',
        subtitle: '[digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Testing for plain-language usability',
        subtitle: '[18f.gov]',
        linkUrl: '#',
      },
    ],
  },
  {
    id: 2,
    title: 'Non-government accessibility resources',
    cards: [
      {
        title: 'Web Accessibility Perspectives Videos ',
        subtitle: '[w3.org]',
        linkUrl: '#',
      },
      {
        title: 'Inclusive design principles',
        subtitle: '[inclusivedesignprinciples.org]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility fundamentals with Rob Dodson ',
        subtitle: '[youtube.com]',
        linkUrl: '#',
      },
    ],
  },
];

const VoiceAndToneStructure = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <ContentHeaderStyled
        title="Voice and Tone"
        breadcrumbs={[{ label: 'Get Started', url: '/' }, { label: 'Voice & Tone' }]}
      />
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-with-navigation-wrapper">
            <div className="page-content-with-navigation">
              <div className="page-content-item">
                <h1 className="h1" id="overview">
                  Overview
                </h1>
                <h3 className="h3" id="definition">
                  Definition
                </h3>
                <p>
                  DHCS’s voice is a reflection Of who we are. We should always sound like Shopify.
                </p>
                <p className="mb-40">
                  At the same time, some aspects of our personality might be more or less apparent,
                  depending on the audience and their context. That&apos;s tone.
                </p>
                <h3 className="h3" id="dhcs-s-voice">
                  DHCS’s voice
                </h3>
                <p>Our voice is a reflection of who we are. So who are we?</p>
                <p>
                  Simply put, we&apos;re a company, built by real people who understand this
                  business and care about helping others succeed.
                  <br />
                  That should be clear every time someone uses our products or services.
                </p>
                <p className="mb-40">
                  Most often, we show this through well-crafted tools that help people get their
                  jobs done efficiently. But there are times when our personalities and perspectives
                  Should be more apparent, In those times, we Should adapt our tone to respond to
                  people in an appropriate and meaningful way.
                </p>
              </div>
              <h1 className="divider h1" id="guidelines">
                Guidelines
              </h1>
              <div className="page-content-item">
                <h3 className="h3" id="best-practices">
                  Best Practices
                </h3>
                <p className="mb-40">As Shopify&apos;s voice, we should always:</p>
                <p>
                  <strong>Be real, but not too tough or overly familiar</strong>
                </p>
                <ul className="disc-list">
                  <li>
                    Use business casual language—be plain-spoken, not pretentious or overly playful
                  </li>
                  <li>Be upfront and honest with merchants, even if we make a mistake</li>
                  <li>Communicate with clarity and empathy—be direct, not insensitive</li>
                </ul>
                <p>
                  <strong>Be dynamic, but not scattered or impulsive</strong>
                </p>
                <ul className="disc-list">
                  <li>Communicate in a way that respects merchant emotion and context</li>
                  <li>
                    Avoid words that generalize success like &quot;every,&quot; &quot;all,&quot; and
                    &quot;most&quot;
                  </li>
                  <li> Use language that supports action, movement, and progress</li>
                </ul>
                <h3 className="visually-hidden" id="adapting-tone">
                  Adapting tone
                </h3>
                <h3 className="h3">Adapting tone by situation</h3>
                <p>
                  Our tone adapts to the context. We&apos;ll use certain voice attributes more or
                  less based on the situation.
                </p>
                <p>
                  Often people frame tone guidance around adapting to the emotional state of the
                  audience. The reality is we never know a person&apos;s emotional state. Even when
                </p>
                <p className="mb-40">
                  While it&apos;s helpful to consider how your audience is likely to feel,
                  don&apos;t assume or tell them how to feel, Instead, focus on the of the situation
                  and less on the emotions. Here are some of the most common situations to consider.
                </p>
                <h3 className="visually-hidden" id="everyday-tasks">
                  Everyday tasks
                </h3>
                <h3 className="h3">Everyday tasks and activities</h3>
                <p className="mb-40">
                  When everything is working as it should, our goal is to give people what they need
                  to get work done, without getting in the way or drawing attention to ourselves, We
                  want the audience to know what something is or that something has happened as
                  expected,
                </p>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-voice-and-tone/screen-everyday-tasks-01@3x.jpg"
                    alt="Look for the simplest way to communicate information—words aren’t always
                      necessary"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>
                      Look for the simplest way to communicate information—words aren’t always
                      necessary.
                    </p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-voice-and-tone/screen-everyday-tasks-02@3x.jpg"
                    alt="Use overly complicated or intimidating language"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>Use overly complicated or intimidating language.</p>
                  </div>
                </div>
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-voice-and-tone/screen-everyday-tasks-03@3x.jpg"
                    alt="Be consistent for identical actions or destinations when possible"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>Be consistent for identical actions or destinations when possible.</p>
                    <ul className="disc-list">
                      <li>Remove measure</li>
                      <li>Delete collection</li>
                    </ul>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-voice-and-tone/screen-everyday-tasks-04@3x.jpg"
                    alt="Add extra text just to fill space"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>Add extra text just to fill space.</p>
                  </div>
                </div>
              </div>
              <div className="page-content-item">
                <h3 className="h3" id="simple-errors">
                  Simple errors
                </h3>
                <p className="mb-40">
                  While we do our best to be proactive and protect against unexpected events, they
                  still happen. These are often simple mistakes or roadblocks to action that can
                  easily be fixed. Although they aren&apos;t necessarily errors or problems, they
                  can feel that way at first.
                </p>
                <p className="mb-40">
                  In this case, our job is to help people understand what happened so that they can
                  get back to work as quickly as possible.
                </p>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-voice-and-tone/screen-simple-errors-01@3x.jpg"
                    alt="Look for the simplest way to communicate information—words aren't
                      always necessary"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>
                      Look for the simplest way to communicate information—words aren&apos;t always
                      necessary.
                    </p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-voice-and-tone/screen-simple-errors-02@3x.jpg"
                    alt="Use overly complicated or intimidating language"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>Use overly complicated or intimidating language.</p>
                  </div>
                </div>
              </div>
              <div className="page-content-item">
                <h3 className="visually-hidden" id="announce">
                  Announce
                </h3>
                <h3 className="h3">Announcing new features and updates</h3>
                <p className="mb-40">
                  It&rsquo;s exciting to launch a new feature or update, but always consider the
                  audience&rsquo;s first. While we may be excited aMut the benefits and
                  capabilities, change can be intimidating or annoying for users.
                </p>
                <p className="mb-40">
                  When you&apos;re announcing something new, fcrus on educating and explaining what
                  it is, what used for. and what the user can expect-
                </p>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>Pitch our product by explaining the specific merchant benefits.</p>
                    <p>
                      <strong>Introducing the new Map Charts Updates</strong>
                    </p>
                    <p>
                      Make it your own with custom fonts, colors, and styles. It also
                      <br />
                      loads 50% faster so your visitors won&apos;t miss it.
                    </p>
                  </div>
                </div>
                <div className="ex-card false">
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>
                      Just talk about ourselves and assume the experience for merchants will be
                      easy.
                    </p>
                    <p>
                      <strong>Get DHCS’s new Map Charts Updates</strong>
                    </p>
                    <p>
                      It&rsquo;s never been easier to customize the fonts, colors, and styles of
                      <br />
                      Buy Buttons. It&rsquo;s also twice as fast.
                    </p>
                  </div>
                </div>
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-voice-and-tone/screen-announce-01@3x.jpg"
                    alt="If possible, let users dismiss announcements or postpone
                        changes until they're ready."
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>
                      If possible, let users dismiss announcements or postpone changes until
                      they&apos;re ready.
                    </p>
                  </div>
                </div>
              </div>
              <div className="page-content-item">
                <h3 className="h3" id="social">
                  Social
                </h3>
                <p className="mb-40">
                  When announcing something new in your social posts, prioritize educating and
                  explaining. Clearly outline what the new feature is, how it can be used, and what
                  benefits users can expect. Providing this information helps to ease concerns and
                  ensures that your audience understands and appreciates the updates.
                </p>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-voice-and-tone/screen-social-01@3x.jpg"
                    alt=""
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-voice-and-tone/screen-socials-02@3x.jpg"
                    alt=""
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                  </div>
                </div>
              </div>
              <div className="page-content-item">
                <h3 className="visually-hidden" id="podcast">
                  Podcast
                </h3>
                <h3 className="h3">Podcast or Webcast</h3>
                <p className="mb-40">
                  Launching new features or updates in your podcast or webcast is always exciting,
                  but it’s important to keep your audience’s viewpoint in mind. While we may be
                  excited about the enhancements, change can sometimes be unsettling or bothersome
                  for listeners.
                </p>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-voice-and-tone/screen-podcast-01@3x.jpg"
                    alt=""
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    className="ex-card-img"
                    src="/images/get-started-voice-and-tone/screen-podcast-02@3x.jpg"
                    alt=""
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                  </div>
                </div>
              </div>
              <h1 className="divider h1" id="references">
                References
              </h1>
              {dataExternalLinkGroups.map((externalLinkGroup) => (
                <ExternalLinkGroupStyled
                  key={externalLinkGroup.id}
                  title={externalLinkGroup.title}
                  cards={externalLinkGroup.cards}
                />
              ))}
            </div>
            <div>
              <div className="page-navigation">
                <h4>Contents</h4>
                <Scrollspy
                  ids={[
                    'overview',
                    'definition',
                    'dhcs-s-voice',
                    'guidelines',
                    'best-practices',
                    'adapting-tone',
                    'everyday-tasks',
                    'simple-errors',
                    'announce',
                    'social',
                    'podcast',
                    'references',
                  ]}
                  offset={0}
                  activeItemClassName="active"
                  itemContainerClassName="multilevel-link-list"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaginationStyled
        previousLink="/get-started/content"
        previous="Content"
        nextLink="/branding-styleguide"
        next="Branding Styleguide"
      />
    </div>
  </div>
);

VoiceAndToneStructure.defaultProps = {
  className: '',
};

const VoiceAndTone = styled(VoiceAndToneStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 3, 5, 6, 7, 8, 9, 10, 11])}
    overflow: unset;
  height: 100%;

  > .content {
    width: 100%;
    padding: 0;
  }
`;

export default VoiceAndTone;
