import styled from 'styled-components';

import PaginationStyled from '../../../components/Pagination';
import StickyNav from '../../../components/StickyNav';
import StickyNavMenuTitle from '../../../components/StickyNav/components/StickyNavMenuTitle';

import firstImg from './images/first-img.png';
import secondImg from './images/second-img.png';
import thirdImg from './images/third-img.png';

interface Props {
  className?: string;
}

const HowToUse = ({ className }: Props) => (
  <div className={className}>
    <div className="page-wrapper">
      <section className="content">
        <h1 className="title">How to Use the DHCS Design System</h1>
        <div className="description">
          <p>
            The DHCS Design System is a comprehensive set of guidelines, components, and tools to
            help you create consistent, accessible, and user-friendly interfaces for all Department
            of Health Care Services (DHCS) applications. Follow these steps to implement the system
            effectively:
          </p>
        </div>
        <StickyNavMenuTitle
          id="design_principles"
          className="subtitle"
          menuTitle="Design Principles"
          as="h3"
        >
          1. Understand the Design Principles
        </StickyNavMenuTitle>

        <div className="description">
          <p>
            Before starting, familiarize yourself with our core design principles: accessibility,
            consistency, and user-centric design. These principles guide every element within the
            design system and ensure that DHCS digital products are inclusive and easy to use.
          </p>
        </div>
        <img className="image" src={firstImg} alt="img" />

        <StickyNavMenuTitle
          id="components_patterns"
          className="subtitle"
          menuTitle=" Components and Patterns"
          as="h3"
        >
          2. Explore Components and Patterns
        </StickyNavMenuTitle>

        <div className="description">
          <p>
            Browse the components library to find reusable elements such as buttons, forms, icons,
            and navigation. Each component is designed for flexibility and accessibility, with
            documentation on:
          </p>
          <br />
          <ol>
            <li>
              <b>Diverse Abilities:</b> Crafting content that is accessible to users with varying
              abilities, including those with visual impairments or cognitive disabilities.
            </li>
            <li>
              <b>Mobile:</b> Optimizing content for mobile devices, ensuring readability and
              usability on smaller screens.
            </li>
            <li>
              <b>Big Data Sets:</b> Organizing large volumes of content to maintain clarity and ease
              of navigation.
            </li>
          </ol>
        </div>

        <StickyNavMenuTitle
          id="style_guide"
          className="subtitle"
          menuTitle="Leverage Style Guidelines"
          as="h3"
        >
          3. Leverage Style Guidelines
        </StickyNavMenuTitle>

        <div className="description">
          <p>
            Apply the provided typography, color schemes, and spacing guidelines to create a
            cohesive look and feel. Use the predefined color palettes to maintain visual consistency
            across all DHCS applications. Typography should align with the font hierarchy and
            scaling rules for readability and clarity.
          </p>
          <p>
            Design tokens are the foundational elements like colors, spacing, and font sizes in
            coded form. By using tokens, you ensure your application automatically stays in sync
            with system-wide updates. Access tokens through the design system repository and
            integrate them into your CSS or development framework.
          </p>
        </div>

        <StickyNavMenuTitle
          id="tokens"
          className="subtitle"
          menuTitle="Use the Design Tokens"
          as="h3"
        >
          4. Use the Design Tokens
        </StickyNavMenuTitle>

        <div className="description">
          <p>
            Design tokens are the foundational elements like colors, spacing, and font sizes in
            coded form. By using tokens, you ensure your application automatically stays in sync
            with system-wide updates. Access tokens through the design system repository and
            integrate them into your CSS or development framework.
          </p>
        </div>
        <img className="image" src={secondImg} alt="img" />

        <StickyNavMenuTitle
          id="accessibility"
          className="subtitle"
          menuTitle="Accessibility Best Practices"
          as="h3"
        >
          5. Follow Accessibility Best Practices
        </StickyNavMenuTitle>

        <div className="description">
          <p>
            Ensure that all designs follow the DHCS accessibility standards. Every component and
            pattern in the design system has built-in accessibility features, but it&apos;s
            essential to follow these guidelines during implementation. For detailed guidance, refer
            to our accessibility documentation.
          </p>
        </div>

        <StickyNavMenuTitle
          id="up_to_date"
          className="subtitle"
          menuTitle="Stay Up to Date"
          as="h3"
        >
          6. Stay Up to Date
        </StickyNavMenuTitle>

        <div className="description">
          <p>
            The design system will continue to evolve as DHCS needs grow. Subscribe to updates and
            check the system documentation regularly to stay informed of new components, design
            patterns, and improvements.
          </p>
        </div>
        <img className="image" src={thirdImg} alt="img" />

        <StickyNavMenuTitle id="support" className="subtitle" menuTitle="Get Support" as="h3">
          7. Get Support
        </StickyNavMenuTitle>

        <div className="description">
          <p>
            If you encounter any challenges while using the design system, consult the FAQ or reach
            out to the support team. For feature requests or feedback, use the dedicated feedback
            channels within the design system portal.
          </p>
        </div>
      </section>
      <div>
        <StickyNav
          style={{ width: '180px', marginTop: '30px' }}
          ids={[
            'design_principles',
            'components_patterns',
            'style_guide',
            'tokens',
            'accessibility',
            'up_to_date',
            'support',
          ]}
          title="Contents"
        />
      </div>
    </div>
    <PaginationStyled
      nextLink="/branding-styleguide"
      next="Branding Styleguide"
      className="footer-pagination"
    />
  </div>
);

HowToUse.defaultProps = {
  className: '',
};

const StyledHowToUse = styled(HowToUse)`
  .page-wrapper {
    display: flex;
    gap: 40px;
    > .content {
      min-width: 1000px;
      .title {
        font-weight: 300;
        color: ${({ theme }) => theme.colors.neutral.text};
        font-size: ${({ theme }) => theme.fontSizes.xl};
        line-height: ${({ theme }) => theme.lineHeights.xl};
        margin-bottom: 24px;
        max-width: 700px;
      }

      .subtitle {
        font-size: 20px;
        line-height: 24px;
        color: ${({ theme }) => theme.colors.neutral.text};
        margin-bottom: 16px;
        font-weight: 600;
        max-width: 700px;
      }

      .description {
        font-size: ${({ theme }) => theme.fontSizes.base};
        line-height: ${({ theme }) => theme.lineHeights.base};
        color: ${({ theme }) => theme.colors.neutral['text-weak']};
        font-weight: 400;
        margin-bottom: 40px;
        max-width: 700px;
      }

      ol {
        margin-left: 18px;
        max-width: 700px;

        > li::marker {
          font-weight: bold;
        }
      }

      .image {
        max-height: 400px;
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }

  .footer-pagination {
    display: none;
    margin-top: auto;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
    }
  }
`;

export default StyledHowToUse;
