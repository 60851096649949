import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import Button from '../../../components/Button';
import ContentHeaderStyled from '../../../components/ContentHeader';
import PaginationStyled from '../../../components/Pagination';
import { ReactComponent as Download } from '../../../images/icons/download.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const PowerBIKitStructure = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <ContentHeaderStyled
        title="Power BI Library"
        breadcrumbs={[
          { label: 'Dev Resources', url: '/dev-resources' },
          { label: 'Power BI Library' },
        ]}
      />
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-with-navigation-wrapper">
            <div className="page-content-with-navigation">
              <div className="title-with-download-button">
                <h1 className="h1" id="theme-file">
                  Theme File
                </h1>
                <Button size="M" iconAfter={<Download />}>
                  Download Power BI Library
                </Button>
              </div>
              <div className="page-content-item">
                <div className="mb-40">
                  <p className="font-sm">
                    Contains all styles, including chart, text, and color styles in a .json format.
                    This file has to be imported to the platform every single time a new project is
                    created.
                  </p>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-1@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <h3 id="importing-theme" className="mb-40 h3">
                  Importing Theme File
                </h3>
                <div className="pseudo-list">
                  <span className="pseudo-list-nr">1</span>
                  <p>
                    Under “View” option, select the “Themes” dropdown button &gt; “Browse for
                    themes”.
                  </p>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-2@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <div className="pseudo-list">
                  <span className="pseudo-list-nr">2</span>
                  <p>In the window select your theme file - “Theme.json”.</p>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-3@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <div className="pseudo-list">
                  <span className="pseudo-list-nr">3</span>
                  <p>
                    When the theme file has loaded successfully, Power BI Desktop displays a success
                    message.
                  </p>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-4@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <h1 className="h1 divider" id="custom-charts">
                Custom Charts
              </h1>
              <div className="page-content-item">
                <p className="font-sm">
                  Contain all styles, including chart, text, and color styles in a .json format.
                  This file has to be imported to the platform every single time a new project is
                  created.
                </p>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-5@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <h3 className="h3" id="importing-charts">
                  Importing custom charts
                </h3>
                <div className="pseudo-list">
                  <span className="pseudo-list-nr">1</span>
                  <p>
                    Click the ‘...’ (ellipsis) from the visualizations pane and select on “Import a
                    visual from a file” from the menu.
                  </p>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-6@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <div className="pseudo-list">
                  <span className="pseudo-list-nr">2</span>
                  <p>
                    Select Import if you trust the source of the file. Navigate to the folder that
                    has the custom visual file (.pbiviz) and open it.
                  </p>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-7@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <div className="pseudo-list">
                  <span className="pseudo-list-nr">3</span>
                  <p>When the visual has successfully imported, select OK.</p>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-8@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <div className="pseudo-list">
                  <span className="pseudo-list-nr">4</span>
                  <p>
                    <strong>A new icon appears</strong> in the visualizations pane of the current
                    report. Select the new visual icon to create the visual on the report canvas.
                  </p>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-9@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <div className="pseudo-list">
                  <span className="pseudo-list-nr">5</span>
                  <p>
                    <strong>If you want the visual to remain on the Visualizations</strong> pane so
                    you can use it in future reports, right-click the visual&apos;s icon and select
                    Pin to visualization pane.
                  </p>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-10@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <h3 className="h3" id="master-PBI-file">
                  Master PBI File
                </h3>
                <p className="font-sm">
                  If you have already imported PBI file with both “Styles” and “Custom Charts”, just
                  open the file to begin.
                </p>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-11@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <h1 id="custom-background-for-Power-BI" className="h1 divider">
                Custom background for PowerBI
              </h1>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-12@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <h3 className="visually-hidden" id="importing-styles">
                  Importing Styles
                </h3>
                <h3 className="h3">Importing Styles File</h3>
                <div className="pseudo-list">
                  <span className="pseudo-list-nr">1</span>
                  <p>
                    <strong>Ensure that the Figma</strong> frame is set to 1280px by 720px
                    dimensions to match the size for PowerBI dashboard.
                  </p>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-13@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <div className="pseudo-list">
                  <span className="pseudo-list-nr">2</span>
                  <p>
                    <strong>With background design finalized (hide the charts)</strong>, export it
                    from Figma in SVG and import it into Power BI. It will seamlessly integrate the
                    background into the Power BI canvas.
                  </p>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-14@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <div className="pseudo-list">
                  <span className="pseudo-list-nr">3</span>
                  <p>
                    <strong>Import the background design into Power BI:</strong> Navigate to the
                    canvas where the dashboard will be created. Insert the exported background
                    design file.
                  </p>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-15@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <h3 className="h3" id="designing-the-remaining-parts">
                  Designing the remaining parts
                </h3>
                <div className="pseudo-list">
                  <span className="pseudo-list-nr">3</span>
                  <p>
                    <strong>Add dynamic visualizations, interactive elements,</strong> and
                    personalized insights to create a truly immersive data experience:
                  </p>
                  <ol className="disc">
                    <li>Insert charts, graphs, and tables to visualize data.</li>
                    <li>
                      Incorporate slicers, filters, and drill-down features for interactivity.
                    </li>
                    <li>bInclude text boxes or annotations to provide insights or explanations.</li>
                  </ol>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-16@3x.jpg"
                alt="Power BI Kit"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <div className="pseudo-list">
                  <span className="pseudo-list-nr">5</span>
                  <p>
                    <strong>Continuously review and refine the dashboard</strong> design until
                    satisfied with the overall layout and functionality.
                  </p>
                </div>
              </div>
              <img
                src="/images/dev-resources-power-bi-kit/power-bi-kit-17@3x.jpg"
                alt="Power BI Kit"
                className="img-responsive"
              />
            </div>
            <div>
              <div className="page-navigation">
                <h4>Contents</h4>
                <Scrollspy
                  ids={[
                    'theme-file',
                    'importing-theme',
                    'custom-charts',
                    'importing-charts',
                    'master-PBI-file',
                    'custom-background-for-Power-BI',
                    'importing-styles',
                    'designing-the-remaining-parts',
                  ]}
                  offset={0}
                  activeItemClassName="active"
                  itemContainerClassName="multilevel-link-list"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaginationStyled
        previousLink="/dev-resources"
        previous="Dev Resources"
        nextLink="/dev-resources/excel-library"
        next="Excel Library"
      />
    </div>
  </div>
);

PowerBIKitStructure.defaultProps = {
  className: '',
};

const PowerBIKit = styled(PowerBIKitStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 4, 5, 7, 8])}

    overflow: unset;
  height: 100%;

  > .content {
    width: 100%;
    padding: 0;
  }
`;

export default PowerBIKit;
