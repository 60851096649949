import { ReactNode, useState } from 'react';
import styled from 'styled-components';

import ContentNavigationStyled from '../../components/ContentNavigation';
import { GetStartedTabs } from '../../types';

import About from './components/About';
import DesignPrinciples from './components/DesignPrinciples';
import GetStarted from './components/GetStarted';
import HowToUse from './components/HowToUse';

interface Props {
  className?: string;
}

const TABS: Record<GetStartedTabs, ReactNode> = {
  explore: <GetStarted />,
  'how-to-use': <HowToUse />,
  'design-principles': <DesignPrinciples />,
  about: <About />,
};

const MainPageStructure = ({ className }: Props) => {
  const [activeTab, setActiveTab] = useState<GetStartedTabs>('explore');
  return (
    <div className={className}>
      <ContentNavigationStyled activeTab={activeTab} onSelect={setActiveTab} />

      {TABS[activeTab]}
    </div>
  );
};

MainPageStructure.defaultProps = {
  className: '',
};

const MainPage = styled(MainPageStructure)`
    width: 100%;
    > .footer-pagination {
      display: none;
      margin-top: auto;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: block;
      }
    }
  }

`;

export default MainPage;
