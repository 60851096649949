import styled from 'styled-components';

import Grid from '../Grid';
import HoverableCard, { Tag } from '../HoverableCard';

interface RelatedPlotsProps {
  className?: string;
  title?: string;
  description?: string;
  data?: {
    className?: string;
    title?: string;
    image?: string;
    icon?: React.ReactNode;
    description?: string;
    url?: string;
    tags?: Tag[];
  }[];
}

const RelatedPlots = ({ className, title, description, data = [] }: RelatedPlotsProps) => (
  <div className={className}>
    <div className="related-plots mb-40">
      <h2 id="related-plots">{title}</h2>
      <p>{description}</p>
    </div>
    <Grid columns={1} gap="16px" columnsMd={2} columnsLg={3}>
      {data?.map((item) => (
        <HoverableCard
          description={item.description}
          className={item.className}
          key={item.title}
          title={item.title}
          icon={item.icon}
          image={item.image}
          tags={item.tags}
          url={item.url}
        />
      ))}
    </Grid>
  </div>
);

RelatedPlots.defaultProps = {
  className: '',
  title: '',
  description: '',
  data: [],
};

const RelatedPlotsStyled = styled(RelatedPlots)`
  .related-plots {
    h2 {
      font-weight: 600;
      line-height: 1.333333333333333;
      letter-spacing: -0.02em;
      color: var(--030404, #030404);
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    p {
      color: var(--5c636e, #5c636e);
      font-size: 0.875rem;
      line-height: 1.142857142857143;
      margin: 0;
    }
  }
`;

export default RelatedPlotsStyled;
