import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import PaginationStyled from '../../../components/Pagination';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government accessibility resources',
    cards: [
      {
        title: 'The Section 508 accessibility program',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility for Teams guide',
        subtitle: '[accessibility.digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Find your Section 508 Coordinator',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Section 508 ICT Testing Baseline',
        subtitle: '[section508coordinators.github.com ]',
        linkUrl: '#',
      },
      {
        title: 'Learn about writing in plain language ',
        subtitle: '[plainlanguage.gov]',
        linkUrl: '#',
      },
    ],
  },
];

const ContainmentStructure = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <ContentHeaderStyled
        title="Containment"
        breadcrumbs={[
          { label: 'General Components', url: '/general-components' },
          { label: 'Containment' },
        ]}
      />
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-with-navigation-wrapper">
            <div className="page-content-with-navigation">
              <div className="page-content-item">
                <h1 className="h1" id="overview">
                  Overview
                </h1>
                <h3 className="h3" id="modal-dialog">
                  Modal dialog
                </h3>
                <p>
                  Modals focus the user’s attention exclusively on one task or piece of information
                  via a window that sits on top of the page content.
                </p>
                <p className="mb-40">
                  Modal dialogs are commonly used for short and non-frequent tasks, such as editing
                  or management tasks. If a user needs to repeatably preform a task, consider making
                  the task do-able from the main page.
                </p>
                <h3 className="h3" id="column-grid-12">
                  12 Column Grid
                </h3>
                <p>
                  The 2x grid starts with 16 columns and encourages designers to divide by two as
                  their content becomes more dense. However, many legacy products used a twelve
                  column grid and in turn,display content in groups of three instead of groups of
                  four.
                </p>
                <p className="mb-40">
                  Although we highly recommend refactoring these UIs to take full advantage of the
                  the 2x grid, we realize that certain products will choose to keep their three
                  column scaffolding. So it&rsquo;s important to note that this configuration can
                  still be achieved with the 2x grid package.
                </p>
              </div>
              <img
                src="/images/general-components-containment/containment-1@3x.jpg"
                alt="12 Column Grid"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <h1 className="divider h1" id="specifications">
                  Specifications
                </h1>
                <h3 className="h3" id="modal-anatomy">
                  Modals Anatomy
                </h3>
                <p>
                  Thee modal is composed of three distinct zones: A header, the body, and a footer.
                  Components (eg. data table, form, progress indicator) can occupy the full width of
                  the modal.
                </p>
                <ol className="disc-list mb-40">
                  <li>
                    <strong>Header:</strong> Contains the modal title.
                  </li>
                  <li>
                    <strong>Body:</strong> Provides an overview of the modal&rsquo;s purpose and,
                    optionally, controls to complete a task.
                  </li>
                  <li>
                    <strong>Footer:</strong> Contains a primary action and the ability to cancel and
                    close the dialog.
                  </li>
                </ol>
              </div>
              <img
                src="/images/general-components-containment/containment-2@3x.jpg"
                alt="Modals Anatomy"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <h3 className="h3" id="grids-anatomy">
                  Grid’s Anatomy
                </h3>
                <p>
                  The 2x grid starts with 16 columns and encourages designers to divide by two as
                  their content becomes more dense. However, many legacy products used a twelve
                  column grid and in turn, display content in groups of three instead of groups of
                  four.
                </p>
                <p>
                  Although we highly recommend refactoring these UIs to take full advantage of the
                  the 2x grid, we realize that certain products will choose to keep their three
                  column scaffolding. So it’s important to note that this configuration can still be
                  achieved with the 2x grid package.
                </p>
                <p>
                  Columns and rows create key lines that are essential for visual rhythm, especially
                  for typography. Construct columns by either dividing a space into a fluid grid, or
                  by tiling fixed boxes in multiples.
                </p>
                <p className="mb-40">
                  For more detailed documentation about structural layout modules, check out IBM
                  Product Layouts
                </p>
              </div>
              <img
                src="/images/general-components-containment/containment-3@3x.jpg"
                alt="Grid’s Anatomy"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <h1 className="h1 divider" id="guidelines">
                  Guidelines
                </h1>
                <h3 className="h3" id="modals-usage">
                  Modals usage
                </h3>
                <p>
                  Use modal dialogs to present a short-term task the user needs to perform. This can
                  include critical or warning information where a response is required, however
                  they&rsquo;re also intended to support efficient task completion without losing
                  the context of the underlying page. Users won&rsquo;t be able to interact with the
                  page until the dialog is closed.
                </p>
                <ol className="disc-list mb-40">
                  <li>Modal dialog titles should use sentence case and prioritize keywords.</li>
                  <li>
                    Modal dialog body copy should contain only valuable and relevant information
                    that is both helpful and concise.
                  </li>
                  <li>
                    Label elements with action verbs that indicate what happens when the element is
                    selected. For example, label a select menu with Choose a user instead of Users.
                  </li>
                  <li>
                    The main action (a primary button) should reflect the modal title. For example,
                    a modal with the title &quot;Fork «repository name &gt; &quot; has a button
                    labeled &quot;Fork repository&quot;. The title &quot;Select a template&quot; has
                    a button labeled &quot;Select&quot;.
                  </li>
                </ol>
                <h3 className="h3" id="grids-usage">
                  Grid’s usage
                </h3>
                <p className="mb-40">
                  Hierarchy helps users (viewer, reader, audience) navigate complex concepts without
                  getting confused or lost, and ultimately find what they are looking for.
                  Thoughtful application of type styles, components, and patterns will allow content
                  to be communicated and prioritised in different ways. Pay attention also to the
                  size and proximity of content pieces within a component and between components.
                </p>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/general-components-containment/screen-do-use-grids-usage@3x.jpg"
                    alt="Do use grids usage"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>
                      Using an odd grid configuration in UI web design can create visually
                      interesting and dynamic layouts, offering flexibility and emphasizing
                      hierarchy.
                    </p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    src="/images/general-components-containment/screen-do-not-grids-usage@3x.jpg"
                    alt="Do not use grids usage"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>
                      Avoid going completely off-grid as it can lead to inconsistent layouts, making
                      it difficult for users to navigate and understand the content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="page-content-item">
                <h3 className="h3" id="grids-content-hierarchy">
                  Grid’s content hierarchy
                </h3>
                <p>
                  Hierarchy helps users (viewer, reader, audience) navigate complex concepts without
                  getting confused or lost, and ultimately find what they are looking for.
                  Thoughtful application of type styles, components, and patterns will allow content
                  to be communicated and prioritised in different ways. Pay attention also to the
                  size and proximity of content pieces within a component and between components.
                </p>
                <p className="mb-40">
                  Unlike editorial experiences, products focus more on task completion and
                  continuity across screens, rather than leading a user through stacked modules on a
                  single page. So common geometries and key alignments are especially important
                  across pages, not just down the scroll.
                </p>
              </div>
              <img
                src="/images/general-components-containment/containment-4@3x.jpg"
                alt="Grid’s content hierarchy"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <p className="font-sm">
                  Although the leadspace heights are changing, the typographic alignment from the
                  World overview page into the product keeps the user oriented.
                </p>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/general-components-containment/screen-do-use-grids-content-hierarchy@3x.jpg"
                    alt="Do use grids content hierarchy"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>
                      Do align type (outside of a container), components, and tiles to the columns.
                    </p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    src="/images/general-components-containment/screen-do-not-grids-content-hierarchy@3x.jpg"
                    alt="Do not use grids content hierarchy"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>Do not take type off the column structure to achieve alignment.</p>
                  </div>
                </div>
              </div>
              <h1 className="h1 divider" id="references">
                References
              </h1>
              {dataExternalLinkGroups.map((externalLinkGroup) => (
                <ExternalLinkGroupStyled
                  key={externalLinkGroup.id}
                  title={externalLinkGroup.title}
                  cards={externalLinkGroup.cards}
                />
              ))}
            </div>
            <div>
              <div className="page-navigation">
                <h4>Contents</h4>
                <Scrollspy
                  ids={[
                    'overview',
                    'modal-dialog',
                    'column-grid-12',
                    'specifications',
                    'modal-anatomy',
                    'grids-anatomy',
                    'guidelines',
                    'modals-usage',
                    'grids-usage',
                    'grids-content-hierarchy',
                    'references',
                  ]}
                  offset={0}
                  activeItemClassName="active"
                  itemContainerClassName="multilevel-link-list"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaginationStyled
        previousLink="/general-components/selection"
        previous="Selection"
        nextLink="/general-components/table"
        next="Table"
      />
    </div>
  </div>
);

ContainmentStructure.defaultProps = {
  className: '',
};

const Containment = styled(ContainmentStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 3, 5, 6, 8, 9, 10])}

    overflow: unset;
  height: 100%;

  > .content {
    width: 100%;
    padding: 0;
  }
`;

export default Containment;
