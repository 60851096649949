import styled from 'styled-components';

const Flex = styled.div.withConfig({
  shouldForwardProp: (prop) => !['direction', 'justify', 'align', 'gap'].includes(prop),
})<{
  direction?: 'row' | 'column';
  justify?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  align?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  gap?: string;
}>`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction}; // Default is horizontal (row)
  justify-content: ${({ justify = 'flex-start' }) => justify}; // Default alignment for main axis
  align-items: ${({ align = 'flex-start' }) => align}; // Default alignment for cross axis
  gap: ${({ gap = '0' }) => gap}; // Optional gap between flex items
`;

export default Flex;
