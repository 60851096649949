import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import Button from '../../../components/Button';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import PaginationStyled from '../../../components/Pagination';
import { ReactComponent as ArrowRight } from '../../../images/icons/arrow-right.svg';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'UI Interstate Connection Network (UI ICON)',
    cards: [
      {
        title: 'IBM Web Accessibility Checklist',
        subtitle: 'plainlanguage.gov',
        linkUrl: '#',
      },
      {
        title: 'Section 508 ICT Testing Baseline',
        subtitle: '[section58coordinators.github.com ]',
        linkUrl: '#',
      },
      {
        title: 'Government usability testing resources',
        subtitle: '[digital.gov]',
        linkUrl: '#',
      },
    ],
  },
];

const IconsStructure = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <ContentHeaderStyled
        title="Icons"
        breadcrumbs={[
          { label: 'Branding Styleguide', url: '/branding-styleguide' },
          { label: 'Icons' },
        ]}
      />
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-with-navigation-wrapper">
            <div className="page-content-with-navigation">
              <div className="page-content-item">
                <h1 className="h1" id="overview">
                  Overview
                </h1>
                <h3 className="h3" id="definition">
                  Definition
                </h3>
                <p className="mb-40">
                  While Bootstrap doesn’t include an icon set by default, we do have our own
                  comprehensive icon library called Bootstrap Icons. Feel free to use them or any
                  other icon set in your project. We’ve included details for Bootstrap Icons and
                  other preferred icon sets below.
                </p>
                <p className="mb-40">
                  While most icon sets include multiple file formats, we prefer SVG implementations
                  for their improved accessibility and vector support.
                </p>
                <Button iconAfter={<ArrowRight />} className="mb-40" size="M">
                  Boostrap icons
                </Button>
              </div>
              <img
                src="/images/branding-styleguide-icons/boostrap-icons@3x.jpg"
                alt=""
                className="mb-40 img-responsive"
              />
              <h1 className="divider h1" id="guidelines">
                Guidelines
              </h1>
              <div className="page-content-item">
                <h3 className="h3" id="sizing">
                  Sizing
                </h3>
                <p className="mb-40">
                  DHCS components typically use icons on 16px artboards. Icons on 16px, and 20px
                  artboards can also be used within the UI. Be sure icon size is consistent
                  throughout your product.
                </p>
                <p className="mb-40">
                  16px and 20px icons are optimized to feel balanced when paired with 14pt and 16pt
                  IBM Plex. Use 24px and 32px when larger icons are needed.
                </p>
              </div>
              <img
                src="/images/branding-styleguide-icons/example-sizing-icons@3x.jpg"
                alt=""
                className="mb-40 img-responsive"
              />
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/branding-styleguide-icons/screen-sizing-icons-01@3x.jpg"
                    alt=""
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>Do use the correct icon size with IBM Plex.</p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    src="/images/branding-styleguide-icons/screen-sizing-icons-02@3x.jpg"
                    alt=""
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>Don’t alter the icon-text size ratio.</p>
                  </div>
                </div>
              </div>
              <h3 className="h3" id="color">
                Color
              </h3>
              <div className="page-content-item">
                <p className="mb-40">
                  Icons are always a solid, monochromatic color and need to pass the same color
                  contrast ratio as typography (4.5:1).
                </p>
                <p className="mb-40">
                  The color of the icon should reflect the importance of the icon’s action which
                  should always be to help guide a user For more information on color, see the&nbsp;
                  <strong>
                    <a className="font-sm" href="/" title="" target="_blank">
                      Color guidelines
                    </a>
                  </strong>
                  .
                </p>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/branding-styleguide-icons/screen-color-guidelines-icons-01@3x.jpg"
                    alt=""
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>Do match your icon color with your text color when pairing them.</p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    src="/images/branding-styleguide-icons/screen-color-guidelines-icons-02@3x.jpg"
                    alt=""
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>Don’t use different colors for text and icons.</p>
                  </div>
                </div>
              </div>
              <h3 className="h3" id="alignment">
                Alignment
              </h3>
              <div className="page-content-item mb-40">
                <p>When used next to text, icons should be center-aligned.</p>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/branding-styleguide-icons/screen-align-icons-01@3x.jpg"
                    alt=""
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>Do center-align icons when they’re next to text.</p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    src="/images/branding-styleguide-icons/screen-align-icons-02@3x.jpg"
                    alt=""
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>Don’t baseline-align icons to the text.</p>
                  </div>
                </div>
              </div>
              <h1 className="h1 divider" id="references">
                References
              </h1>
              {dataExternalLinkGroups.map((externalLinkGroup) => (
                <ExternalLinkGroupStyled
                  key={externalLinkGroup.id}
                  title={externalLinkGroup.title}
                  cards={externalLinkGroup.cards}
                />
              ))}
            </div>
            <div>
              <div className="page-navigation">
                <h4>Contents</h4>
                <Scrollspy
                  ids={[
                    'overview',
                    'definition',
                    'guidelines',
                    'sizing',
                    'color',
                    'alignment',
                    'references',
                  ]}
                  offset={0}
                  activeItemClassName="active"
                  itemContainerClassName="multilevel-link-list"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaginationStyled
        previousLink="/branding-styleguide/logos"
        previous="Logos"
        nextLink="/branding-styleguide/imagery"
        next="Imagery"
      />
    </div>
  </div>
);

IconsStructure.defaultProps = {
  className: '',
};

const Icons = styled(IconsStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 4, 5, 6])}

    overflow: unset;
  height: 100%;

  > .content {
    width: 100%;
    padding: 0;
  }
`;

export default Icons;
