import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  className?: string;
  icon: ReactNode;
}

const Chip = ({ children, className, icon }: Props) => (
  <div className={className}>
    <div className="chip-icon">{icon}</div> {children}
  </div>
);

Chip.defaultProps = {
  className: '',
};

const StyledChip = styled(Chip)<{ size: 'S' | 'XS' }>`
    padding: ${({ size }) => (size === 'S' ? '4px 6px' : '3px 4px')};
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.neutral.background};
    border: 1px solid transparent;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    line-height: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.colors.neutral.text};
    font-weight: 400;
    box-sizing: border-box;

    .chip-icon {
        margin-right:  ${({ size }) => (size === 'S' ? '12px' : '8px')};
        position: relative;
        display: flex;

        > svg {
            width: ${({ size }) => (size === 'S' ? '14px' : '12px')};
            height: ${({ size }) => (size === 'S' ? '14px' : '12px')};
        }

        &::after {
            content: '';
            display: block;
            width: 1px;
            height: 100%;
            background-color: ${({ theme }) => theme.colors.neutral.border};
            position: absolute;
            right: ${({ size }) => (size === 'S' ? '-6px' : '-4px')};
            top: 0;
            transition: height 0.3s, top 0.3s;

        }

    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow: 0 2px 4px 1px #141C2C0A;
        border: 1px solid ${({ theme }) => theme.colors.neutral.border};

        .chip-icon {
            &::after {
                height: 190%;
                top: -45%;
            }
    }
`;

export default StyledChip;
