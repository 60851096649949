import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import PaginationStyled from '../../../components/Pagination';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government usability testing resources',
    cards: [
      {
        title: 'IBM Web Accessibility Checklist',
        subtitle: 'plainlanguage.gov',
        linkUrl: '#',
      },
      {
        title: 'IBM Web Accessibility Checklist',
        subtitle: 'plainlanguage.gov',
        linkUrl: '#',
      },
      {
        title: 'Government usability testing resources',
        subtitle: '[digital.gov]',
        linkUrl: '#',
      },
    ],
  },
];

const LinksStructure = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <ContentHeaderStyled
        title="Links"
        breadcrumbs={[
          { label: 'General Components', url: '/general-components' },
          { label: 'Links' },
        ]}
      />
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-with-navigation-wrapper">
            <div className="page-content-with-navigation">
              <div className="page-content-item">
                <h1 className="h1" id="overview">
                  Overview
                </h1>
                <h3 className="h3" id="definition">
                  Definition
                </h3>
                <p className="mb-40">
                  Links are used as navigational elements. They navigate users to another location,
                  such as a different site, resource, or section within the same page.
                </p>
              </div>
              <img
                src="/images/general-components-links/screen-definition@3x.jpg"
                alt=""
                className="mb-40 img-responsive"
              />
              <div className="page-content-item">
                <h3 className="h3" id="anatomy">
                  Anatomy
                </h3>
                <p>
                  There are three link sizes—large, medium, and small. However, the width of a link
                  is determined by the length of the link&apos;s text.
                </p>
                <p className="mb-40">
                  Links can be grouped vertically or horizontally. The following specs are not
                  developed for the link component but are recommended by design as the proper
                  distance between grouped links.
                </p>
              </div>
              <div className="number-cards">
                <div className="number-card">
                  <div className="number-card-img">
                    <span className="number-circle">1</span>
                    <img src="/images/general-components-links/screen-anatomy-01@3x.jpg" alt="" />
                  </div>
                  <div className="number-card-desc-row">
                    <p>
                      1. <strong>Link text:</strong> Communicates what is being linked to.
                    </p>
                  </div>
                </div>
                <div className="number-card">
                  <div className="number-card-img">
                    <span className="number-circle">2</span>
                    <img src="/images/general-components-links/screen-anatomy-02@3x.jpg" alt="" />
                  </div>
                  <div className="number-card-desc-row">
                    <p>
                      2. <strong>Icon (optional)</strong> Indicates the destination or type of
                      content being. referenced
                    </p>
                  </div>
                </div>
              </div>
              <figure>
                <img src="/images/general-components-links/screen-anatomy-03@3x.jpg" alt="" />
                <figcaption>
                  Structure and spacing measurements for Link with icon I px / rem
                </figcaption>
              </figure>
              <figure>
                <img src="/images/general-components-links/screen-anatomy-04@3x.jpg" alt="" />
                <figcaption>Structure and spacing measurements for Link grouping I px</figcaption>
              </figure>
              <div className="page-content-item">
                <h3 className="h3" id="variants">
                  Variants
                </h3>
                <p className="mb-40">
                  There are three size variants for the link component: small, medium, and large.
                  Inline link sizes should match the type size of the text it is inline with.
                  Standalone link sizes should match the default body copy size of the page.
                </p>
              </div>
              <table className="table table-variants mb-40">
                <thead>
                  <tr>
                    <th style={{ width: '15.625rem' }}>Element</th>
                    <th>Property</th>
                    <th>px I rem</th>
                    <th>Spacing token</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td valign="top">Icon: small link</td>
                    <td>padding-right</td>
                    <td>16 / 1</td>
                    <td>
                      <div className="role-color">$rithm-xs</div>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" aria-label="hh" />
                    <td>padding-bottom</td>
                    <td>4 / 0.25</td>
                    <td>
                      <div className="role-color">$rithm-xs</div>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Icon: medium link</td>
                    <td>svg</td>
                    <td>12 x 12</td>
                    <td>
                      <div className="role-color">$rithm-s</div>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Text</td>
                    <td>Text</td>
                    <td>Text</td>
                    <td>
                      <div className="role-color">$rithm-m</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h1 className="h1 divider" id="specifications">
                Specifications
              </h1>
              <div className="page-content-item">
                <h3 className="visually-hidden" id="standalone">
                  Standalone
                </h3>
                <h3 className="h3">Standalone link</h3>
                <p>
                  Standalone links are used on their own directly following content. They should not
                  be used within sentences or paragraphs. Standalone links are the default link
                  style for Carbon and only have an underline in the hover state, focus state, and
                  active state.
                </p>
                <p className="mb-40">
                  The standalone link component can be paired with an icon. Icons should always be
                  the same color as the link text. When necessary, utilize appropriate icons to
                  distinguish them from ghost buttons or inline links when they appear within the
                  same context or page.
                </p>
              </div>
              <figure>
                <img src="/images/general-components-links/screen-standalone-link@3x.jpg" alt="" />
                <figcaption>
                  Indicates the destination or type of content being. referenced
                </figcaption>
              </figure>
              <div className="page-content-item mb-40">
                <h3 className="h3" id="inline">
                  Inline link
                </h3>
                <p className="mb-40">
                  Inline links are used in sentences or paragraphs of text. The inline link behaves
                  the same as the standalone link but it is styled with an underline.
                </p>
                <p className="mb-40">
                  This helps differentiate them from the text they are placed next to and makes it
                  clear users can interact with them. Inline links should not be used on their own
                  and should not be paired with icons.
                </p>
              </div>
              <figure>
                <img src="/images/general-components-links/screen-inline-link@3x.jpg" alt="" />
                <figcaption>Example shows inline links within the body text</figcaption>
              </figure>
              <h1 className="h1 divider" id="guidelines">
                Guidelines
              </h1>
              <div className="page-content-item mb-40">
                <h3 className="h3" id="sizing">
                  Sizing
                </h3>
                <p className="mb-40">
                  There are three fixed heights of links-small (16px), medium (18px), and large
                  (22px).
                </p>
              </div>
              <table className="table table-variants mb-40">
                <thead>
                  <tr>
                    <th>Element</th>
                    <th>Height</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td valign="top">Small</td>
                    <td>16 / 1</td>
                  </tr>
                  <tr>
                    <td valign="top">Medium</td>
                    <td>18 / 1.125</td>
                  </tr>
                  <tr>
                    <td valign="top">Large</td>
                    <td>22 / 1.375</td>
                  </tr>
                </tbody>
              </table>
              <figure>
                <img src="/images/general-components-links/screen-sizing@3x.jpg" alt="" />
                <figcaption>Sizing applies to both standalone and inline links</figcaption>
              </figure>
              <div className="page-content-item mb-40">
                <h3 className="visually-hidden" id="areas">
                  Areas
                </h3>
                <h3 className="h3">Clickable areas</h3>
                <p>
                  Both standalone and inline links are clickable across the entire width of the
                  text. Standalone link with an icon has an expanded clickable area that includes
                  the icon.
                </p>
              </div>
              <div className="number-cards">
                <div className="number-card">
                  <div className="number-card-img">
                    <span className="number-circle">1</span>
                    <img
                      src="/images/general-components-links/screen-clickable-areas-01@3x.jpg"
                      alt=""
                    />
                  </div>
                  <div className="number-card-desc">
                    <p>Standalone and inline links with their clickable areas</p>
                  </div>
                </div>
                <div className="number-card">
                  <div className="number-card-img">
                    <span className="number-circle">2</span>
                    <img
                      src="/images/general-components-links/screen-clickable-areas-02@3x.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <h1 className="h1 divider" id="references">
                References
              </h1>
              {dataExternalLinkGroups.map((externalLinkGroup) => (
                <ExternalLinkGroupStyled
                  key={externalLinkGroup.id}
                  title={externalLinkGroup.title}
                  cards={externalLinkGroup.cards}
                />
              ))}
            </div>
            <div>
              <div className="page-navigation">
                <h4>Contents</h4>
                <Scrollspy
                  ids={[
                    'overview',
                    'definition',
                    'anatomy',
                    'variants',
                    'specifications',
                    'standalone',
                    'inline',
                    'guidelines',
                    'sizing',
                    'areas',
                    'references',
                  ]}
                  offset={0}
                  activeItemClassName="active"
                  itemContainerClassName="multilevel-link-list"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaginationStyled
        previousLink="/general-components/buttons"
        previous="Buttons"
        nextLink="/general-components/form-elements"
        next="Form Elements"
      />
    </div>
  </div>
);

LinksStructure.defaultProps = {
  className: '',
};

const Links = styled(LinksStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 3, 4, 6, 7, 9, 10])}

    overflow: unset;
  height: 100%;

  > .content {
    width: 100%;
    padding: 0;
  }
`;

export default Links;
