import styled from 'styled-components';

import Article from '../../../components/Article';
import Button from '../../../components/Button';
import exportshareBg from '../../../images/exportshare-bg.jpg';
import { ReactComponent as BoxArrowIcon } from '../../../images/icons/box-arrow.svg';
import { ReactComponent as FileIcon } from '../../../images/icons/file.svg';
import { ReactComponent as PlusIcon } from '../../../images/icons/plus.svg';
import { ReactComponent as ShareIcon } from '../../../images/icons/share.svg';
import { ReactComponent as SquareIcon } from '../../../images/icons/square.svg';

interface Props {
  className?: string;
}

const ExportShareTab = ({ className }: Props) => (
  <section className={className}>
    <div className="tabs-content-left">
      <div className="tab-content-header">
        <h3>Versatile Export Options with Secure Sharing Capabilities</h3>
        <div className="tabs-content-left__actions">
          <Button
            onClick={() => (window.location.href = 'https://dhcs.fuselabcreative.com/sign-in')}
            size="M"
            iconAfter={<PlusIcon />}
          >
            Get Started
          </Button>
        </div>
      </div>

      <div className="articles-container">
        <div>
          <Article
            boldWordsCount={2}
            icon={<FileIcon />}
            content="Multiple Formats export visualizations in various formats, including PDF, Excel, and image files."
          />
          <Article
            boldWordsCount={2}
            icon={<ShareIcon />}
            content="Sharing Options Direct sharing via email or integration with collaboration tools like Slack and Teams."
          />
        </div>
        <div>
          <Article
            boldWordsCount={2}
            icon={<SquareIcon />}
            content="Customizable Export options to include specific data points, annotations, and titles in exports"
          />
          <Article
            boldWordsCount={2}
            icon={<BoxArrowIcon />}
            content="Secure Access Control who can view or edit shared visualizations with permission settings."
          />
        </div>
      </div>
    </div>

    <div className="tabs-content-right" />
  </section>
);

ExportShareTab.defaultProps = {
  className: '',
};

const StyledExportShareTab = styled(ExportShareTab)`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: nowrap;
    flex-direction: row;
    min-height: 100%;
  }

  > .tabs-content-left {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-basis: 50%;
    }

    > .tab-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 16px;
      gap: 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        flex-wrap: nowrap;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints['2xl']}) {
        margin-bottom: 40px;
      }
      > h3 {
        font-size: ${({ theme }) => theme.fontSizes.base};
        font-weight: 600;
        line-height: ${({ theme }) => theme.lineHeights.base};
        color: ${({ theme }) => theme.colors.neutral.text};
      }

      > .tabs-content-left__actions {
        display: flex;
        gap: 24px;
      }
    }

    > .articles-container {
      display: flex;
      flex-direction: column;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-direction: row;
        > div {
          flex-basis: 50%;
        }
      }
    }
  }

  > .tabs-content-right {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
      flex-basis: 50%;
      background-image: url(${exportshareBg});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom right;
    }
  }
`;

export default StyledExportShareTab;
