import styled from 'styled-components';

import Article from '../../../components/Article';
import Button from '../../../components/Button';
import dataBg from '../../../images/data-bg.jpg';
import { ReactComponent as DatabaseIcon } from '../../../images/icons/general-components-outline.svg';
import { ReactComponent as PlusIcon } from '../../../images/icons/plus.svg';
import { ReactComponent as RefreshIcon } from '../../../images/icons/refresh.svg';
import { ReactComponent as ThreeLinesIcon } from '../../../images/icons/three-lines.svg';

interface Props {
  className?: string;
}

const DataTab = ({ className }: Props) => (
  <section className={className}>
    <div className="tabs-content-left">
      <div className="tab-content-header">
        <h3>Robust Data Access with Advanced Filtering and Integration</h3>
        <div className="tabs-content-left__actions">
          <Button
            onClick={() => (window.location.href = 'https://dhcs.fuselabcreative.com/sign-in')}
            size="M"
            iconAfter={<PlusIcon />}
          >
            Get Started
          </Button>
        </div>
      </div>

      <div className="articles-container">
        <div>
          <Article
            boldWordsCount={2}
            icon={<DatabaseIcon />}
            content="Custom Data Selection allows users to filter and choose specific datasets relevant to their analysis needs."
          />
          <Article
            boldWordsCount={2}
            icon={<ThreeLinesIcon />}
            content="Data Filtering advanced filters to refine and segment datasets for more targeted insights."
          />
        </div>
        <div>
          <Article
            boldWordsCount={2}
            icon={<RefreshIcon />}
            content="Real-time Updates automatically refreshes data to ensure the most current information is available."
          />
        </div>
      </div>
    </div>

    <div className="tabs-content-right" />
  </section>
);

DataTab.defaultProps = {
  className: '',
};

const StyledDataTab = styled(DataTab)`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: nowrap;
    flex-direction: row;
    min-height: 100%;
  }

  > .tabs-content-left {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-basis: 50%;
    }

    > .tab-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 16px;
      gap: 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        flex-wrap: nowrap;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints['2xl']}) {
        margin-bottom: 40px;
      }
      > h3 {
        font-size: ${({ theme }) => theme.fontSizes.base};
        font-weight: 600;
        line-height: ${({ theme }) => theme.lineHeights.base};
        color: ${({ theme }) => theme.colors.neutral.text};
      }

      > .tabs-content-left__actions {
        display: flex;
        gap: 24px;
      }
    }

    > .articles-container {
      display: flex;
      flex-direction: column;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-direction: row;
        > div {
          flex-basis: 50%;
        }
      }
    }
  }

  > .tabs-content-right {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
      flex-basis: 50%;
      background-image: url(${dataBg});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom right;
    }
  }
`;

export default StyledDataTab;
