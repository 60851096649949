import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  columns: { header: string; key: string }[];
  data: { [key: string]: ReactNode }[];
  className?: string;
}

const Table = ({ columns, data, className }: Props) => (
  <table className={className}>
    <thead>
      <tr>
        {columns.map((column) => (
          <th key={column.key}>{column.header}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {columns.map((column) => (
            <td key={column.key}>{row[column.key]}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

Table.defaultProps = {
  className: '',
};

const StyledTable = styled(Table)`
  border-collapse: collapse;
  width: 100%;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.neutral.border};

  thead {
    background-color: ${({ theme }) => theme.colors.neutral['background-medium']};
    border-top: 1px solid ${({ theme }) => theme.colors.neutral.border};
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.border};
    > tr {
      > th {
        font-size: ${({ theme }) => theme.fontSizes.xxs};
        line-height: ${({ theme }) => theme.lineHeights.xxs};
        font-weight: 400;
        border-right: 1px solid ${({ theme }) => theme.colors.neutral.border};
        text-align: left;
        padding: 4px 8px;

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  tbody {
    > tr {
      &:first-child {
        > td {
          padding-top: 8px;
        }
      }
      &:last-child {
        > td {
          padding-bottom: 10px;
        }
      }
      > td {
        font-size: ${({ theme }) => theme.fontSizes.xs};
        line-height: ${({ theme }) => theme.lineHeights.xs};
        padding: 6px 8px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.neutral.text};
      }
    }
  }
`;

export default StyledTable;
