import styled from 'styled-components';

interface GridProps {
  columns?: number;
  columnsMd?: number;
  columnsLg?: number;
  gap?: string;
  gapMd?: string;
  gapLg?: string;
}

const Grid = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['columns', 'columnsMd', 'columnsLg', 'gap', 'gapMd', 'gapLg'].includes(prop),
})<GridProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns || 3}, 1fr);
  gap: ${(props) => props.gap || '16px'};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: repeat(${({ columnsMd, columns }) => columnsMd || columns}, 1fr);
    gap: ${(props) => props.gapMd || props.gap};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(
      ${({ columnsMd, columns, columnsLg }) => columnsLg || columnsMd || columns},
      1fr
    );
    gap: ${(props) => props.gapLg || props.gapMd || props.gap};
  }
`;

export default Grid;
