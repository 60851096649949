import * as RadixSelect from '@radix-ui/react-select';
import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ChevronDown } from '../../images/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../images/icons/chevron-up.svg';

interface Props {
  className?: string;
  data: { label: ReactNode; icon: ReactNode; value: string }[];
  onSelect?: (value: string) => void;
  defaultValue?: string;
}

const StyledContent = styled(RadixSelect.Content)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px;
  width: 240px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.border};
  box-shadow: 0 4px 17px 0 #0c1d3126;
  border-radius: 6px;
  margin-top: 12px;

  > [role='option'] {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutral.text};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.sm};
    padding: 8px 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;

    &[data-state='checked'] {
      background-color: ${({ theme }) => theme.colors.neutral.background};
      font-weight: 500;
    }

    &[aria-selected='true'] {
      outline: none;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.neutral.background};
      outline: none;
    }
  }
`;

const Select = ({ className, data, onSelect, defaultValue }: Props) => {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const activeItem = data.find((item) => item.value === value);

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  return (
    <div className={className}>
      <RadixSelect.Root
        open={open}
        value={value}
        onOpenChange={setOpen}
        onValueChange={(val) => {
          setValue(val);
          onSelect?.(val);
        }}
      >
        <RadixSelect.Trigger className="select-triggers">
          <div className="select-selected-option">
            <div className="select-selected-option__icon">{activeItem?.icon}</div>{' '}
            {activeItem?.label || data[0].label}
          </div>
          <div className="select-icon">{open ? <ChevronUp /> : <ChevronDown />}</div>
        </RadixSelect.Trigger>
        <StyledContent
          style={{ zIndex: 1111, position: 'relative' }}
          className="select-options"
          position="popper"
        >
          {data.map((item) => (
            <RadixSelect.Item key={item.value} value={item.value}>
              <div className="select-options-item__icon">{item.icon}</div>{' '}
              <div className="select-options-item__label">{item.label}</div>
            </RadixSelect.Item>
          ))}
        </StyledContent>
      </RadixSelect.Root>
    </div>
  );
};

Select.defaultProps = {
  className: '',
  onSelect: undefined,
  defaultValue: '',
};

const SelectStyled = styled(Select)`
  .select-triggers {
    width: 274px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.neutral.background};
    border: 1px solid ${({ theme }) => theme.colors.neutral.border};
    padding: 12px 10px;
    border-radius: 6px;
    outline: none;
    height: 40px;

    > .select-selected-option {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: ${({ theme }) => theme.fontSizes.sm};
      line-height: ${({ theme }) => theme.lineHeights.sm};
      color: ${({ theme }) => theme.colors.neutral['text-weak']};
      font-weight: 400;
    }

    .select-icon {
      display: flex;
      align-items: center;
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 16px;
        background-color: ${({ theme }) => theme.colors.neutral.border};
        position: absolute;
        left: -8px;
      }
    }
  }
`;

export default SelectStyled;
