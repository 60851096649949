import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LayoutWithHeader from './components/LayoutWithHeader';
import LayoutWithSidebar from './components/LayoutWithSidebar';
import { PageActionsProvider } from './components/PageActions/context';
import {
  Accessibility,
  BrandingStyleguide,
  Buttons,
  ChartMatch,
  Colors,
  Communication,
  Containment,
  Content,
  DashboardTemplates,
  DataVizualization,
  DevResources,
  ESRIKit,
  Error404,
  ExcelLibrary,
  FigmaLibrary,
  FormElements,
  GeneralComponents,
  Icons,
  Imagery,
  InteractionsAndAnimations,
  LineChart,
  Links,
  Logos,
  GetStarted,
  Navigation,
  PowerBIKit,
  Selection,
  Table,
  Typography,
  VoiceAndTone,
  OverviewPage,
  BuilderPage,
  BarsPage,
  LinesPage,
  MapsPage,
  TablesPage,
  CircularsPage,
  DiagramsPage,
  OthersPage,
  AreaChart,
  StackedAreaChart,
  BarChartV,
  BarChartH,
  GroupedBarChartV,
  GroupedBarChartH,
  StackedBarChartV,
  StackedBarChartH,
  LollipopV,
  LollipopH,
  GroupedLollipopV,
  GroupedLollipopH,
  StackedLollipopV,
  StackedLollipopH,
  FunnelChart,
  HistogramChart,
  DonutChart,
  BubbleChart,
  ScatterChart,
  ChrolopethChart,
  PikesChart,
  HeatmapChart,
  PunchcardChart,
  MatrixChart,
  PolarChart,
  RadarChart,
  SankeyChart,
  TreempaChart,
  CircleChart,
  OtherBubbleChart,
  BoxPlotHChart,
  BoxPlotVChart,
  ViolinPlotHChart,
  ViolinPlotVChart,
  LineStackedBarChart,
  RibbonChart,
  ScatterPlotChart,
  News,
  DesignEvolved,
  DashboardExamples,
} from './screens';

const App = () => (
  <Routes>
    <Route element={<LayoutWithHeader />}>
      <Route path="/overview" element={<OverviewPage />} />
      <Route path="/builder" element={<BuilderPage />} />
    </Route>
    <Route
      element={
        <PageActionsProvider>
          <LayoutWithSidebar />
        </PageActionsProvider>
      }
    >
      <Route path="/" element={<GetStarted />} />
      <Route path="/get-started/accessibility" element={<Accessibility />} />
      <Route path="/get-started/content" element={<Content />} />
      <Route path="/get-started/voice-and-tone" element={<VoiceAndTone />} />
      <Route path="/get-started/news" element={<News />} />
      <Route path="/get-started/news/design-evolved" element={<DesignEvolved />} />

      <Route path="/branding-styleguide" element={<BrandingStyleguide />} />
      <Route path="/branding-styleguide/colors" element={<Colors />} />
      <Route path="/branding-styleguide/typography" element={<Typography />} />
      <Route path="/branding-styleguide/logos" element={<Logos />} />
      <Route path="/branding-styleguide/icons" element={<Icons />} />
      <Route path="/branding-styleguide/imagery" element={<Imagery />} />
      <Route
        path="/branding-styleguide/interactions-and-animations"
        element={<InteractionsAndAnimations />}
      />

      <Route path="/general-components" element={<GeneralComponents />} />
      <Route path="/general-components/buttons" element={<Buttons />} />
      <Route path="/general-components/links" element={<Links />} />
      <Route path="/general-components/form-elements" element={<FormElements />} />
      <Route path="/general-components/communication" element={<Communication />} />
      <Route path="/general-components/navigation" element={<Navigation />} />
      <Route path="/general-components/selection" element={<Selection />} />
      <Route path="/general-components/table" element={<Table />} />
      <Route path="/general-components/containment" element={<Containment />} />

      <Route path="/design-system/chart-match" element={<ChartMatch />} />

      <Route path="/dev-resources" element={<DevResources />} />
      <Route path="/dev-resources/esrikit" element={<ESRIKit />} />
      <Route path="/dev-resources/powerbikit" element={<PowerBIKit />} />
      <Route path="/dev-resources/excel-library" element={<ExcelLibrary />} />
      <Route path="/dev-resources/figma-library" element={<FigmaLibrary />} />

      <Route path="/data-visualization" element={<DataVizualization />} />
      <Route path="/data-visualization/lines" element={<LinesPage />} />
      <Route path="/data-visualization/bars" element={<BarsPage />} />
      <Route path="/data-visualization/maps" element={<MapsPage />} />
      <Route path="/data-visualization/tables" element={<TablesPage />} />
      <Route path="/data-visualization/circulars" element={<CircularsPage />} />
      <Route path="/data-visualization/diagrams" element={<DiagramsPage />} />
      <Route path="/data-visualization/others" element={<OthersPage />} />
      <Route path="/data-visualization/lines/line-chart" element={<LineChart />} />
      <Route path="/data-visualization/lines/area-chart" element={<AreaChart />} />
      <Route path="/data-visualization/lines/stacked-area-chart" element={<StackedAreaChart />} />
      <Route path="/data-visualization/bars/chart-v" element={<BarChartV />} />
      <Route path="/data-visualization/bars/chart-h" element={<BarChartH />} />
      <Route path="/data-visualization/bars/grouped-bar-chart-v" element={<GroupedBarChartV />} />
      <Route path="/data-visualization/bars/grouped-bar-chart-h" element={<GroupedBarChartH />} />
      <Route path="/data-visualization/bars/stacked-bar-chart-v" element={<StackedBarChartV />} />
      <Route path="/data-visualization/bars/stacked-bar-chart-h" element={<StackedBarChartH />} />
      <Route path="/data-visualization/bars/lollipop-v" element={<LollipopV />} />
      <Route path="/data-visualization/bars/lollipop-h" element={<LollipopH />} />
      <Route path="/data-visualization/bars/grouped-lollipop-v" element={<GroupedLollipopV />} />
      <Route path="/data-visualization/bars/grouped-lollipop-h" element={<GroupedLollipopH />} />
      <Route path="/data-visualization/bars/stacked-lollipop-v" element={<StackedLollipopV />} />
      <Route path="/data-visualization/bars/stacked-lollipop-h" element={<StackedLollipopH />} />
      <Route path="/data-visualization/bars/funnel-chart" element={<FunnelChart />} />
      <Route path="/data-visualization/bars/histogram" element={<HistogramChart />} />

      <Route path="/data-visualization/maps/choropleth" element={<ChrolopethChart />} />
      <Route path="/data-visualization/maps/bubble" element={<BubbleChart />} />
      <Route path="/data-visualization/maps/pikes" element={<PikesChart />} />
      <Route path="/data-visualization/maps/scatter" element={<ScatterChart />} />

      <Route path="/data-visualization/tables/heatmap" element={<HeatmapChart />} />
      <Route path="/data-visualization/tables/matrix" element={<MatrixChart />} />
      <Route path="/data-visualization/tables/punchcard" element={<PunchcardChart />} />

      <Route path="/data-visualization/circulars/donut" element={<DonutChart />} />
      <Route path="/data-visualization/circulars/polar" element={<PolarChart />} />
      <Route path="/data-visualization/circulars/radar" element={<RadarChart />} />

      <Route path="/data-visualization/diagrams/sankey" element={<SankeyChart />} />
      <Route path="/data-visualization/diagrams/treemap" element={<TreempaChart />} />
      <Route path="/data-visualization/diagrams/circle" element={<CircleChart />} />

      <Route path="/data-visualization/others/bubble" element={<OtherBubbleChart />} />
      <Route path="/data-visualization/others/box-plot-v" element={<BoxPlotVChart />} />
      <Route path="/data-visualization/others/box-plot-h" element={<BoxPlotHChart />} />
      <Route path="/data-visualization/others/violin-plot-v" element={<ViolinPlotVChart />} />
      <Route path="/data-visualization/others/violin-plot-h" element={<ViolinPlotHChart />} />
      <Route
        path="/data-visualization/others/line-and-stacked-bar"
        element={<LineStackedBarChart />}
      />
      <Route path="/data-visualization/others/scatter-plot" element={<ScatterPlotChart />} />
      <Route path="/data-visualization/others/ribbon-chart" element={<RibbonChart />} />

      <Route path="/design-system/dashboard-examples" element={<DashboardExamples />} />
    </Route>

    <Route path="/dashboard-templates" element={<DashboardTemplates />} />
    <Route path="*" element={<Error404 />} />
  </Routes>
);

const Router: FC = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default Router;
