import {
  createContext,
  useContext,
  ReactNode,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';

interface PageActionsContextProps {
  zoomIn: () => void;
  zoomOut: () => void;
  resetSettings: (...args: unknown[]) => void;
  fullScreen: () => void;
  resetScreen: () => void;

  onZoomIn: (callback: () => void) => void;
  onZoomOut: (callback: () => void) => void;
  onResetSettings: (callback: (...args: unknown[]) => void) => void;
  isFullScreen: boolean;
}

const PageActionsContext = createContext<PageActionsContextProps | undefined>(undefined);

const PageActionsProvider = ({ children }: { children: ReactNode }) => {
  const zoomInCallbackRef = useRef<(() => void) | null>(null);
  const zoomOutCallbackRef = useRef<(() => void) | null>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const listenersRef = useRef<(() => void)[]>([]); // Ensure correct typing

  const zoomIn = () => {
    zoomInCallbackRef.current?.();
  };

  const zoomOut = () => {
    zoomOutCallbackRef.current?.();
  };

  const resetSettings = useCallback(() => {
    listenersRef.current.forEach((callback) => callback());
  }, []);

  const onResetSettings = useCallback((callback: () => void) => {
    listenersRef.current.push(callback);

    return () => {
      listenersRef.current = listenersRef.current.filter((cb) => cb !== callback);
    };
  }, []);

  const fullScreen = () => {
    setIsFullScreen(true);
  };

  const resetScreen = () => {
    setIsFullScreen(false);
  };

  const value = useMemo(
    () => ({
      zoomIn,
      zoomOut,
      onResetSettings,
      fullScreen,
      resetScreen,
      onZoomIn: (callback: () => void) => {
        zoomInCallbackRef.current = callback;
      },
      onZoomOut: (callback: () => void) => {
        zoomOutCallbackRef.current = callback;
      },
      resetSettings,
      isFullScreen,
    }),
    [isFullScreen, onResetSettings, resetSettings],
  );

  return <PageActionsContext.Provider value={value as any}>{children}</PageActionsContext.Provider>;
};

// Custom hook to use the PageActions context
const usePageActions = () => {
  const context = useContext(PageActionsContext);
  if (!context) {
    throw new Error('usePageActions must be used within a PageActionsProvider');
  }
  return context;
};

export { PageActionsProvider, usePageActions };
