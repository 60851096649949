import { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import Box from '../../../../components/Box';
import ChangelogStyled from '../../../../components/Changelog';
import ContentHeaderStyled from '../../../../components/ContentHeader';
import Flex from '../../../../components/Flex';
import Legend from '../../../../components/Legend';
import { usePageActions } from '../../../../components/PageActions/context';
import PaginationStyled from '../../../../components/Pagination';
import RelatedPlotsStyled from '../../../../components/RelatedPlots';
import Tabs from '../../../../components/Tabs';
import Text from '../../../../components/Text';
import ZoomImage from '../../../../components/ZoomImage';
import { ReactComponent as ChartIcon } from '../../../../images/icons/chart-bar.svg';
import ChartSettingsSelect from '../../components/ChartSettingsSelect';
import LibrariesSelect from '../../components/LibrariesSelect';
import { RelatedPlotsData } from '../../types';

import UsabilityTab from './components/UsabilityTab';
import UseCaseTab from './components/UseCaseTab';
import defaultChart from './images/default.png';

const dataChart: RelatedPlotsData[] = [
  {
    description:
      'Grouped bar chat is useful for visually comparing data across multiple subcategories within each main category. It displays multiple bars for each category with each group containing bars representing different subcategories.',
    title: 'Box Plot - V',
    url: '/data-visualization/others/box-plot-v',
    image: '/images/data-viz-others/box-plot-v.png',
    icon: <ChartIcon />,
    tags: ['distribution', 'comparison'],
  },
  {
    description:
      'Grouped bar chat is useful for visually comparing data across multiple subcategories within each main category. It displays multiple bars for each category with each group containing bars representing different subcategories.',
    title: 'Box Plot - H',
    url: '/data-visualization/others/box-plot-h',
    image: '/images/data-viz-others/box-plot-h.png',
    icon: <ChartIcon />,
    tags: ['distribution', 'comparison'],
  },
  {
    description:
      'Grouped bar chat is useful for visually comparing data across multiple subcategories within each main category. It displays multiple bars for each category with each group containing bars representing different subcategories.',
    title: 'Violin Plot - V',
    url: '/data-visualization/others/violin-plot-v',
    image: '/images/data-viz-others/violin-plot-v.png',
    icon: <ChartIcon />,
    tags: ['distribution', 'comparison'],
  },
];

const dataBadge = [
  {
    id: 1,
    title: '1.03.0',
    date: '09.13.2023',
    color: 'info',
    badgeTitle: 'Changed',
    description:
      'Hover state now has a stronger border, thus resolving readability problem encountered by some users.',
  },
  {
    id: 2,
    title: '1.02.0',
    date: '08.07.2023',
    color: 'warning',
    badgeTitle: 'Fixed',
    description: `Fixed animation on hover when moving mouse quickly on Map's borders.`,
  },
  {
    id: 3,
    title: '1.01.0',
    date: '06.10.2023',
    color: 'success',
    badgeTitle: 'Added',
    description: 'Added tooltips on hover with customisable data.',
  },
  {
    id: 4,
    title: '1.03.0',
    date: '09.13.2023',
    color: 'info',
    badgeTitle: 'Changed',
    description:
      'Hover state now has a stronger border, thus resolving readability problem encountered by some users.',
  },
];

const dataTabs = [
  {
    id: 'tab-use-case',
    title: 'Use Case',
    content: <UseCaseTab />,
  },
  {
    id: 'tab-usability',
    title: 'Usability',
    content: <UsabilityTab />,
  },
  {
    id: 'tab-changelog',
    title: 'Changelog',
    content: <ChangelogStyled data={dataBadge} />,
  },
];

interface Props {
  className?: string;
}

type SelectData = {
  label: string;
  value: string;
  imagePath: string;
}[];

const selectData: SelectData = [
  {
    label: '',
    value: 'defaultChart',
    imagePath: defaultChart,
  },
];

const legendData: Record<string, { label: string; color: string }[]> = {
  defaultChart: [
    { label: 'East', color: '#17315A' },
    { label: 'North', color: '#2D6E8D' },
    { label: 'South', color: '#4B9B8E' },
    { label: 'West', color: '#E47225' },
  ],
};

type LibraryType = 'builder' | 'powerBI' | 'excel' | 'esri' | 'figma';

const activeChartSettings: Record<LibraryType, SelectData> = {
  powerBI: selectData,
  builder: selectData,
  excel: selectData,
  esri: selectData,
  figma: selectData,
};

const DEFAULT_ACTIVE_LIBRARY = 'builder';

const BubbleChartStructure = ({ className }: Props) => {
  const [selectedTab, setSelectedTab] = useState('tab-use-case');
  const { isFullScreen, onResetSettings } = usePageActions();
  const [activeLibrary, setActiveLibrary] = useState<LibraryType>(DEFAULT_ACTIVE_LIBRARY);
  const [activeChart, setActiveChart] = useState(activeChartSettings[activeLibrary][0].value);
  const theme = useTheme();

  const resetSettingsCallback = () => {
    setActiveLibrary(DEFAULT_ACTIVE_LIBRARY);
    setActiveChart(selectData[0].value);
  };

  const onSetActiveLibrary = (value: LibraryType) => {
    setActiveLibrary(value as LibraryType);
    setActiveChart(activeChartSettings[value as LibraryType][0].value);
  };

  onResetSettings(() => {
    resetSettingsCallback();
  });

  const activeChartData = activeChartSettings[
    activeLibrary as keyof typeof activeChartSettings
  ].find((chart) => chart.value === activeChart);

  return (
    <div className={className}>
      <div className="content">
        <ContentHeaderStyled
          style={{ marginBottom: isFullScreen ? '16px' : undefined }}
          controlBar
          title="Bubble Chart"
          breadcrumbs={[
            { label: 'Data Vizualization', url: '/data-visualization' },
            { label: 'Others', url: '/data-visualization/others' },
            { label: 'Bubble Chart' },
          ]}
        />
        <Box
          style={{
            marginBottom: `${isFullScreen ? '0' : '40px'}`,
            border: `1px solid ${theme.colors.neutral.border}`,
            borderRadius: '6px',
          }}
          as="section"
        >
          {!isFullScreen && (
            <Box
              style={{
                borderBottom: `1px solid ${theme.colors.neutral.border}`,
                backgroundColor: theme.colors.neutral.background,
                padding: '12px 16px',
              }}
            >
              <Flex align="center" gap="40px" justify="space-between">
                <Text
                  color={theme.colors.neutral['text-weak']}
                  fontSize={theme.fontSizes.xs}
                  lineHeight={theme.lineHeights.xs}
                >
                  Please select a kit from the dropdown menu to change the chart’s view and access
                  the appropriate kit page. <br />
                  Follow the provided instructions to download the kit, locate your plot, and
                  complete the installation process.
                </Text>
                <LibrariesSelect
                  defaultValue={activeLibrary}
                  onSelect={(value) => onSetActiveLibrary(value as LibraryType)}
                  optionsToExclude={['esri']}
                />
              </Flex>
            </Box>
          )}
          <Box>
            <Box style={{ padding: '46px 24px 34px 24px' }}>
              <Flex justify="space-between">
                {activeChartSettings[activeLibrary][0].label ? (
                  <ChartSettingsSelect
                    defaultValue={activeChart}
                    onSelect={(val) => {
                      setActiveChart(val);
                    }}
                    placeholder="Active Chart Settings"
                    data={activeChartSettings[activeLibrary]}
                  />
                ) : (
                  <div />
                )}
                <Legend type="categorical" data={legendData[activeChart]} />
              </Flex>
            </Box>
            <ZoomImage imgSrc={activeChartData?.imagePath} />
          </Box>
        </Box>

        {!isFullScreen && (
          <>
            <Tabs
              onSelectedTab={setSelectedTab}
              className="tabs-component"
              tabs={dataTabs.map((item) => ({ ...item, content: item.content, title: item.title }))}
            />
            {selectedTab === 'tab-use-case' && (
              <RelatedPlotsStyled
                title="Related Plots"
                description="These plots may serve as a substitution to this Line Chart."
                data={dataChart}
                className="related-plots-section"
              />
            )}
            <PaginationStyled
              next="Box Plot V"
              nextLink="/data-visualization/others/box-plot-v"
              className="footer-pagination"
            />
          </>
        )}
      </div>
    </div>
  );
};

BubbleChartStructure.defaultProps = {
  className: '',
};

const OtherBubbleChart = styled(BubbleChartStructure)`
  width: 100%;
  .content {
    margin: 0 auto;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    > .tabs-component {
      margin-bottom: 40px;
    }

    > .footer-pagination {
      display: none;
      margin-top: auto;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: block;
      }
    }

    > .related-plots-section {
      margin-bottom: 40px;
    }
  }
`;

export default OtherBubbleChart;
