import styled from 'styled-components';

import ColorPaletteStyled from '../../../../../components/ColorPalette';
import NotificationStyled from '../../../../../components/Notification';
import StickyNav from '../../../../../components/StickyNav';
import { ReactComponent as InfoCircle } from '../../../../../images/icons/info-circle.svg';

interface Props {
  className?: string;
}

const dataColorPalette1 = [
  { id: 1, color: '#084594' },
  { id: 2, color: '#2171b5' },
  { id: 3, color: '#4292c6' },
  { id: 4, color: '#6baed6' },
  { id: 5, color: '#9ecae1' },
  { id: 6, color: '#c6dbef' },
  { id: 7, color: '#eff3ff' },
];

const dataColorPalette2 = [
  { id: 1, color: '#8c510a' },
  { id: 2, color: '#d8b365' },
  { id: 3, color: '#f6e8c3' },
  { id: 4, color: '#f5f5f5' },
  { id: 5, color: '#c7eae5' },
  { id: 6, color: '#5ab4ac' },
  { id: 7, color: '#01665e' },
];

const UseCaseTab = ({ className }: Props) => (
  <div className={className}>
    <div className="tab-content">
      <div className="definition">
        <h2 className="title" id="definition">
          Definition
        </h2>
        <p>
          A choropleth map is a data visualization tool that represents geographic areas with colors
          or shading based on a numerical variable, enabling the study of variable trends across a
          region. While powerful, it has a bias favoring larger regions in interpretation.
        </p>
      </div>
      <div className="usage">
        <h2 className="title" id="usage">
          Usage
        </h2>
        <p className="subtitle">Choropleth maps are suitable for data that:</p>
        <ul className="ordered-list-badge-num">
          <li>
            <strong>Relate to Enumeration Units:</strong> Your data is associated with specific
            geographic areas like counties, provinces, or countries.
          </li>
          <li>
            <strong>Standardized for Rates or Ratios:</strong> The data is standardized to depict
            rates or ratios rather than raw counts. Raw data counts are not suitable for choropleth
            mapping.
          </li>
          <li>
            <strong>Represent a Continuous Statistical Surface:</strong> The data represents a
            continuous statistical surface, meaning it can be conceptually measured anywhere in
            space, even if the measurement is zero.
          </li>
        </ul>
      </div>
      <div className="limitations">
        <h2 className="title" id="limitations">
          Limitations
        </h2>
        <p className="subtitle">
          Choropleth are a valuable data visualization tool, but they do have limitations that
          should be considered when deciding whether to use them for a particular data set or
          analysis:
        </p>
        <ul className="ordered-list-badge-num">
          <li>
            <strong>Normalize variable:</strong> Direct comparisons between regions with varying
            sizes or populations using raw numbers can be misleading.
          </li>
          <li>
            <strong>Standardized for Rates or Ratios:</strong> The data is standardized to depict
            rates or ratios rather than raw counts. Raw data counts are not suitable for choropleth
            mapping.
          </li>
          <li>
            <strong>Represent a Continuous Statistical Surface:</strong> The data represents a
            continuous statistical surface, meaning it can be conceptually measured anywhere in
            space, even if the measurement is zero.
          </li>
        </ul>
      </div>
      <div className="colors">
        <h2 className="title" id="colors">
          Colors
        </h2>
        <p>
          Color selection is crucial in choropleth maps. Typically, a sequential color ramp is used,
          with lighter colors indicating smaller values and darker colors for larger values.
          Alternatively, a diverging color palette can be employed when there&apos;s a meaningful
          zero point in the data.
        </p>
        <NotificationStyled
          icon={<InfoCircle />}
          title="For in-depth information on this topic, please refer to the Colors page."
          color="blue"
          className="info-notification"
        />
        <div className="palette">
          <h4 className="palette-title">Sequential Palette - Continuous Values</h4>
          <p>To showcase categorical values.</p>
          <ColorPaletteStyled className="color-palette" data={dataColorPalette1} />
        </div>
        <div className="div-palette">
          <h4>Diverging Palette - Meaningful Central Value</h4>
          <ColorPaletteStyled className="color-palette" data={dataColorPalette2} />
        </div>
      </div>

      <div className="challenges">
        <h2 className="title">Challenges</h2>
        <p>Unclassed choropleth maps present some challenges</p>
        <ul className="ordered-list-badge-num">
          <li>
            <strong>Information Overload:</strong> Unclassed maps can overwhelm with excessive
            detail, making it challenging to distinguish significant patterns amidst the noise.
            Classification can help filter out irrelevant variations and emphasize major
            differences.
          </li>
          <li>
            <strong>Legibility and Interpretation:</strong> Users often struggle to match colors on
            unclassed maps with those in the legend due to the multitude of subtly different shades.
            This hinders the ability to estimate values or extract specific data from the map.
          </li>
          <li>
            <strong>Printing Issues:</strong> Users often struggle to match colors on unclassed maps
            with those in the legend due to the multitude of subtly different shades. This hinders
            the ability to estimate values or extract specific data from the map.
          </li>
        </ul>
      </div>
    </div>
    <div>
      <StickyNav title="Contents" />
    </div>
  </div>
);

UseCaseTab.defaultProps = {
  className: '',
};

const StyledUseCaseTab = styled(UseCaseTab)`
  display: flex;
  gap: 2.5rem;
  justify-content: space-between;

  > .tab-content {
    width: 100%;
    .usage,
    .definition,
    .limitations,
    .challenges {
      max-width: 700px;
    }
    > .colors {
      > .info-notification {
        margin-top: 24px;
        margin-bottom: 40px;
      }

      > .palette {
        > .palette-title {
          color: ${({ theme }) => theme.colors.neutral.text};
          font-size: ${({ theme }) => theme.fontSizes.base};
          line-height: ${({ theme }) => theme.lineHeights.base};
          margin-bottom: 4px;
        }
      }

      .color-palette {
        margin-top: 12px;
      }
    }

    > .challenges {
      > .ordered-list-badge-num {
        margin-top: 16px;
      }
    }

    .title {
      color: ${({ theme }) => theme.colors.neutral.text};
      font-weight: 600;
      font-size: ${({ theme }) => theme.fontSizes.lg};
      line-height: ${({ theme }) => theme.lineHeights.lg};
      margin-bottom: 8px;
      margin-top: 40px;
    }

    p {
      color: ${({ theme }) => theme.colors.neutral['text-weak']};
      font-size: ${({ theme }) => theme.fontSizes.sm};
      line-height: ${({ theme }) => theme.lineHeights.sm};
      font-weight: 400;
      max-width: 700px;
    }

    .subtitle {
      margin-bottom: 16px;
    }

    // styles for list with badge
    .ordered-list-badge-num {
      font-size: 0.875rem;
      line-height: 1.4285714286;
      margin-left: 29px;
      counter-reset: num;
      list-style: none;
      color: ${({ theme }) => theme.colors.neutral['text-weak']};

      li {
        position: relative;
        margin-bottom: 16px;

        &::before {
          content: counter(num);
          counter-increment: num;
          position: absolute;
          left: -1.75rem;
          top: 0;
          width: 1.25rem;
          height: 1.25rem;
          border-radius: 0.1875rem;
          text-align: center;
          font-family: 'Sora', sans-serif;
          font-size: 0.6875rem;
          line-height: 1.8;
          color: ${({ theme }) => theme.colors.primary.text};
          background-color: ${({ theme }) => theme.colors.primary['background-active']};
        }
      }
    }
  }
`;

export default StyledUseCaseTab;
