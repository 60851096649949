import * as RadixTabs from '@radix-ui/react-tabs';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  tabs: {
    title: string;
    content: ReactNode;
    id: string;
  }[];
  onSelectedTab?: (tab: string) => void;
}

const Tabs = ({ className, tabs, onSelectedTab }: Props) => (
  <RadixTabs.Root onValueChange={onSelectedTab} className={className} defaultValue={tabs[0].id}>
    <RadixTabs.List className="tabsList" aria-label="Manage your account">
      {tabs.map((tab) => (
        <RadixTabs.Trigger key={tab.title} className="TabsTrigger" value={tab.id}>
          {tab.title}
        </RadixTabs.Trigger>
      ))}
    </RadixTabs.List>
    {tabs.map((tab, index) => (
      <RadixTabs.Content className="TabsContent" value={tab.id} key={index}>
        {tab.content}
      </RadixTabs.Content>
    ))}
  </RadixTabs.Root>
);

Tabs.defaultProps = {
  className: '',
  onSelectedTab: () => {},
};

const StyledTabs = styled(Tabs)`
  .tabsList {
    background-color: ${({ theme }) => theme.colors.neutral.background};
    width: fit-content;
    padding: 2px;
    border-radius: 6px;

    button {
      outline: none;
      border: none;
      font-size: ${({ theme }) => theme.fontSizes.sm};
      line-height: ${({ theme }) => theme.lineHeights.sm};
      color: ${({ theme }) => theme.colors.neutral['text-weak']};
      padding: 8px 16px;
      border-radius: 4px;
      font-weight: 500;
      cursor: pointer;

      &[data-state='active'] {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.neutral.text};
      }
    }
  }

  .TabsContent {
    background-color: transparent;
  }
`;

export default StyledTabs;
