import * as RadixSelect from '@radix-ui/react-select';
import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ChevronDown } from '../../../images/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../../images/icons/chevron-up.svg';
import { ReactComponent as RadioChecked } from '../../../images/icons/radio-selected.svg';
import { ReactComponent as RadioUnChecked } from '../../../images/icons/radio.svg';

interface Props {
  className?: string;
  placeholder: string;
  data: { label: ReactNode; icon?: ReactNode; value: string }[];
  onSelect?: (value: string) => void;
  defaultValue: string;
}

const StyledContent = styled(RadixSelect.Content)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px;
  width: 200px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.border};
  box-shadow: 0 4px 17px 0 #0c1d3126;
  border-radius: 6px;
  margin-top: 12px;
  left: 73%;

  > [role='option'] {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutral['text-weak']};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    line-height: ${({ theme }) => theme.lineHeights.sm};
    padding: 8px 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;

    &[data-state='checked'] {
      background-color: ${({ theme }) => theme.colors.neutral.background};
      font-weight: 500;
      color: ${({ theme }) => theme.colors.neutral.text};
    }

    &[aria-selected='true'] {
      outline: none;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.neutral.background};
      outline: none;
    }
  }
`;

const ChartSettingsSelect = ({ className, data, placeholder, onSelect, defaultValue }: Props) => {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const activeItem = data.find((item) => item.value === value);

  useEffect(() => {
    setValue(defaultValue || data[0].value);
  }, [data, defaultValue]);

  return (
    <div className={className}>
      <RadixSelect.Root
        open={open}
        value={value}
        onOpenChange={setOpen}
        onValueChange={(val) => {
          setValue(val);
          onSelect?.(val);
        }}
      >
        <RadixSelect.Trigger className="select-triggers">
          <div className="select-selected-option">
            <span className="select-selected-option__placeholder">{placeholder}</span>{' '}
            <span className="select-selected-option__label">
              {activeItem?.label || data[0].label}
            </span>
          </div>
          <div className="select-icon">{open ? <ChevronUp /> : <ChevronDown />}</div>
        </RadixSelect.Trigger>
        <StyledContent
          style={{ zIndex: 1111, position: 'relative' }}
          className="select-options"
          position="popper"
        >
          {data.map((item) => (
            <RadixSelect.Item key={item.value} value={item.value}>
              <div className="select-options-item__icon">{item.icon}</div>{' '}
              {item.value === value ? (
                <RadioChecked style={{ marginRight: '8px' }} />
              ) : (
                <RadioUnChecked style={{ marginRight: '8px' }} />
              )}
              <div className="select-options-item__label">{item.label}</div>
            </RadixSelect.Item>
          ))}
        </StyledContent>
      </RadixSelect.Root>
    </div>
  );
};

ChartSettingsSelect.defaultProps = {
  className: '',
  onSelect: undefined,
};

const ChartSettingsSelectStyled = styled(ChartSettingsSelect)`
  .select-triggers {
    gap: 4px;
    display: flex;
    border: none;
    background-color: ${({ theme }) => theme.colors.white};
    align-items: center;
    cursor: pointer;
    outline: none;

    > .select-selected-option {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: ${({ theme }) => theme.fontSizes.sm};
      line-height: ${({ theme }) => theme.lineHeights.sm};

      > .select-selected-option__placeholder {
        color: ${({ theme }) => theme.colors.neutral['text-weak']};
      }

      > .select-selected-option__label {
        color: ${({ theme }) => theme.colors.primary.text};
        font-weight: 500;
      }
    }

    > .select-icon {
      display: flex;
      align-items: center;

      > svg {
        color: ${({ theme }) => theme.colors.primary.text};
      }
    }
  }
`;

export default ChartSettingsSelectStyled;
