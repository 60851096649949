import styled from 'styled-components';

import ContentHeaderStyled from '../../../components/ContentHeader';
import HoverableCard from '../../../components/HoverableCard';
import PaginationStyled from '../../../components/Pagination';
import { ReactComponent as ChartIcon } from '../../../images/icons/chart-bar.svg';

interface Props {
  className?: string;
}

const LinesPage = ({ className }: Props) => (
  <div className={className}>
    <div className="page-content">
      <ContentHeaderStyled
        className="breadcrumbs"
        title="Lines"
        breadcrumbs={[
          { label: 'Data Vizualization', url: '/data-visualization' },
          { label: 'Lines Chart' },
        ]}
      />

      <div className="cards">
        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Lines Chart"
          url="line-chart"
          image="/images/data-viz-lines/line-chart.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />
        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Area Chart"
          url="area-chart"
          image="/images/data-viz-lines/area-chart.png"
          icon={<ChartIcon />}
          tags={['trend']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Stacked Area Chart"
          url="stacked-area-chart"
          image="/images/data-viz-lines/stacked-area-chart.png"
          icon={<ChartIcon />}
          tags={['trend', 'proportion']}
        />
      </div>

      <PaginationStyled
        previousLink="/lines"
        previous="Lines"
        previousDisabled="disabled"
        nextLink="/data-visualization/bars"
        next="Bars"
        className="footer-pagination"
      />
    </div>
  </div>
);

LinesPage.defaultProps = {
  className: '',
};

const StyledLinesPage = styled(LinesPage)`
  > .sidebar {
    overflow-x: hidden;
    height: calc(100vh - 4rem);
  }

  > .page-content {
    overflow-x: hidden;
    margin: 0 auto;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    > .breadcrumbs {
      margin-bottom: 24px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-bottom: 40px;
      }
    }

    > .cards {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 80px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    > .footer-pagination {
      display: none;
      margin-top: auto;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: block;
      }
    }
  }
`;

export default StyledLinesPage;
