import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import PaginationStyled from '../../../components/Pagination';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government accessibility resources',
    cards: [
      {
        title: 'The Section 508 accessibility program',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility for Teams guide',
        subtitle: '[accessibility.digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Find your Section 508 Coordinator',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Section 508 ICT Testing Baseline',
        subtitle: '[section508coordinators.github.com]',
        linkUrl: '#',
      },
      {
        title: 'Learn about writing in plain language',
        subtitle: '[plainlanguage.gov]',
        linkUrl: '#',
      },
      {
        title: 'Plain language resources',
        subtitle: '[plainlanguage.gov]',
        linkUrl: '#',
      },
      {
        title: '18F’s accessibility guide',
        subtitle: '[accessibility.18f.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility Requirements Tool (ART) for contracting',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Understanding universal design',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
    ],
  },
];

const ButtonsStructure = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <ContentHeaderStyled
        title="Buttons"
        breadcrumbs={[
          { label: 'General Components', url: '/general-components' },
          { label: 'Buttons' },
        ]}
      />
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-with-navigation-wrapper">
            <div className="page-content-with-navigation">
              <div className="page-content-item">
                <h1 className="h1" id="overview">
                  Overview
                </h1>
                <h3 className="h3" id="definition">
                  Definition
                </h3>
                <p className="mb-40">
                  Buttons are used primarily for actions, such as “Add”, “Close”, “Cancel”, or
                  “Save”. Plain buttons, which look similar to links, are used for less important or
                  less commonly used actions, such as “view shipping settings”.
                </p>
                <h3 className="h3" id="playground">
                  Playground
                </h3>
                <p className="mb-40">
                  Buttons are triggers for events or actions. They&apos;re a common part of larger
                  experiences such as forms or modal dialogs.
                </p>
              </div>
              <img
                src="/images/general-components-buttons/playgrounds-frame@3x.jpg"
                alt="Playground frame"
                className="mb-40 img-responsive"
              />
              <h1 className="divider h1" id="specifications">
                Specifications
              </h1>
              <div className="page-content-item">
                <h3 className="h3" id="variants">
                  Variants
                </h3>
                <p className="mb-40">
                  Buttons are triggers for events or actions. They&apos;re a common part of larger
                  experiences such as forms or modal dialogs.
                </p>
              </div>
              <table className="table table-variants mb-40">
                <thead>
                  <tr>
                    <th style={{ width: '15.625rem' }}>Variant</th>
                    <th>Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td valign="top">Primary</td>
                    <td>
                      For the principal call to action on the page. Primary buttons should only
                      appear once per screen (not including the application header, modal dialog, or
                      side panel).
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Secondary</td>
                    <td>
                      For secondary actions on each page. Secondary buttons can only be used in
                      conjunction with a primary button. As part of a pair, the secondary
                      button&apos;s function is to perform the negative action of the set, such as
                      &quot;Cancel&quot; or &quot;Back&quot;. Do not use a secondary button in
                      isolation and do not use a secondary button for a positive action.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Tertiary</td>
                    <td>
                      For less prominent, and sometimes independent, actions. Tertiary buttons can
                      be used in isolation or paired with a primary button when there are multiple
                      calls to action. Tertiary buttons can also be used for sub-tasks on a page
                      where a primary button for the main and final action is present.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Danger</td>
                    <td>
                      For actions that could have destructive effects on the user&apos;s data (for
                      example, delete or remove). Danger button has three styles: primary, tertiary,
                      and ghost.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Ghost</td>
                    <td>
                      For the least pronounced actions; often used in conjunction with a primary
                      button. In a situation such as a progress flow, a ghost button may be paired
                      with a primary and secondary button set, where the primary button is for
                      forward action, the secondary button is for &quot;Back&quot;, and the ghost
                      button is for &quot;Cancel&quot;.
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="page-content-item">
                <h3 className="h3" id="anatomy">
                  Anatomy
                </h3>
                <p className="mb-40">
                  A button’s text label is the most important element on a button, as it
                  communicates the action that will be performed when the user interacts with it. In
                  a contained button the text is always left-aligned, not centered. By default
                  Carbon uses sentence case for all button labels.
                </p>
                <ul className="ordered-list-badge-latin">
                  <li>
                    <strong>Label:</strong> Text describing the button action. Use action verbs or
                    phrases to tell the user what will happen next, and follow the button label
                    content guidelines.
                  </li>
                  <li>
                    <strong>Button:</strong> The selectable area of the button.
                  </li>
                  <li>
                    <strong>Icon (optional):</strong> Most buttons don&apos;t need an icon. Use an
                    icon to add additional affordance where the icon has a clear and
                    well-established meaning.
                  </li>
                </ul>
              </div>
              <div className="number-cards mb-40">
                <div className="number-card">
                  <div className="number-card-img">
                    <span className="number-circle">1</span>
                    <img
                      src="/images/general-components-buttons/example-contained-button@3x.jpg"
                      alt=""
                    />
                  </div>
                  <div className="number-card-desc">
                    <strong>Contained button</strong>
                    <ul className="inline-latin">
                      <li>A. Text label</li>
                      <li>B. Button container</li>
                      <li>C. Icon (optional)</li>
                    </ul>
                  </div>
                </div>
                <div className="number-card">
                  <div className="number-card-img">
                    <span className="number-circle">2</span>
                    <img
                      src="/images/general-components-buttons/example-icon-button@3x.jpg"
                      alt=""
                    />
                  </div>
                  <div className="number-card-desc">
                    <strong>Icon button</strong>
                    <ul className="inline-latin">
                      <li>A. Text label</li>
                      <li>B. Button container</li>
                      <li>C. Icon (optional)</li>
                    </ul>
                  </div>
                </div>
              </div>
              <img
                src="/images/general-components-buttons/example-button-size@3x.jpg"
                alt="Example button size"
                className="mb-40 img-responsive"
              />
              <div className="page-content-item mb-40">
                <h3 className="h3" id="sizes-purpose">
                  Sizes - purpose
                </h3>
                <p>
                  Buttons are named according to their size and usage in the Carbon Sketch kit and
                  in the code. The table below adds more context around the use case for each size.
                  For guidance on button heights as well as type sizes and tokens, see Sizes on the
                  Style tab.
                </p>
              </div>
              <table className="table table-variants mb-40">
                <thead>
                  <tr>
                    <th style={{ width: '15.625rem' }}>Button size</th>
                    <th>Use case</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td valign="top">Extra small</td>
                    <td>
                      Use when buttons bleed to the edge of a larger component, like side panels or
                      modals. Note: This variant is not offered in the kit because it does not have
                      a coded counterpart. Carbon only offers this button in the context of the
                      modal component.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Small</td>
                    <td>
                      Use when there is not enough vertical space for the default or field-sized
                      button.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Medium</td>
                    <td>
                      The larger expressive type size within this button provides balance when used
                      with 16px body copy. Used by the IBM.com team in website banners.
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="page-content-item mb-40">
                <h3 className="h3" id="states">
                  States
                </h3>
                <p>
                  Only include one primary button or call to action (CTA) in a page or area of UI.
                </p>
                <p>
                  Primary buttons indicate the most important action in a group or area. Having
                  multiple primary CAs in one area can be confusing or visually overwhelming because
                  they compete for attention.
                </p>
              </div>
              <img
                src="/images/general-components-buttons/example-types-button@3x.jpg"
                alt=""
                className="mb-40 img-responsive"
              />
              <h1 className="divider h1" id="guidelines">
                Guidelines
              </h1>
              <div className="page-content-item">
                <h3 className="h3" id="usage">
                  Usage
                </h3>
                <p className="mb-40">
                  Only include one primary button or call to action (CTA) in a page or area of UI.
                </p>
                <p>
                  Primary buttons indicate the most important action in a group or area. Having
                  multiple primary CAs in one area can be confusing or visually overwhelming because
                  they compete for attention.
                </p>
              </div>
              <img
                src="/images/general-components-buttons/example-usage-button@3x.jpg"
                alt=""
                className="mb-40 img-responsive"
              />
              <div className="page-content-item mb-40">
                <h3 className="h3" id="placement">
                  Placement
                </h3>
                <p>
                  Only include one primary button or call to action (CTA) in a page or area of UI.
                </p>
                <p className="mb-40">
                  Primary buttons indicate the most important action in a group or area. Having
                  multiple primary CAs in one area can be confusing or visually overwhelming because
                  they compete for attention.
                </p>
                <h3 className="h3" id="do-and-donts">
                  Do and dont’s
                </h3>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/general-components-buttons/screen-do-not-use-two-high-emphasis@3x.jpg"
                    alt="Do use high-emphasis and medium-emphasis buttons in a button group"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>Do use high-emphasis and medium-emphasis buttons in a button group.</p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    src="/images/general-components-buttons/screen-do-use-high-emphasis@3x.jpg"
                    alt="Do not use two high-emphasis buttons in a button group"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>Do not use two high-emphasis buttons in a button group.</p>
                  </div>
                </div>
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/general-components-buttons/screen-use-validation@3x.jpg"
                    alt="Use validation or other clear on-screen directions to help people proceed"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>
                      Use validation or other clear on-screen directions to help people proceed.
                    </p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    src="/images/general-components-buttons/screen-dont-disable-buttons@3x.jpg"
                    alt="Don't disable form submission buttons"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>
                      Don&apos;t disable form submission buttons, as this doesn&apos;t give people
                      clear a direction for how to proceed.
                    </p>
                  </div>
                </div>
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/general-components-buttons/screen-use-consistent-language@3x.jpg"
                    alt="Use consistent language for the button and other text descibing the same action"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>
                      Use consistent language for the button and other text descibing the same
                      action.
                    </p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    src="/images/general-components-buttons/screen-different-words-refer-same-action@3x.jpg"
                    alt="Use different words to refer to the same action"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>Use different words to refer to the same action.</p>
                  </div>
                </div>
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/general-components-buttons/screen-use-sentence-case-capitalization@3x.jpg"
                    alt="Use sentence-case capitalization"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>Use sentence-case capitalization.</p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    src="/images/general-components-buttons/screen-use-title-case-capitalization@3x.jpg"
                    alt="Use title case capitalization or all caps"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>Use title case capitalization or all caps.</p>
                  </div>
                </div>
              </div>
              <div className="page-content-item">
                <h3 className="h3" id="grouping">
                  Grouping
                </h3>
                <p className="mb-40">
                  A high-emphasis button can be accompanied by medium- and low-emphasis buttons that
                  perform less important actions. Keep in mind that you should only group together
                  calls to action that have a relationship to one another.
                </p>
              </div>
              <img
                src="/images/general-components-buttons/button-group@3x.jpg"
                alt="Grouping"
                className="mb-40 img-responsive"
              />
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/general-components-buttons/screen-lower-emphasis-buttons-in-button-group@3x.jpg"
                    alt="Do use a primary and two of the same lower emphasis buttons in a button group"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>
                      Do use a primary and two of the same lower emphasis buttons in a button group.
                    </p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    src="/images/general-components-buttons/screen-do-not-mix-buttons-in-group@3x.jpg"
                    alt="Do not mix primary, secondary, and tertiary buttons in
                    the same button group"
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>
                      Do not mix primary, secondary, and tertiary buttons in the same button group.
                    </p>
                  </div>
                </div>
              </div>
              <div className="page-content-item mb-40">
                <h3 className="h3" id="content">
                  Content
                </h3>
                <p>
                  Only include one primary button or call to action (CTA) in a page or area of UI.
                </p>
                <p>
                  Primary buttons indicate the most important action in a group or area. Having
                  multiple primary CAs in one area can be confusing or visually overwhelming because
                  they compete for attention.
                </p>
              </div>
              <h1 className="divider h1" id="builder-specifications">
                Builder specifications
              </h1>
              <div className="page-content-item mb-40">
                <h3 className="h3">Variants</h3>
                <p>
                  Buttons are triggers for events or actions. They&apos;re a common part of larger
                  experiences such as forms or modal dialogs.
                </p>
              </div>
              <table className="table table-variants mb-40">
                <thead>
                  <tr>
                    <th style={{ width: '15.625rem' }}>Variant</th>
                    <th>Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td valign="top">Primary</td>
                    <td>
                      For the principal call to action on the page. Primary buttons should only
                      appear once per screen (not including the application header, modal dialog, or
                      side panel).
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Secondary</td>
                    <td>
                      For default actions on each page. Default buttons can only be used in
                      conjunction with a primary button. As part of a pair, the default
                      button&apos;s function is to perform the negative action of the set, such as
                      &ldquo;Cancel&rdquo; or &ldquo;Back&rdquo;. Do not use a default button in
                      isolation and do not use a default button for a positive action.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Subtle</td>
                    <td>
                      For less prominent, and sometimes independent, actions. Subtle buttons can be
                      used in isolation or paired with a primary button when there are multiple
                      calls to action. Subtle buttons can also be used for sub-tasks on a page where
                      a primary button for the main and final action is present.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Plain</td>
                    <td>
                      Plain buttons can be utilized either on their own or alongside a primary
                      button when multiple calls to action are necessary. Plain buttons are also
                      suitable for sub-tasks on a page where a primary button is designated for the
                      main and final action.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Destructive</td>
                    <td>
                      Destructive buttons are used for actions that have serious consequences, such
                      as deleting data or performing irreversible changes. These buttons are
                      designed to stand out and alert the user to the potential impact of the
                      action.
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="page-content-item">
                <h3 className="h3" id="sizes-purpose">
                  Sizes - purpose
                </h3>
                <p className="mb-40">
                  Buttons are named according to their size and usage in the Carbon Sketch kit and
                  in the code. The table below adds more context around the use case for each size.
                  For guidance on button heights as well as type sizes and tokens, see Sizes on the
                  Style tab.
                </p>
              </div>
              <table className="table table-variants mb-40">
                <thead>
                  <tr>
                    <th style={{ width: '15.625rem' }}>Button size</th>
                    <th>Use case</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td valign="top">Extra small</td>
                    <td>
                      Use when buttons bleed to the edge of a larger component, like side panels or
                      modals. Note: This variant is not offered in the kit because it does not have
                      a coded counterpart. Carbon only offers this button in the context of the
                      modal component.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Small</td>
                    <td>
                      Use when there is not enough vertical space for the default or field-sized
                      button.
                    </td>
                  </tr>
                  <tr>
                    <td valign="top">Medium</td>
                    <td>
                      The larger expressive type size within this button provides balance when used
                      with 16px body copy. Used by the IBM.com team in website banners.
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="page-content-item">
                <h3 className="h3" id="states">
                  States
                </h3>
                <p className="mb-40">
                  Only include one primary button or call to action (CTA) in a page or area of UI.
                  Primary buttons indicate the most important action in a group or area. Having
                  multiple primary CAs in one area can be confusing or visually overwhelming because
                  they compete for attention.
                </p>
              </div>
              <img
                src="/images/general-components-buttons/example-button-states@3x.jpg"
                alt=""
                className="mb-40 img-responsive"
              />
              <h1 className="h1 divider" id="references">
                References
              </h1>
              {dataExternalLinkGroups.map((externalLinkGroup) => (
                <ExternalLinkGroupStyled
                  key={externalLinkGroup.id}
                  title={externalLinkGroup.title}
                  cards={externalLinkGroup.cards}
                />
              ))}
            </div>
            <div>
              <div className="page-navigation">
                <h4>Contents</h4>
                <Scrollspy
                  ids={[
                    'overview',
                    'definition',
                    'playground',
                    'specifications',
                    'variants',
                    'anatomy',
                    'sizes-purpose',
                    'states',
                    'guidelines',
                    'usage',
                    'placement',
                    'do-and-donts',
                    'grouping',
                    'content',
                    'builder-specifications',
                    'references',
                  ]}
                  offset={0}
                  activeItemClassName="active"
                  itemContainerClassName="multilevel-link-list"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaginationStyled
        previousLink="/general-components"
        previous="General Components"
        nextLink="/general-components/links"
        next="Links"
      />
    </div>
  </div>
);

ButtonsStructure.defaultProps = {
  className: '',
};

const Buttons = styled(ButtonsStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 3, 5, 6, 7, 8, 10, 11, 12, 13, 14])}

    overflow: unset;
  height: 100%;

  > .content {
    width: 100%;
    padding: 0;
  }
`;

export default Buttons;
