import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import PaginationStyled from '../../../components/Pagination';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government usability testing resources',
    cards: [
      {
        title: 'IBM Web Accessibility Checklist',
        subtitle: 'plainlanguage.gov',
        linkUrl: '#',
      },
      {
        title: 'IBM Web Accessibility Checklist',
        subtitle: 'plainlanguage.gov',
        linkUrl: '#',
      },
      {
        title: 'Government usability testing resources',
        subtitle: '[digital.gov]',
        linkUrl: '#',
      },
    ],
  },
  {
    id: 2,
    title: 'Government accessibility resources',
    cards: [
      {
        title: 'Learn about writing in plain language ',
        subtitle: '[plainlanguage.gov]',
        linkUrl: '#',
      },
      {
        title: 'The Section 508 accessibility program',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility for Teams guide',
        subtitle: '[accessibility.digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Find your Section 508 Coordinator',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Section 508 ICT Testing Baseline',
        subtitle: '[section58coordinators.github.com]',
        linkUrl: '#',
      },
    ],
  },
];

const TypographyStructure = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <ContentHeaderStyled
        title="Typography"
        breadcrumbs={[
          { label: 'Branding Styleguide', url: '/branding-styleguide' },
          { label: 'Typography' },
        ]}
      />
      <div className="page-container">
        <div className="page-content">
          <div className="page-content-with-navigation-wrapper">
            <div className="page-content-with-navigation">
              <div className="page-content-item">
                <h1 className="h1" id="overview">
                  Overview
                </h1>
                <h3 className="h3" id="definition">
                  Definition
                </h3>
                <p className="mb-40">
                  Maintaining consistent and engaging digital interfaces throughout DHCS, whether
                  applications or experiences, demands extended guidance around color usage. The
                  following concepts provide the foundation as we strive to achieve balance and
                  harmony through our User Interface design.
                </p>
              </div>
              <img
                src="/images/branding-styleguide-typography/typography-definition@3x.jpg"
                alt=""
                className="mb-40 img-responsive"
              />
              <div className="number-cards mb-40">
                <div className="number-card">
                  <div className="number-card-img">
                    <span className="number-circle">1</span>
                    <img
                      src="/images/branding-styleguide-typography/screen-heading@3x.jpg"
                      alt="Heading"
                    />
                  </div>
                  <div className="number-card-desc">
                    <strong>Heading</strong>
                    <p>Heading is used to create various levels of typographic hierarchies.</p>
                  </div>
                </div>
                <div className="number-card">
                  <div className="number-card-img">
                    <span className="number-circle">2</span>
                    <img
                      src="/images/branding-styleguide-typography/screen-body@3x.jpg"
                      alt="Body"
                    />
                  </div>
                  <div className="number-card-desc">
                    <strong>Body</strong>
                    <p>Body is primarily used for Spectrum components and for blocks of text.</p>
                  </div>
                </div>
                <div className="number-card">
                  <div className="number-card-img">
                    <span className="number-circle">3</span>
                    <img
                      src="/images/branding-styleguide-typography/screen-actions@3x.jpg"
                      alt="Actions"
                    />
                  </div>
                  <div className="number-card-desc">
                    <strong>Actions</strong>
                    <p>
                      Detail is used for disclosing extra information or smaller items in
                      hierarchical relationships of text.
                    </p>
                  </div>
                </div>
              </div>
              <h1 className="divider h1" id="specifications">
                Specifications
              </h1>
              <div className="page-content-item">
                <h3 className="h3" id="anatomy">
                  Anatomy
                </h3>
                <p className="mb-40">
                  Carbon uses the open-source typeface IBM Plex. It has been carefully designed to
                  meet IBM’s needs as a global technology company and reflect IBM’s spirit, beliefs,
                  and design principles. Arial can be accessed and downloaded from the&nbsp;
                  <strong>
                    <a href="/" title="">
                      Plex GitHub Repo.
                    </a>
                  </strong>
                </p>
                <h3 className="h3" id="font-sizes">
                  Font sizes
                </h3>
                <p className="mb-40">
                  The IBM type scale is built on a single equation. The formula for our scale was
                  created to provide hierarchy for all types of experiences. The formula assumes
                  that y₀=12 px.
                </p>
              </div>
              <table className="table table-colors table-font mb-40">
                <thead>
                  <th>Titles</th>
                  <th>Font Family</th>
                  <th>Weight</th>
                  <th>Size</th>
                  <th>Line height</th>
                  <th>Line height</th>
                </thead>
                <tbody>
                  <tr>
                    <td className="table-h1-b">H1 B</td>
                    <td>Arial</td>
                    <td>Bold</td>
                    <td>36 px</td>
                    <td>44 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-h2-b">H2 B</td>
                    <td>Arial</td>
                    <td>Bold</td>
                    <td>24 px</td>
                    <td>32 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-h2">H2</td>
                    <td>Arial</td>
                    <td>Regular</td>
                    <td>24 px</td>
                    <td>32 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-h3-b">H3 B</td>
                    <td>Arial</td>
                    <td>Bold</td>
                    <td>20 px</td>
                    <td>28 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-h3">H3</td>
                    <td>Arial</td>
                    <td>Regular</td>
                    <td>20 px</td>
                    <td>28 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-h4-b">H4 B</td>
                    <td>Arial</td>
                    <td>Bold</td>
                    <td>16 px</td>
                    <td>24 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-h4">H4</td>
                    <td>Arial</td>
                    <td>Regular</td>
                    <td>16 px</td>
                    <td>24 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-h5-b">H5 B</td>
                    <td>Arial</td>
                    <td>Bold</td>
                    <td>13 px</td>
                    <td>16 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-h5">H5</td>
                    <td>Arial</td>
                    <td>Regular</td>
                    <td>13 px</td>
                    <td>16 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-body-1-b">Body 1 B</td>
                    <td>Arial</td>
                    <td>Bold</td>
                    <td>14 px</td>
                    <td>20 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-body-1">Body 1</td>
                    <td>Arial</td>
                    <td>Regular</td>
                    <td>14 px</td>
                    <td>20 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-body-2-b">Body 2 B</td>
                    <td>Arial</td>
                    <td>Bold</td>
                    <td>12 px</td>
                    <td>16 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-body-2">Body 2</td>
                    <td>Arial</td>
                    <td>Regular</td>
                    <td>12 px</td>
                    <td>16 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-small">Small</td>
                    <td>Arial</td>
                    <td>Regular</td>
                    <td>11 px</td>
                    <td>14 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-overline">Overline</td>
                    <td>Arial</td>
                    <td>Regular</td>
                    <td>10 px</td>
                    <td>12 px</td>
                    <td>8%</td>
                  </tr>
                  <tr>
                    <td className="table-footnote">Footnote</td>
                    <td>Arial</td>
                    <td>regular</td>
                    <td>14 px</td>
                    <td>16 px</td>
                    <td>0%</td>
                  </tr>
                </tbody>
              </table>
              <h1 className="divider h1" id="guidelines">
                Guidelines
              </h1>
              <div className="page-content-item">
                <h3 className="h3" id="best-practices">
                  Best practices
                </h3>
                <p className="mb-40">
                  Typography creates purposeful texture, guiding users to read and understand the
                  hierarchy of information. The right typographic treatment and the controlled usage
                  of type styles, including proper capitalization rules, help manage the display of
                  content, keeping it useful, simple, and effective. Using capitalization
                  consistently and appropriately enhances readability and ensures that important
                  information stands out clearly.
                </p>
              </div>
              <div className="ex-cards">
                <div className="ex-card true">
                  <img
                    className="ex-card-img"
                    src="/images/branding-styleguide-typography/screen-best-practices-01@3x.jpg"
                    alt=""
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <p>
                      Respect capitalization rules. Use sentence case for all UX content. For more
                      on capitalization guidelines, go to Grammar and mechanics.
                    </p>
                  </div>
                </div>
                <div className="ex-card false">
                  <img
                    src="/images/branding-styleguide-typography/screen-best-practices-02@3x.jpg"
                    alt=""
                  />
                  <div className="ex-card-desc">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <p>
                      Don&apos;t use underlines for adding emphasise. Underlines are reserved for
                      text links only. They should not be used as a way for adding emphasis to
                      words.
                    </p>
                  </div>
                </div>
              </div>
              <div className="page-content-item">
                <h3 className="h3" id="line-length">
                  Line length
                </h3>
                <p className="mb-40">
                  Set the reading environment to suit the reader. Wide lines of text are difficult
                  to read and make it harder for people to focus. While there is no right way to
                  measure the perfect width for text, a good goal is to aim for between 60 and 100
                  characters per line including spacing.
                </p>
                <p className="mb-40">
                  Setting an optimal line length will break up content into easily digestible
                  information.
                </p>
              </div>
              <figure>
                <img
                  src="/images/branding-styleguide-typography/screen-line-length-01@3x.jpg"
                  alt=""
                  className="mb-40"
                />
                <figcaption>
                  The ideal line length is 40-60 characters per line for English body text.
                </figcaption>
              </figure>
              <figure>
                <img
                  src="/images/branding-styleguide-typography/screen-line-length-02@3x.jpg"
                  alt=""
                />
                <figcaption>
                  The ideal line length for short lines of English text is 20-40 characters per
                  line.
                </figcaption>
              </figure>
              <h1 className="divider h1" id="specifications-for-builder">
                Specifications for Builder
              </h1>
              <div className="page-content-item">
                <h3 className="h3" id="anatomy">
                  Anatomy
                </h3>
                <p>
                  The design of this typeface supports multiple languages and character sets, making
                  it versatile and suitable for DHCS Builder’s diverse international audience. Its
                  thoughtful construction includes various weights and styles, providing flexibility
                  for use in different contexts, from digital interfaces to printed materials.
                </p>
                <p>
                  The typeface’s open-source nature allows for continuous improvement and
                  customization, encouraging contributions from the design community to further
                  refine and expand its capabilities.
                </p>
                <p className="mb-40">
                  Inter can be accessed and downloaded from the{' '}
                  <strong>
                    <a href="/" title="">
                      DHCS GitHub Repo
                    </a>
                  </strong>
                  .
                </p>
              </div>
              <table className="table table-colors table-font-2 mb-40">
                <thead>
                  <tr>
                    <th colSpan={6} aria-label="hh" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="table-display">Display</td>
                    <td>Inter</td>
                    <td>semibold</td>
                    <td>24 px</td>
                    <td>52 px</td>
                    <td>-5%</td>
                  </tr>
                  <tr>
                    <td className="table-h1-sb">H1 SB</td>
                    <td>Inter</td>
                    <td>semibold</td>
                    <td>32 px</td>
                    <td>40 px</td>
                    <td>-3%</td>
                  </tr>
                  <tr>
                    <td className="table-h1">H1</td>
                    <td>Inter</td>
                    <td>light</td>
                    <td>32 px</td>
                    <td>40 px</td>
                    <td>-3%</td>
                  </tr>
                  <tr>
                    <td className="table-h2-sb">H2 SB</td>
                    <td>Inter</td>
                    <td>semibold</td>
                    <td>24 px</td>
                    <td>32 px</td>
                    <td>-2%</td>
                  </tr>
                  <tr>
                    <td className="table-h2">H2</td>
                    <td>Inter</td>
                    <td>light</td>
                    <td>24 px</td>
                    <td>32 px</td>
                    <td>-2%</td>
                  </tr>
                  <tr>
                    <td className="table-h3-sb">H3 SB</td>
                    <td>Inter</td>
                    <td>semibold</td>
                    <td>20 px</td>
                    <td>24 px</td>
                    <td>-2%</td>
                  </tr>
                  <tr>
                    <td className="table-h3">H3</td>
                    <td>Inter</td>
                    <td>light</td>
                    <td>20 px</td>
                    <td>24 px</td>
                    <td>-2%</td>
                  </tr>
                  <tr>
                    <td className="table-h4-sb">H4 SB</td>
                    <td>Inter</td>
                    <td>semibold</td>
                    <td>16 px</td>
                    <td>20 px</td>
                    <td>-2%</td>
                  </tr>
                  <tr>
                    <td className="table-h5">H5</td>
                    <td>Inter</td>
                    <td>light</td>
                    <td>16 px</td>
                    <td>20 px</td>
                    <td>-2%</td>
                  </tr>
                  <tr>
                    <td className="table-h5-sb">H5 SB</td>
                    <td>Inter</td>
                    <td>semibold</td>
                    <td>14 px</td>
                    <td>20 px</td>
                    <td>-2%</td>
                  </tr>
                  <tr>
                    <td className="table-body-1-b">Body 14</td>
                    <td>Inter</td>
                    <td>regular</td>
                    <td>14 px</td>
                    <td>16 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-body-14-m">Body 14 M</td>
                    <td>Inter</td>
                    <td>medium</td>
                    <td>14 px</td>
                    <td>16 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-body-14-sb">Body 14 SB</td>
                    <td>Inter</td>
                    <td>semibold</td>
                    <td>14 px</td>
                    <td>16 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-body-12">Body 12</td>
                    <td>Inter</td>
                    <td>regular</td>
                    <td>14 px</td>
                    <td>16 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-body-12-m">Body 12 M</td>
                    <td>Inter</td>
                    <td>medium</td>
                    <td>14 px</td>
                    <td>16 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-small">Small</td>
                    <td>Inter</td>
                    <td>Regular</td>
                    <td>11 px</td>
                    <td>14 px</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td className="table-body-overline">Body overline</td>
                    <td>Inter</td>
                    <td>Regular</td>
                    <td>10 px</td>
                    <td>12 px</td>
                    <td>8%</td>
                  </tr>
                  <tr>
                    <td className="table-body-footnote">Body Footnote</td>
                    <td>Inter</td>
                    <td>regular</td>
                    <td>8 px</td>
                    <td>12 px</td>
                    <td>8%</td>
                  </tr>
                </tbody>
              </table>
              <h1 className="h1 divider" id="references">
                References
              </h1>
              {dataExternalLinkGroups.map((externalLinkGroup) => (
                <ExternalLinkGroupStyled
                  key={externalLinkGroup.id}
                  title={externalLinkGroup.title}
                  cards={externalLinkGroup.cards}
                />
              ))}
            </div>
            <div>
              <div className="page-navigation">
                <h4>Contents</h4>
                <Scrollspy
                  ids={[
                    'overview',
                    'definition',
                    'specifications',
                    'anatomy',
                    'font-sizes',
                    'guidelines',
                    'best-practices',
                    'line-length',
                    'specifications-for-builder',
                    'references',
                  ]}
                  offset={0}
                  activeItemClassName="active"
                  itemContainerClassName="multilevel-link-list"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaginationStyled
        previousLink="/branding-styleguide/colors"
        previous="Colors"
        nextLink="/branding-styleguide/logos"
        next="Logos"
      />
    </div>
  </div>
);

TypographyStructure.defaultProps = {
  className: '',
};

const Typography = styled(TypographyStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 4, 5, 7, 8])}

    overflow: unset;
  height: 100%;

  > .content {
    width: 100%;
    padding: 0;
  }

  .table {
    &-font {
      tr:last-child {
        border-bottom: 1px solid var(--dadce0, #dadce0);
      }

      td {
        color: var(--171717, #171717);
        height: 4.375rem;

        &:first-child {
          padding-top: 0;
          padding-bottom: 0;
        }

        &.table {
          &-h1-b {
            font-family: Arial;
            font-size: 2.25rem;
            font-weight: 700;
            line-height: 1.222222222222222;
          }

          &-h2-b {
            font-family: Arial;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1.333333333333333;
          }

          &-h2 {
            font-family: Arial;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.333333333333333;
          }

          &-h3-b {
            font-family: Arial;
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.4;
          }

          &-h3 {
            font-family: Arial;
            font-size: 1.25rem;
            font-weight: 400;
            line-height: 1.4;
          }

          &-h4-b {
            font-family: Arial;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.25;
          }

          &-h4 {
            font-family: Arial;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.25;
          }

          &-h5-b {
            font-family: Arial;
            font-size: 0.8125rem;
            font-weight: 700;
            line-height: 1.230769230769231;
          }

          &-h5 {
            font-family: Arial;
            font-size: 0.8125rem;
            font-weight: 400;
            line-height: 1.230769230769231;
          }

          &-body-1-b {
            font-family: Arial;
            font-size: 0.875rem;
            font-weight: 700;
            line-height: 1.428571428571429;
          }

          &-body-1 {
            font-family: Arial;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.428571428571429;
          }

          &-body-2-b {
            font-family: Arial;
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 1.333333333333333;
          }

          &-body-2 {
            font-family: Arial;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.333333333333333;
          }

          &-small {
            font-family: Arial;
            font-size: 0.6875rem;
            font-weight: 400;
            line-height: 1.272727272727273;
          }

          &-overline {
            font-family: Arial;
            font-size: 0.625rem;
            font-weight: 400;
            line-height: 1.2;
            text-transform: uppercase;
          }

          &-footnote {
            font-family: Arial;
            font-size: 0.5rem;
            font-weight: 400;
            line-height: 1.5;
          }
        }
      }
    }
  }

  .table {
    &-font-2 {
      th {
        height: 1.5rem;
      }

      tr:last-child {
        border-bottom: 1px solid var(--dadce0, #dadce0);
      }

      td {
        color: var(--171717, #171717);
        height: 4.375rem;

        &:first-child {
          padding-top: 0;
          padding-bottom: 0;
        }

        &.table {
          &-display {
            font-size: 3rem;
            font-weight: 600;
            line-height: 1.083333333333333;
            letter-spacing: -0.05em;
          }

          &-h1-sb {
            font-size: 2rem;
            font-weight: 600;
            line-height: 1.25;
            letter-spacing: -0.03em;
          }

          &-h1 {
            font-size: 2rem;
            font-weight: 300;
            line-height: 1.25;
            letter-spacing: -0.03em;
          }

          &-h2-sb {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.333333333333333;
            letter-spacing: -0.02em;
          }

          &-h2 {
            font-size: 1.5rem;
            font-weight: 300;
            line-height: 1.333333333333333;
            letter-spacing: -0.02em;
          }

          &-h3-sb {
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.2;
            letter-spacing: -0.02em;
          }

          &-h3 {
            font-size: 1.25rem;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: -0.02em;
          }

          &-h4-sb {
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.25;
            letter-spacing: -0.01em;
          }

          &-h5 {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.25;
            letter-spacing: -0.01em;
          }

          &-h5-sb {
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 1.428571428571429;
          }

          &-body-14 {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.142857142857143;
          }

          &-body-14-m {
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.142857142857143;
          }

          &-body-14-sb {
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 1.142857142857143;
          }

          &-body-12 {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.166666666666667;
          }

          &-body-12-m {
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 1.166666666666667;
          }

          &-body-small {
            font-size: 0.6875rem;
            font-weight: 400;
            line-height: 1.272727272727273;
          }

          &-body-overline {
            font-size: 0.625rem;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: 0.08em;
            text-transform: uppercase;
          }

          &-body-footnote {
            font-size: 0.5rem;
            font-weight: 400;
            line-height: 1.5;
          }
        }
      }
    }
  }
`;

export default Typography;
