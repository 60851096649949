import { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as IconBrandingStyleguideOutline } from '../../images/icons/branding-styleguide-outline.svg';
import { ReactComponent as IconBrandingStyleguide } from '../../images/icons/branding-styleguide.svg';
import { ReactComponent as IconChartMatchFilled } from '../../images/icons/chart-filled.svg';
import { ReactComponent as IconChartMatch } from '../../images/icons/chartmatch.svg';
import { ReactComponent as IconChevronDown } from '../../images/icons/chevron-down.svg';
import { ReactComponent as IconChevronUp } from '../../images/icons/chevron-up.svg';
import { ReactComponent as IconDashboardTemplatesOutline } from '../../images/icons/dashboard-templates-outline.svg';
import { ReactComponent as IconDashboardTemplates } from '../../images/icons/dashboard-templates.svg';
import { ReactComponent as IconDataVizualizationOutline } from '../../images/icons/data-vizualization-outline.svg';
import { ReactComponent as IconDataVizualization } from '../../images/icons/data-vizualization.svg';
import { ReactComponent as IconDevResourcesOutline } from '../../images/icons/dev-resources-outline.svg';
import { ReactComponent as IconDevResources } from '../../images/icons/dev-resources.svg';
import { ReactComponent as IconGeneralComponentsOutline } from '../../images/icons/general-components-outline.svg';
import { ReactComponent as IconGeneralComponents } from '../../images/icons/general-components.svg';
import { ReactComponent as IconGetStartedOutline } from '../../images/icons/get-started-outline.svg';
import { ReactComponent as IconGetStarted } from '../../images/icons/get-started.svg';

interface Props {
  className?: string;
}

const DEFAULT_COLLAPSED_MENUS = [
  'get-started',
  'branding-styleguide',
  'general-components',
  'data-visualization',
  'dev-resources',
  'chart-match',
];

const DEFAULT_COLLAPSED_SUB_MENUS = [
  'lines',
  'bars',
  'maps',
  'tables',
  'circulars',
  'diagrams',
  'others',
];

const Navigation = ({ className }: Props) => {
  const [collapsedMenus, setCollapsedMenus] = useState<string[]>(DEFAULT_COLLAPSED_MENUS);
  const [collapsedSubMenus, setCollapsedSubMenus] = useState<string[]>(DEFAULT_COLLAPSED_SUB_MENUS);
  const location = useLocation();
  const path = location.pathname.split('/').filter(Boolean);
  const hasRun = useRef(false);

  useEffect(() => {
    // Open the last visited menu on page refresh
    if (!hasRun.current) {
      if (path.length > 0) {
        setCollapsedMenus(DEFAULT_COLLAPSED_MENUS.filter((item) => item !== path[0]));
        setCollapsedSubMenus(DEFAULT_COLLAPSED_SUB_MENUS.filter((item) => item !== path[1]));
      } else {
        setCollapsedMenus(DEFAULT_COLLAPSED_MENUS.filter((item) => item !== 'get-started'));
      }
      hasRun.current = true;
    }
  }, [path]);

  const handleMenuCollapse = (menu: string, isThirdLevel = false) => {
    if (isThirdLevel) {
      setCollapsedSubMenus((prev) => {
        if (prev.includes(menu)) {
          return DEFAULT_COLLAPSED_SUB_MENUS.filter((item) => item !== menu);
        }
        return [...prev, menu];
      });
    } else {
      setCollapsedMenus((prev) => {
        if (prev.includes(menu)) {
          return DEFAULT_COLLAPSED_MENUS.filter((item) => item !== menu);
        }
        return [...prev, menu];
      });
    }
  };

  return (
    <div className={className}>
      <ul className="lvl-1">
        <li>
          <NavLink
            to="/"
            onClick={() => handleMenuCollapse('get-started')}
            className={location.pathname.startsWith('/get-started') ? 'active' : ''}
          >
            {({ isActive }) => (
              <>
                <div>
                  {isActive ? <IconGetStarted /> : <IconGetStartedOutline />}
                  <span>Get Started</span>
                </div>
                {collapsedMenus.includes('get-started') ? <IconChevronDown /> : <IconChevronUp />}
              </>
            )}
          </NavLink>
          <ul className={`lvl-2 ${collapsedMenus.includes('get-started') ? 'collapsed' : ''}`}>
            <li>
              <NavLink
                to="/get-started/accessibility"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Accessibility</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/get-started/content"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Content</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/get-started/voice-and-tone"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Voice & Tone</span>
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink
            to="/branding-styleguide"
            className={({ isActive }) => (isActive ? 'active' : '')}
            onClick={() => handleMenuCollapse('branding-styleguide')}
          >
            {({ isActive }) => (
              <>
                <div>
                  {isActive ? <IconBrandingStyleguide /> : <IconBrandingStyleguideOutline />}
                  <span>Branding Styleguide</span>
                </div>
                {collapsedMenus.includes('branding-styleguide') ? (
                  <IconChevronDown />
                ) : (
                  <IconChevronUp />
                )}
              </>
            )}
          </NavLink>
          <ul
            className={`lvl-2 ${collapsedMenus.includes('branding-styleguide') ? 'collapsed' : ''}`}
          >
            <li>
              <NavLink
                to="/branding-styleguide/colors"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Colors</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/branding-styleguide/typography"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Typography</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/branding-styleguide/logos"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Logos</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/branding-styleguide/icons"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Icons</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/branding-styleguide/imagery"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Imagery</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/branding-styleguide/interactions-and-animations"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Interactions and Animations</span>
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink
            to="/general-components"
            className={({ isActive }) => (isActive ? 'active' : '')}
            onClick={() => handleMenuCollapse('general-components')}
          >
            {({ isActive }) => (
              <>
                <div>
                  {isActive ? <IconGeneralComponents /> : <IconGeneralComponentsOutline />}
                  <span>General Components</span>
                </div>
                {collapsedMenus.includes('general-components') ? (
                  <IconChevronDown />
                ) : (
                  <IconChevronUp />
                )}
              </>
            )}
          </NavLink>
          <ul
            className={`lvl-2 ${collapsedMenus.includes('general-components') ? 'collapsed' : ''}`}
          >
            <li>
              <NavLink
                to="/general-components/buttons"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Buttons</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/general-components/links"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Links</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/general-components/form-elements"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Form Elements</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/general-components/communication"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Communication</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/general-components/navigation"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Navigation</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/general-components/selection"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Selection</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/general-components/containment"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Containment</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/general-components/table"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Table</span>
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink
            to="/data-visualization"
            className={({ isActive }) => (isActive ? 'active' : '')}
            onClick={() => handleMenuCollapse('data-visualization')}
          >
            <>
              <div>
                {collapsedMenus.includes('data-visualization') ? (
                  <IconDataVizualizationOutline />
                ) : (
                  <IconDataVizualization />
                )}
                <span>Data Vizualization</span>
              </div>
              {collapsedMenus.includes('data-visualization') ? (
                <IconChevronDown />
              ) : (
                <IconChevronUp />
              )}
            </>
          </NavLink>
          <ul
            className={`lvl-2 ${collapsedMenus.includes('data-visualization') ? 'collapsed' : ''}`}
          >
            <li>
              <NavLink
                to="/data-visualization/lines"
                className={({ isActive }) => (isActive ? 'active' : '')}
                onClick={() => handleMenuCollapse('lines', true)}
              >
                <span>Lines</span>
                {collapsedSubMenus.includes('lines') ? <IconChevronDown /> : <IconChevronUp />}
              </NavLink>
              <ul className={`lvl-3 ${collapsedSubMenus.includes('lines') ? 'collapsed' : ''}`}>
                <li>
                  <NavLink
                    to="/data-visualization/lines/line-chart"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Line Chart</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/lines/area-chart"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Area Chart</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/lines/stacked-area-chart"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Stacked Area Chart</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/data-visualization/bars"
                className={({ isActive }) => (isActive ? 'active' : '')}
                onClick={() => handleMenuCollapse('bars', true)}
              >
                <span>Bars</span>
                {collapsedSubMenus.includes('bars') ? <IconChevronDown /> : <IconChevronUp />}
              </NavLink>
              <ul className={`lvl-3 ${collapsedSubMenus.includes('bars') ? 'collapsed' : ''}`}>
                <li>
                  <NavLink
                    to="/data-visualization/bars/chart-v"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Bar Chart - V</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/bars/chart-h"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Bar Chart - H</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/bars/grouped-bar-chart-v"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Grouped Bar Chart - V</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/bars/grouped-bar-chart-h"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Grouped Bar Chart - H</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/bars/stacked-bar-chart-v"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Stacked Bar Chart - V</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/bars/stacked-bar-chart-h"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Stacked Bar Chart - H</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/bars/lollipop-v"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Lollipop - V</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/bars/lollipop-h"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Lollipop - H</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/bars/grouped-lollipop-v"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Grouped Lollipop - V</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/bars/grouped-lollipop-h"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Grouped Lollipop - H</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/bars/stacked-lollipop-v"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Stacked Lollipop - V</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/bars/stacked-lollipop-h"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Stacked Lollipop - H</span>
                  </NavLink>
                </li>{' '}
                <li>
                  <NavLink
                    to="/data-visualization/bars/funnel-chart"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Funnel Chart</span>
                  </NavLink>
                </li>{' '}
                <li>
                  <NavLink
                    to="/data-visualization/bars/histogram"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Histogram</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/data-visualization/maps"
                className={({ isActive }) => (isActive ? 'active' : '')}
                onClick={() => handleMenuCollapse('maps', true)}
              >
                <span>Maps</span>
                {collapsedSubMenus.includes('maps') ? <IconChevronDown /> : <IconChevronUp />}
              </NavLink>
              <ul className={`lvl-3 ${collapsedSubMenus.includes('maps') ? 'collapsed' : ''}`}>
                <li>
                  <NavLink
                    to="/data-visualization/maps/choropleth"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Maps - Choropleth</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/maps/bubble"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Maps - Bubble</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/maps/pikes"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Map - Pikes</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/maps/scatter"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Map - Scatter</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/data-visualization/tables"
                className={({ isActive }) => (isActive ? 'active' : '')}
                onClick={() => handleMenuCollapse('tables', true)}
              >
                <span>Tables</span>
                {collapsedSubMenus.includes('tables') ? <IconChevronDown /> : <IconChevronUp />}
              </NavLink>
              <ul className={`lvl-3 ${collapsedSubMenus.includes('tables') ? 'collapsed' : ''}`}>
                <li>
                  <NavLink
                    to="/data-visualization/tables/heatmap"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Heatmap</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/tables/matrix"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Matrix</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/tables/punchcard"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Punchcard</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/data-visualization/circulars"
                className={({ isActive }) => (isActive ? 'active' : '')}
                onClick={() => handleMenuCollapse('circulars', true)}
              >
                <span>Circulars</span>
                {collapsedSubMenus.includes('circulars') ? <IconChevronDown /> : <IconChevronUp />}
              </NavLink>
              <ul className={`lvl-3 ${collapsedSubMenus.includes('circulars') ? 'collapsed' : ''}`}>
                <li>
                  <NavLink
                    to="/data-visualization/circulars/donut"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Donut Chart</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/circulars/polar"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Polar Area Chart</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/circulars/radar"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Radar Chart</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/data-visualization/diagrams"
                className={({ isActive }) => (isActive ? 'active' : '')}
                onClick={() => handleMenuCollapse('diagrams', true)}
              >
                <span>Diagrams</span>
                {collapsedSubMenus.includes('diagrams') ? <IconChevronDown /> : <IconChevronUp />}
              </NavLink>
              <ul className={`lvl-3 ${collapsedSubMenus.includes('diagrams') ? 'collapsed' : ''}`}>
                <li>
                  <NavLink
                    to="/data-visualization/diagrams/sankey"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Sankey Diagram</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/diagrams/treemap"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Treemap</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/diagrams/circle"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Circle Packing</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/data-visualization/others"
                className={({ isActive }) => (isActive ? 'active' : '')}
                onClick={() => handleMenuCollapse('others', true)}
              >
                <span>Others</span>
                {collapsedSubMenus.includes('others') ? <IconChevronDown /> : <IconChevronUp />}
              </NavLink>
              <ul className={`lvl-3 ${collapsedSubMenus.includes('others') ? 'collapsed' : ''}`}>
                <li>
                  <NavLink
                    to="/data-visualization/others/bubble"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Bubble Chart</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/others/box-plot-v"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Box Plot - V</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/others/box-plot-h"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Box Plot - H</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/others/violin-plot-v"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Violin Plot - V</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/others/violin-plot-h"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Violin Plot - H</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/others/line-and-stacked-bar"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Line &amp; Stacked Bar Chart</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/others/scatter-plot"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Scatter Plot</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/data-visualization/others/ribbon-chart"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <span>Ribbon Chart</span>
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <NavLink
            to="/design-system/chart-match"
            className={({ isActive }) => (isActive ? 'active' : '')}
            onClick={() => handleMenuCollapse('chart-match')}
          >
            {({ isActive }) => (
              <div>
                {isActive ? <IconChartMatchFilled /> : <IconChartMatch />}
                <span>ChartMatch</span>
              </div>
            )}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/design-system/dashboard-examples"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {({ isActive }) => (
              <div>
                {isActive ? <IconDashboardTemplates /> : <IconDashboardTemplatesOutline />}
                <span>Dashboard Examples</span>
              </div>
            )}
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={() => handleMenuCollapse('dev-resources')}
            to="/dev-resources"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {({ isActive }) => (
              <>
                <div>
                  {isActive ? <IconDevResources /> : <IconDevResourcesOutline />}
                  <span>Dev Resources</span>
                </div>
                {collapsedMenus.includes('dev-resources') ? <IconChevronDown /> : <IconChevronUp />}
              </>
            )}
          </NavLink>
          <ul className={`lvl-2 ${collapsedMenus.includes('dev-resources') ? 'collapsed' : ''}`}>
            <li>
              <NavLink
                to="/dev-resources/powerbikit"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Power BI Library</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dev-resources/excel-library"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Excel Library</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dev-resources/esrikit"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>ESRI Library</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dev-resources/figma-library"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <span>Figma Library</span>
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

Navigation.defaultProps = {
  className: '',
};

const NavigationStyled = styled(Navigation)`
  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  button {
    cursor: pointer;
  }

  .lvl-1 {
    > li {
      > button,
      > a {
        border: 0 none;
        padding: 0.75rem 1rem;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-radius: 0.375rem;
        font-size: 0.875rem;
        line-height: 1.4285714286;
        font-weight: 500;
        text-decoration: none;
        transition: background 0.3s ease-in-out;

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.5rem;
          transition: color 0.3s ease-in-out;

          svg {
            transition: color 0.3s ease-in-out;
          }
        }

        &:not(.active) {
          background-color: transparent;
          &:active {
            svg {
              color: var(--5f6877, #5f6877);
            }
          }
          div {
            color: var(--5c636e, #5c636e);

            svg {
              color: var(--5f6877, #5f6877);
            }
          }

          &:hover {
            background-color: var(--747c891f, #747c891f);

            div {
              color: var(--000005, #000005);

              svg {
                color: var(--000005, #000005);
              }
            }
          }
        }

        &.active {
          background-color: var(--fff, #fff);

          > svg {
            color: var(--000005, #000005);
          }

          div {
            color: var(--006b8a, #006b8a);

            svg {
              color: var(--2685a1, #2685a1);
            }
          }

          &:hover {
            div {
              color: var(--000005, #000005);

              svg {
                color: var(--000005, #000005);
              }
            }
          }
        }
      }
    }
  }

  button {
    background: transparent;
    border: 0 none;
    cursor: pointer;
    display: flex;
    width: 100%;
  }

  .lvl-2 {
    max-height: 130rem;
    overflow: hidden;
    padding-bottom: 0.75rem;
    padding-top: 1rem;
    transition:
      max-height 0.1s ease-in-out,
      padding 0.1s ease-in-out;

    &.collapsed {
      max-height: 0;
      padding-bottom: 0;
      padding-top: 0;
    }

    > li {
      margin-left: 2rem;
      padding-right: 1rem;
      position: relative;

      > button,
      > a {
        border: 0 none;
        padding: 0.5rem 0.75rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.75rem;
        line-height: 1.3333333333;
        font-weight: 500;
        text-decoration: none;
        width: 100%;
        border-radius: 0.375rem;
        transition:
          background 0.3s ease-in-out,
          color 0.3s ease-in-out;

        &::before,
        &::after {
          transition: background 0.3s ease-in-out;
        }

        > svg {
          transition: color 0.3s ease-in-out;
        }

        &:not(.active) {
          background-color: transparent;
          color: var(--5c636e, #5c636e);

          > svg {
            color: var(--5f6877, #5f6877);
          }

          &:hover {
            background-color: var(--747c891f, #747c891f);
            color: var(--000005, #000005);

            > svg {
              color: var(--000005, #000005);
            }
          }
        }

        &.active {
          background-color: var(--2d6e8d1f, #2d6e8d1f);
          color: var(--006b8a, #006b8a);

          > svg {
            color: var(--000005, #000005);
          }
        }
      }

      &:not(:last-child) {
        &::before {
          content: '';
          height: 100%;
          width: 0.0625rem;
          position: absolute;
          top: 0;
          left: -0.5625rem;
          background-color: var(--dfe1e4, #dfe1e4);
        }

        > button,
        > a {
          &::before {
            content: '';
            height: 2rem;
            width: 0.0625rem;
            position: absolute;
            top: 0;
            left: -0.5625rem;
          }
          &::after {
            content: '';
            height: 0.0625rem;
            width: 0.5rem;
            top: 1rem;
            left: -0.5rem;
            transform: translateY(-50%);
            position: absolute;
          }

          &:not(.active) {
            &::before,
            &::after {
              background-color: var(--dfe1e4, #dfe1e4);
            }

            &:hover {
              &::before,
              &::after {
                background-color: var(--000005, #000005);
              }
            }
          }

          &.active {
            cursor: pointer;

            &::before {
              content: '';
              height: 2rem;
              width: 0.0625rem;
              position: absolute;
              top: 0;
              left: -0.5625rem;
            }

            &::before,
            &::after {
              background-color: var(--006b8a, #006b8a);
            }
          }
        }
      }

      &:last-child {
        > button,
        > a {
          &::before {
            content: '';
            height: calc(50% + 0.0625rem);
            width: 0.0625rem;
            position: absolute;
            top: 0;
            left: -0.5625rem;
          }

          &::after {
            content: '';
            height: 0.0625rem;
            width: 0.5rem;
            top: 1rem;
            left: -0.5rem;
            transform: translateY(-50%);
            position: absolute;
          }

          &:not(.active) {
            &::before,
            &::after {
              background-color: var(--dfe1e4, #dfe1e4);
            }

            &:hover {
              &::before,
              &::after {
                background-color: var(--000005, #000005);
              }
            }
          }

          &.active {
            cursor: pointer;

            &::before,
            &::after {
              background-color: var(--006b8a, #006b8a);
            }

            &:hover {
              &::before,
              &::after {
                background-color: var(--000005, #000005);
              }
            }
          }
        }
      }
    }

    .lvl-3 {
      padding-top: 0.5rem;
      padding-bottom: 1rem;
      max-height: 130rem;
      overflow: hidden;
      transition:
        max-height 0.1s ease-in-out,
        padding 0.1s ease-in-out;

      &.collapsed {
        max-height: 0;
        padding-bottom: 0;
        padding-top: 0;
      }

      > li {
        margin-left: 1rem;
        position: relative;

        > button,
        > a {
          border: 0 none;
          padding: 0.5rem 0.75rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.75rem;
          line-height: 1.3333333333;
          font-weight: 500;
          text-decoration: none;
          width: 100%;
          border-radius: 0.375rem;
          transition:
            background 0.3s ease-in-out,
            color 0.3s ease-in-out;

          &::before,
          &::after {
            transition: background 0.3s ease-in-out;
          }

          > svg {
            transition: color 0.3s ease-in-out;
          }

          &:not(.active) {
            color: var(--5c636e, #5c636e);

            > svg {
              color: var(--5f6877, #5f6877);
            }

            &:hover {
              background-color: var(--747c891f, #747c891f);
              color: var(--000005, #000005);

              > svg {
                color: var(--000005, #000005);
              }
            }
          }

          &.active {
            color: var(--006b8a, #006b8a);

            > svg {
              color: var(--000005, #000005);
            }
          }
        }

        &:not(:last-child) {
          &::before {
            content: '';
            height: 100%;
            width: 0.0625rem;
            position: absolute;
            top: 0;
            left: -0.5625rem;
            background-color: var(--dfe1e4, #dfe1e4);
          }

          > button,
          > a {
            &::before {
              content: '';
              height: 2rem;
              width: 0.0625rem;
              position: absolute;
              top: 0;
              left: -0.5625rem;
            }
            &::after {
              content: '';
              height: 0.0625rem;
              width: 0.5rem;
              top: 1rem;
              left: -0.5rem;
              transform: translateY(-50%);
              position: absolute;
            }

            &:not(.active) {
              &::before,
              &::after {
                background-color: var(--dfe1e4, #dfe1e4);
              }

              &:hover {
                &::before,
                &::after {
                  background-color: var(--000005, #000005);
                }
              }
            }

            &.active {
              cursor: default;

              &::before {
                content: '';
                height: 2rem;
                width: 0.0625rem;
                position: absolute;
                top: 0;
                left: -0.5625rem;
              }

              &::before,
              &::after {
                background-color: var(--006b8a, #006b8a);
              }
            }
          }
        }

        &:last-child {
          > button,
          > a {
            &::before {
              content: '';
              height: calc(50% + 0.0625rem);
              width: 0.0625rem;
              position: absolute;
              top: 0;
              left: -0.5625rem;
            }

            &::after {
              content: '';
              height: 0.0625rem;
              width: 0.5rem;
              top: 1rem;
              left: -0.5rem;
              transform: translateY(-50%);
              position: absolute;
            }

            &:not(.active) {
              &::before,
              &::after {
                background-color: var(--dfe1e4, #dfe1e4);
              }

              &:hover {
                &::before,
                &::after {
                  background-color: var(--000005, #000005);
                }
              }
            }

            &.active {
              cursor: default;

              &::before,
              &::after {
                background-color: var(--006b8a, #006b8a);
              }

              &:hover {
                &::before,
                &::after {
                  background-color: var(--000005, #000005);
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default NavigationStyled;
